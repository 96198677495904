import { TABLES_KEYS } from 'features/command-center/constants';

export const ACTIVIY_MODE = {
  View: 'view',
  Edit: 'edit'
};

export const ACTIVITY_TYPE_MAP = {
  call: 'Call',
  email: 'Email'
};

export const PAGE_KEY = TABLES_KEYS.feedbackLogSupportActivity;

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'createdAt',
    label: 'Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'giverName',
    label: 'Contact',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'giverRoleTitle',
    label: 'Contact Role',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'duration',
    label: 'Duration',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'direction',
    label: 'Direction',
    options: {
      sort: true,
      display: true
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'createdAt',
  direction: 'desc'
};
