import { hasFeatureFlag } from 'UI/utils';

import { FeatureFlags } from './featureFlags';

export const OPT_OUT_COLUMN = {
  name: 'is_opted_out',
  label: 'Subscription Status',
  hideWhen: () => !hasFeatureFlag(FeatureFlags.OptOutFilter),
  options: {
    sort: true,
    display: true,
    renderer: {
      id: 'fastChipBuilder',
      config: {
        label: 'Opt Out',
        presetMode: 'error'
      }
    }
  }
};

export const LEGACY_EMAIL_COLUMN = {
  name: 'email',
  label: 'Email',
  options: {
    sort: true,
    display: true
  }
};

export const EMAIL_WITH_VALIDATION_COLUMN = {
  name: 'mainEmail',
  label: 'Email',
  options: {
    sort: true,
    display: true,
    renderer: 'mainEmail'
  }
};

export const getEmailValidationStatusColumn = ({ featureFlagToHideColumn }) => {
  return {
    name: 'email_verdict',
    label: 'Email Validation',
    hideWhen: () =>
      !hasFeatureFlag(FeatureFlags.EmailValidationColumn) ||
      (featureFlagToHideColumn && hasFeatureFlag(featureFlagToHideColumn)),
    options: {
      sort: false,
      display: true,
      renderer: 'emailStatus'
    }
  };
};

export const PhoneColumns = {
  PHONE_LEGACY: {
    name: 'phone',
    label: 'Phone',
    hideWhen: () => hasFeatureFlag(FeatureFlags.MultiplePhonesColumn),
    options: {
      sort: false,
      display: false,
      renderer: 'phone'
    }
  },
  OTHER_PHONE_LEGACY: {
    name: 'mobile',
    label: 'Other phone',
    hideWhen: () => hasFeatureFlag(FeatureFlags.MultiplePhonesColumn),
    options: {
      sort: false,
      display: false,
      renderer: 'phone'
    }
  },
  WORK_PHONE_LEGACY: {
    name: 'work_phone',
    label: 'Phone',
    hideWhen: () =>
      hasFeatureFlag(FeatureFlags.MultiplePhonesColumn) &&
      hasFeatureFlag(FeatureFlags.HiringAuthoritiesMultiplePhones),
    options: {
      sort: false,
      display: true,
      renderer: 'phone'
    }
  },
  PERSONAL_PHONE_LEGACY: {
    name: 'personal_phone',
    label: 'Other phone',
    hideWhen: () =>
      hasFeatureFlag(FeatureFlags.MultiplePhonesColumn) &&
      hasFeatureFlag(FeatureFlags.HiringAuthoritiesMultiplePhones),
    options: {
      sort: false,
      display: true,
      renderer: 'phone'
    }
  }
};
