import { FeatureFlags } from 'UI/constants/featureFlags';
import {
  EMAIL_WITH_VALIDATION_COLUMN,
  getEmailValidationStatusColumn,
  LEGACY_EMAIL_COLUMN,
  OPT_OUT_COLUMN,
  PhoneColumns
} from 'UI/constants/sharedColumns';
import { hasFeatureFlag } from 'UI/utils';

const LEGACY_PERSONAL_EMAIL_COLUMN = {
  name: 'other_email',
  label: 'Personal Email',
  options: {
    sort: true,
    display: false
  }
};

const PERSONAL_EMAIL_WITH_VALIDATION_COLUMN = {
  name: 'secondaryEmail',
  label: 'Personal Email',
  options: {
    sort: true,
    display: true,
    renderer: {
      id: 'secondaryEmail',
      config: {
        prefix: 'other'
      }
    }
  }
};

const EMAIL_VALIDATION_STATUS_COLUMN = getEmailValidationStatusColumn({
  featureFlagToHideColumn: FeatureFlags.EmailWithValidationColumnNames
});

const FINAL_EMAIL_COLUMNS = hasFeatureFlag(FeatureFlags.EmailWithValidationColumnNames)
  ? [EMAIL_WITH_VALIDATION_COLUMN, PERSONAL_EMAIL_WITH_VALIDATION_COLUMN]
  : [LEGACY_EMAIL_COLUMN, LEGACY_PERSONAL_EMAIL_COLUMN, EMAIL_VALIDATION_STATUS_COLUMN];

export const columnsContact = [
  {
    name: 'id',
    options: {
      display: 'excluded',
      sort: false
    }
  },
  {
    name: 'full_name',
    label: 'Full Name',
    options: {
      sort: true,
      display: true,
      renderer: 'contactLink'
    }
  },
  {
    name: 'nickname',
    label: 'Nickname',
    options: {
      sort: true
    }
  },
  OPT_OUT_COLUMN,
  ...FINAL_EMAIL_COLUMNS,
  {
    name: 'company_type',
    label: 'Company Type',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-company-type',
          fallback: {
            label: 'N/A',
            hint: 'No company is linked to this candidate. Add a company to start marketing it.',
            variant: 'danger'
          }
        }
      }
    }
  },
  {
    name: 'current_company',
    label: 'Company',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'recruiter_name',
    label: 'Recruiter Name',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'specialty_title',
    label: 'Industry: Specialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'specialty',
    label: 'Specialty',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'industry',
    label: 'Industry',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'subspecialty',
    label: 'Subspecialty',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'functional_title',
    label: 'Functional Title',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      sort: false,
      display: false
    }
  },
  {
    name: 'role',
    label: 'Role',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'added_date',
    label: 'Added Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'state',
    label: 'State',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'city',
    label: 'City',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'zip',
    label: 'Zip',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'last_activity_title',
    label: 'Last Activity',
    options: {
      sort: false,
      display: false
    }
  },
  {
    name: 'last_activity_recruiter',
    label: 'Last Activity by',
    options: {
      sort: false,
      display: false
    }
  },
  {
    name: 'last_activity_date',
    label: 'Last Activity on',
    options: {
      sort: true,
      display: false,
      renderer: 'date'
    }
  },
  PhoneColumns.PHONE_LEGACY,
  PhoneColumns.OTHER_PHONE_LEGACY,
  {
    name: 'opt_out_date',
    label: 'Opt Out Date',
    options: {
      renderer: 'date'
    }
  },
  {
    name: 'opt_out_reason',
    label: 'Opt Out Reason',
    options: {
      renderer: 'default'
    }
  },
  {
    name: 'role_id',
    options: {
      display: 'excluded',
      sort: false
    }
  }
];

const PhoneFilter = hasFeatureFlag(FeatureFlags.MultiplePhonesColumn) ? 'partialPhone' : 'phone';

const isPersonalEmailValidationEnabled = hasFeatureFlag(
  FeatureFlags.EmailWithValidationColumnNames
);

export const filtersInclude = ['types'];
export const FiltersGroups = [
  {
    id: 'general',
    name: 'Contacts',
    filters: [
      'contactStatuses',
      'contactTypes',
      'company',
      'companyTypes',
      'recruitersAndCoaches',
      PhoneFilter,
      'optOut',
      'emailVerdicts',
      ...(isPersonalEmailValidationEnabled ? ['personalEmailVerdicts'] : [])
    ]
  },
  {
    id: 'industries',
    name: 'Industries',
    filters: ['industries', 'specialties', 'subspecialties', 'positions', 'title']
  },
  {
    id: 'location',
    name: 'Location',
    filters: ['countries', 'states', 'cities', 'cityRadius', 'zips', 'radius']
  }
];

export const VirtualProps = [
  {
    propName: 'full_name',
    attributes: ['id', 'full_name', 'role_id']
  },
  {
    propName: 'phoneNumbers',
    attributes: ['phone', 'phones']
  },
  {
    propName: 'mainEmail',
    attributes: ['email', 'email_verdict']
  },
  {
    propName: 'secondaryEmail',
    attributes: ['other_email', 'personal_email_verdict']
  }
];

export const PeriodColumnBySection = {
  0: 'created_at',
  1: 'last_activity_date'
};

export const CreatedAtFilter = { id: 0, title: 'Added Date', column: 'created_at' };
export const LastActivityDateFilter = {
  id: 1,
  title: 'Last Activity Date',
  column: 'last_activity_date'
};

export const DateFilters = [CreatedAtFilter, LastActivityDateFilter];
