import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const Container = styled('div')(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(4),
  width: '100%'
}));

export const LoaderContaier = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center'
});

export const NotesLabel = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '700 !important',
  lineHeight: '18px',
  color: theme.typography.fontWeightBold
}));
