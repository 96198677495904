import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import API from 'services/API';
import strings from 'strings';
import { UIStatus } from 'UI/constants/status';
import { preloadFromBackend } from 'UI/utils/forms';

import { FormFieldsMap } from '../ActivitiesListing/ActivityOutcomeFormFields';
import { ENTITY_URL_MAP, NOT_REGISTERED_CALL_ENTITY } from '../ActivityTable.constants';

const { form: STRINGS } = strings.inventoryProfiles.sections.tabs.activityNote;

const getErrorMessage = exception => {
  const detail = exception.response?.data?.detail;

  if (!detail) return strings.shared.errors.serverError.subtitle;

  const message = Array.isArray(detail) ? detail.map(({ msg }) => `∙ ${msg}\n`).join('') : detail;

  return message;
};

const useActivitiesForm = ({
  activityId,
  outcome,
  outcomeComments,
  entityId,
  onCancel,
  onSubmitSucceed,
  rowIndex,
  entity
}) => {
  const [saveState, setSaveState] = useState({ status: UIStatus.Default, error: null });
  const dispatch = useDispatch();

  const defaultValues = preloadFromBackend(
    {
      [FormFieldsMap.ActivityOutcome.key]: outcome,
      [FormFieldsMap.ActivityOutcomeComments.key]: outcomeComments
    },
    FormFieldsMap
  );

  const isUpdating = !!outcome.activityOutcomeId;

  const form = useForm(isUpdating ? { defaultValues } : {});
  const {
    formState: { dirty },
    handleSubmit,
    register,
    setValue,
    unregister,
    watch,
    getValues,
    clearError,
    errors
  } = form;
  const formValues = watch();

  useEffect(() => {
    register(FormFieldsMap.ActivityOutcome.key, {
      validate: value => {
        const comments = getValues(FormFieldsMap.ActivityOutcomeComments.key);
        if (comments && (!value || !value.typeId)) {
          return STRINGS.validation.activityOutcome;
        }
        return true;
      }
    });

    register(FormFieldsMap.ActivityOutcomeComments.key, {
      validate: value => {
        const outcomeValue = getValues(FormFieldsMap.ActivityOutcome.key);
        if (value && (!outcomeValue || !outcomeValue.typeId)) {
          return STRINGS.validation.activityOutcomeComments;
        }
        return true;
      }
    });

    return () => {
      unregister(FormFieldsMap.ActivityOutcome.key);
      unregister(FormFieldsMap.ActivityOutcomeComments.key);
    };
  }, [getValues, register, unregister]);

  const handleFieldChange = (fieldName, newValue) => {
    if (newValue === '') {
      clearError();
    }
    setValue(fieldName, newValue);
  };

  const handleSelectChange = (name?, value) => {
    setValue(name, value, true);
  };

  const handleCancel = () => onCancel && onCancel(activityId, rowIndex);

  const onSubmit = async formData => {
    const fieldsToSend = {
      activityOutcomeTypeId: formData.activityOutcome?.typeId || null,
      comments: formData.activityOutcomeComments || null
    };

    const isNotRegisteredCalls = entity === NOT_REGISTERED_CALL_ENTITY;
    try {
      setSaveState({ status: UIStatus.Saving });

      const baseUrl = isNotRegisteredCalls
        ? `/${ENTITY_URL_MAP[entity]}/${activityId}/outcomes`
        : `/${ENTITY_URL_MAP[entity]}/${entityId}/activity-logs/${activityId}/outcomes`;
      const url = isUpdating ? `${baseUrl}/${outcome?.activityOutcomeId}` : baseUrl;
      const request = isUpdating ? API.put : API.post;

      const response = await request(url, fieldsToSend);

      if (
        response.status === HTTPStatusCodes.Ok ||
        response.status === HTTPStatusCodes.Created ||
        response.status === HTTPStatusCodes.NoContent
      ) {
        setSaveState({ status: UIStatus.Success });
        dispatch(
          showAlert({
            severity: 'success',
            body: strings.collections.messages.recordUpdated,
            autoHideDuration: 3000
          })
        );

        onSubmitSucceed && onSubmitSucceed(rowIndex);
      }
    } catch (e) {
      setSaveState({ status: UIStatus.Error, error: getErrorMessage(e) });
    }
  };

  return {
    dirty,
    formValues,
    handleCancel,
    handleFieldChange,
    handleSelectChange,
    handleSubmit,
    onSubmit,
    saveState,
    errors
  };
};

export default useActivitiesForm;
