import React from 'react';
import {
  MEDIUM_COLORS_BY_LABEL,
  METRICS_MAP,
  METRICS_TITLE
} from 'features/command-center/constants';
import { EntityRoutes } from 'routes/constants';
import { colors } from 'UI/res';

import { MetricCell } from './TeamMembersHealth.styled';

export const TEAM_MEMBERS_HEALTH_COLUMNS = [
  {
    name: 'teamName',
    label: 'Team',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CommandCenterOverviewTeam,
          idKey: 'id',
          textKey: 'managerName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'atRisk',
    label: METRICS_TITLE.atRisk,
    options: {
      sort: true,
      display: true,
      renderer: 'healthStatus'
    }
  },
  {
    name: 'behind',
    label: METRICS_TITLE.behind,
    options: {
      sort: true,
      display: true,
      renderer: 'healthStatus'
    }
  },
  {
    name: 'onTrack',
    label: METRICS_TITLE.onTrack,
    options: {
      sort: true,
      display: true,
      renderer: 'healthStatus'
    }
  },
  {
    name: 'inTraining',
    label: METRICS_TITLE.inTraining,
    options: {
      sort: true,
      display: true,
      renderer: 'healthStatus'
    }
  },
  {
    name: 'under3Months',
    label: METRICS_TITLE.under3Months,
    options: {
      sort: true,
      display: true,
      renderer: 'healthStatus'
    }
  },
  {
    name: 'headcount',
    label: 'Total',
    options: {
      sort: true,
      display: true,
      renderer: 'healthStatus'
    }
  },
  {
    name: 'minMaxValues',
    label: 'Min/Max',
    options: {
      display: false
    }
  }
];

export const TEAM_MEMBERS_HEALTH_ORDER_BY_OPTIONS = {
  column: 'teamName',
  direction: 'asc'
};

export const MAP_METRIC_MEDIUM_COLORS = {
  [METRICS_MAP.AtRisk]: MEDIUM_COLORS_BY_LABEL.red,
  [METRICS_MAP.Behind]: MEDIUM_COLORS_BY_LABEL.yellow,
  [METRICS_MAP.OnTrack]: MEDIUM_COLORS_BY_LABEL.green,
  [METRICS_MAP.InTraining]: MEDIUM_COLORS_BY_LABEL.blue,
  [METRICS_MAP.Under3Months]: MEDIUM_COLORS_BY_LABEL.grey,
  [METRICS_MAP.Headcount]: colors.white
};

export const teamMembersHealthExtraRenderers = {
  healthStatus: (value, rowData) => {
    const { columnData } = rowData;
    const color = columnData.name === METRICS_MAP.Headcount ? colors.white : value.color;

    return <MetricCell hexColor={color}>{value?.total ?? 0}</MetricCell>;
  }
};
