import Grid from '@material-ui/core/Grid';
import { makeStyles, styled } from '@material-ui/core/styles';

export const Container = styled(Grid)({
  width: '100%'
});

export const TableContainer = styled(Grid)({
  height: 'fit-content'
});

export const ButtonContainer = styled(Grid)(({ theme }) => ({
  display: 'flex ',
  justifyContent: 'flex-end',
  margin: theme.spacing(1.5, 1.5, 1.5, 0)
}));

export const useStyles = makeStyles(theme => ({
  drawerLarge: {
    width: 1100,
    overflowY: 'hidden',
    [theme.breakpoints.down(1024)]: {
      width: '80%'
    }
  },
  drawerContent: {
    padding: 0,
    flexGrow: 1,
    overflowY: 'auto'
  }
}));
