import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';

export const useJobOrderProfileFeatureFlags = () => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  return {
    canUseChangeLogsTabs: checkIfFeatureFlagEnabled(FeatureFlags.ChangeLogJobOrder),
    canUseUrgencyVerification: checkIfFeatureFlagEnabled(FeatureFlags.UrgencyVerification),
    isDataSheetCardsEnabled: checkIfFeatureFlagEnabled(FeatureFlags.JobOrderDataSheetFlow)
  };
};
