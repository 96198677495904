import omit from 'lodash/omit';
import { FeeAgreementStatus } from 'UI/constants/status';
import { getInitials, getQuestionsCounts } from 'utils';

import { ANSWER, FEE_AGREEMENT_PERCENT, GUARANTEE_DAYS } from './AssignmentSheetDrawer.constants';

export const getDataSheetCount = ({ formData, questionsPaths, schema }) => {
  if (!formData) {
    return {
      total: 0,
      answered: 0
    };
  }

  let finalQuestionsPaths = { ...questionsPaths };

  if (formData.section2?.coverPosition === ANSWER.Negative) {
    const {
      'section2.coverPositionDetails': _coverPositionDetails,
      'section2.resultsYouHad': _resultsYouHad,
      ...rest
    } = finalQuestionsPaths;
    finalQuestionsPaths = { ...rest };
  }

  if (!('feeAgreement' in schema?.properties?.section1?.properties)) {
    delete finalQuestionsPaths['section1.feeAgreement'];
  }

  return getQuestionsCounts(formData, finalQuestionsPaths);
};

export const formatDataSheetDataForAPI = ({
  id,
  formData,
  schemaName,
  schemaVersion,
  progress,
  user
}) => {
  return {
    ...(id && { id }),
    data: formData,
    schema: {
      key: schemaName,
      version: schemaVersion
    },
    progress: {
      current: progress.answered,
      total: progress.total
    },
    trace: {
      user: {
        id: user.id,
        fullName: user.personalInformation.full_name,
        email: user.email,
        initials: getInitials(user.personalInformation.full_name)
      }
    }
  };
};

export const convertDraftToWhiteSheetFormat = draftData => {
  return {
    ...draftData,
    company_fee_agreement_id: draftData.companyFeeAgreement?.id,
    work_type_option_id: draftData.workTypeOption?.id,
    job_order_type_id: draftData.jobOrderType?.id
  };
};

export const addFeeAgreementValuesToFormData = (
  formDataToModify,
  { feeAgreements, hasFeeAgreementChanged = false } = {}
) => {
  const { section1 = {} } = formDataToModify || {};

  const signedAgreements = feeAgreements?.some(
    ({ fee_agreement_status_id: statusId }) => statusId === FeeAgreementStatus.Signed
  );

  const getResponseValue = value => {
    if ([ANSWER.Positive, ANSWER.Negative].includes(value)) return value;

    if (signedAgreements) {
      return ANSWER.Positive;
    }

    return undefined;
  };

  const getFeeAgreement = value => {
    if (value) return value;

    if (signedAgreements) {
      return feeAgreements?.find(
        ({ fee_agreement_status_id: statusId }) => statusId === FeeAgreementStatus.Signed
      )?.id;
    }

    if (feeAgreements?.length > 0) {
      return feeAgreements[0].id;
    }

    return undefined;
  };

  const feeAgreementId = getFeeAgreement(section1.feeAgreement);
  const feeAgreement = feeAgreementId && feeAgreements?.find(({ id }) => id === feeAgreementId);

  const getFeePercent = () => {
    if (!hasFeeAgreementChanged)
      return section1.feePercent ?? feeAgreement?.fee_percentage ?? FEE_AGREEMENT_PERCENT ?? '';

    return feeAgreement?.fee_percentage ?? FEE_AGREEMENT_PERCENT ?? '';
  };

  const getFeeGuaranteeDays = () => {
    if (!hasFeeAgreementChanged)
      return section1.feeGuaranteeDays ?? feeAgreement?.guarantee_days ?? GUARANTEE_DAYS ?? '';

    return feeAgreement?.guarantee_days ?? GUARANTEE_DAYS ?? '';
  };

  return {
    ...formDataToModify,
    section1: {
      ...section1,
      feePercent: String(getFeePercent()),
      feeGuaranteeDays: String(getFeeGuaranteeDays()),
      signedFeeAgreement: getResponseValue(section1.signedFeeAgreement),
      preparedToSign: getResponseValue(section1.preparedToSign),
      feeAgreement: feeAgreement?.id
    }
  };
};

export const resetSection1Values = formData => {
  return {
    ...formData,
    section1: {
      feePercent: undefined,
      feeGuaranteeDays: undefined,
      signedFeeAgreement: undefined,
      preparedToSign: undefined,
      feeAgreement: undefined
    }
  };
};

export const cleanUpSchema = (formData, schema) => {
  const { section2 = {} } = formData || {};

  if (section2?.coverPosition === ANSWER.Negative) {
    return omit(schema, [
      'properties.section2.properties.coverPositionDetails',
      'properties.section2.properties.resultsYouHad'
    ]);
  }

  return {
    ...schema,
    properties: {
      ...schema.properties,
      section2: {
        ...schema.properties.section2,
        properties: {
          ...schema.properties.section2.properties,
          ...schema.properties.section2.then?.properties
        }
      }
    }
  };
};
