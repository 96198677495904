import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  CallsGauge,
  Drawers,
  FinancialProductivityStatsSummary,
  HealthDistribution,
  InMailsGauge,
  Layout,
  LayoutSkeleton,
  PDAByTenure,
  PeopleReachedGauge,
  PhoneTimeGauge,
  ProductionCashInByMonth,
  RegionalDirectorCards,
  TeamMembersHealth
} from 'features/command-center';
import { DrawerManagerProvider } from 'features/command-center/contexts';
import { useRefreshStats } from 'features/command-center/hooks';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import usePageTitle from 'hooks/usePageTitle';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import strings from 'strings';
import { PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { UIStatus } from 'UI/constants/status';

export const Overview = () => {
  usePageTitle({ title: PageTitles.CommandCenterOverview });
  const { refreshData, refreshKey } = useRefreshStats();
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const { state: weeksCatalogState } = useFetchWithStatusV2({
    endpoint: Endpoints.ToolsUsageReportWeeks
  });

  const weeks = weeksCatalogState?.results;
  const canDoDrillDown = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterDrillDown);

  return (
    <Layout
      title={strings.commandCenter.overview.title}
      path={strings.commandCenter.title}
      onRefresh={refreshData}
    >
      <DrawerManagerProvider key={refreshKey}>
        {weeksCatalogState.status === UIStatus.Loading ? (
          <LayoutSkeleton />
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <HealthDistribution
                  hasDrillDown={canDoDrillDown}
                  requestParams={canDoDrillDown && { version: 1 }}
                />
              </Grid>

              {checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterPDA) && (
                <Grid item xs={12}>
                  <PDAByTenure />
                </Grid>
              )}

              <Grid item xs={12}>
                <TeamMembersHealth />
              </Grid>

              <Grid item xs={12} sm={6}>
                <PeopleReachedGauge
                  weeks={weeks}
                  footerLabel={strings.commandCenter.activity.peopleReachedGauge.chart.footerLabel}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InMailsGauge
                  weeks={weeks}
                  footerLabel={strings.commandCenter.activity.inMailsGauge.chart.footerLabel}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CallsGauge
                  weeks={weeks}
                  footerLabel={strings.commandCenter.activity.callsGauge.chart.footerLabel}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <PhoneTimeGauge
                  weeks={weeks}
                  footerLabel={strings.commandCenter.activity.phoneTimeGauge.chart.footerLabel}
                />
              </Grid>

              <Grid item xs={12}>
                <FinancialProductivityStatsSummary />
              </Grid>

              <Grid item xs={12}>
                <ProductionCashInByMonth />
              </Grid>

              <Grid item xs={12}>
                <RegionalDirectorCards />
              </Grid>
            </Grid>
            <Drawers />
          </>
        )}
      </DrawerManagerProvider>
    </Layout>
  );
};
