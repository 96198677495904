// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Prompt, useLocation } from 'react-router';
import ScrollToTop from 'react-router-scroll-top';
import {
  hideFile as hideFileAction,
  hideFileExplorer as hideFileExplorerAction,
  toggleExpandTableAction,
  toggleSidebar as toggleSidebarAction
} from 'actions/app';
import { AppInsightsErrorBoundary } from 'AppInsights';
import useNotification from 'hooks/useNotification';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { EntityRoutes } from 'routes/constants';
import type { DisplayedFile, FileExplorerOptions } from 'types/app';
import CollapsableButtonMenu from 'UI/components/molecules/CollapsableButtonMenu';
import FileExplorer from 'UI/components/organisms/FileExplorer';
import FileViewer from 'UI/components/organisms/FileViewer';
import NavBar from 'UI/components/organisms/NavBar';
import Sidebar from 'UI/components/templates/Sidebar';
import { addMenu } from 'UI/constants/dimensions';
import {
  CandidateEntity,
  CompanyEntity,
  ContactEntity,
  JobOrderEntity
} from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgAdd, SvgCandidate, SvgCompanies, SvgContacts, SvgJobOrders } from 'UI/res';

import ErrorFallback from './ErrorFallback';
import { useStyles } from './styles';

const menuItems = [
  {
    icon: SvgCandidate,
    title: CandidateEntity.singular,
    link: EntityRoutes.CandidateCreate,
    visible: true
  },
  {
    icon: SvgCompanies,
    title: CompanyEntity.singular,
    link: EntityRoutes.CompanyCreate,
    visible: true
  },
  {
    icon: SvgJobOrders,
    title: JobOrderEntity.singular,
    link: EntityRoutes.JobOrderCreate,
    visible: true
  },
  {
    icon: SvgContacts,
    title: ContactEntity.singular,
    link: EntityRoutes.ContactCreate,
    visible: true
  }
];

type MainLayoutProps = {
  activePdf: DisplayedFile,
  fileExplorer: FileExplorerOptions,
  hideFile: () => void,
  hideFileExplorer: () => void,
  isSidebarOpen: boolean,
  showVersionBar: boolean
};
const MainLayout = ({
  activePdf,
  children,
  fileExplorer,
  isTableExpanded,
  hideFile,
  hideFileExplorer,
  toggleExpandTable,
  isSidebarOpen,
  showVersionBar
}: MainLayoutProps) => {
  const { pathname } = useLocation();
  const classes = useStyles({ isSidebarOpen, showVersionBar });
  const showFile = !!activePdf;

  const { verifyNotificationPermissions } = useNotification();

  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  useEffect(() => {
    if (checkIfFeatureFlagEnabled(FeatureFlags.Notifications)) {
      verifyNotificationPermissions();
    }
  }, [checkIfFeatureFlagEnabled, verifyNotificationPermissions]);

  return (
    <>
      <Prompt
        when={isTableExpanded}
        message={() => {
          toggleExpandTable(false);
        }}
      />
      <ScrollToTop>
        <div className={classes.app}>
          <NavBar />
          <div className={classes.flexContentWrapper}>
            <div className={classes.sidebar}>
              <Sidebar>
                <CollapsableButtonMenu
                  className={classes.addButton}
                  icon={SvgAdd}
                  iconSize={addMenu.addButton.iconSize}
                  menuItems={menuItems}
                  text="Add"
                />
              </Sidebar>
            </div>
            <AppInsightsErrorBoundary key={pathname} onError={ErrorFallback}>
              {children}
            </AppInsightsErrorBoundary>
          </div>
        </div>
        {showFile && (
          <FileViewer
            url={activePdf?.url || ''}
            onClose={hideFile}
            useProxy={activePdf?.useProxy}
            explicitFileName={activePdf?.explicitFileName}
          />
        )}
        {fileExplorer && (
          <FileExplorer
            category={fileExplorer.category}
            defaultEntityType={fileExplorer.defaultEntityType}
            endpoint={fileExplorer.endpoint}
            multiple={fileExplorer.multiple}
            onClose={hideFileExplorer}
            onSelect={fileExplorer.onSelect}
            sections={fileExplorer.sections}
            selectedFiles={fileExplorer.selectedFiles}
            shouldAppendCategory={fileExplorer.shouldAppendCategory}
            title={fileExplorer.title}
          />
        )}
      </ScrollToTop>
    </>
  );
};

MainLayout.defaultProps = {
  showVersionBar: false
};

const mapDispatchToProps = dispatch => {
  return {
    hideFile: () => dispatch(hideFileAction()),
    hideFileExplorer: () => dispatch(hideFileExplorerAction()),
    toggleSidebar: (value: boolean) => dispatch(toggleSidebarAction(value)),
    toggleExpandTable: (value: boolean) => dispatch(toggleExpandTableAction(value))
  };
};

const mapStateToProps = ({ app }) => {
  return {
    activePdf: app.ui.activePdf,
    fileExplorer: app.ui.fileExplorer,
    showVersionBar: app.ui.showVersionBar,
    isSidebarOpen: app.ui.isSidebarOpen,
    isTableExpanded: app.ui.isTableExpanded
  };
};

const MainLayoutConnected = connect(mapStateToProps, mapDispatchToProps)(MainLayout);

export default MainLayoutConnected;
