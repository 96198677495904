import { formatInternationalPhone } from 'features/command-center/utils';
import omit from 'lodash/omit';
import { EntityRoutes } from 'routes/constants';
import { NOTIFICATIONS_SERVICE_TYPES } from 'UI/utils';

export const getFinalPayload = payload => {
  const isMessageCenterNotification = payload?.type === NOTIFICATIONS_SERVICE_TYPES.MESSAGE_CENTER;

  if (isMessageCenterNotification) {
    if (!('rawMessage' in payload)) return payload;

    const payloadObject = JSON.parse(payload.rawMessage);
    const payloadWithoutMessage = omit(payloadObject, ['message']);
    const formattedRawMessage = {
      ...payloadWithoutMessage,
      id: payloadWithoutMessage.conversationId,
      messageId: payloadWithoutMessage.id
    };
    const encodedPayloadObject = Buffer.from(JSON.stringify(formattedRawMessage)).toString(
      'base64'
    );

    return {
      ...payload,
      clickAction: `${EntityRoutes.MessageCenter}?conversation=${encodedPayloadObject}`,
      title: payloadObject?.fullName || formatInternationalPhone(payloadObject?.externalNumber),
      body: payloadObject?.message,
      icon: 'textMessages'
    };
  }

  return {
    ...payload,
    clickAction: payload.click_action,
    icon: payload.icon
  };
};
