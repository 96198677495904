// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import NumberedForm from 'UI/components/molecules/NumberedForm';

import { FORM_FIELDS_MAP } from '../fields';

const { searchProject: formCopies } = strings.searchProjects.forms;

const SearchProjectMasterListFields = () => {
  const { errors, register, setValue, watch } = useFormContext();
  const isMasterListWatcher = watch(FORM_FIELDS_MAP.isMasterList.key);

  useEffect(() => {
    register({ name: FORM_FIELDS_MAP.isMasterList.key });
    register(
      { name: FORM_FIELDS_MAP.seatCount.key },
      {
        validate: {
          positive: value => {
            if (!value) return true;

            return value < 1 ? formCopies.sections.seatCount.atLeastOneMessage : true;
          },
          shouldBeInteger: value => {
            if (!value) return true;
            return value % 1 !== 0 ? formCopies.sections.seatCount.shouldBeInteger : true;
          }
        }
      }
    );
  }, [register]);

  const handleMasterListChange = ({ target }) => setValue(target.name, target.checked);

  return (
    <>
      <NumberedForm.Item title={formCopies.sections.titles.markAsMasterList}>
        <FormControlLabel
          label={formCopies.sections.markAsMasterList.label}
          control={
            <Checkbox
              name={FORM_FIELDS_MAP.isMasterList.key}
              checked={!!isMasterListWatcher}
              onChange={handleMasterListChange}
              color="primary"
            />
          }
        />
      </NumberedForm.Item>
      <NumberedForm.Item title={formCopies.sections.titles.geoPolygon}>
        <TextBox
          label={formCopies.sections.geoPolygon.label}
          name={FORM_FIELDS_MAP.geoPolygon.key}
          inputRef={register()}
        />
      </NumberedForm.Item>
      <NumberedForm.Item title={formCopies.sections.titles.seatCount}>
        <TextBox
          label={formCopies.sections.seatCount.label}
          name={FORM_FIELDS_MAP.seatCount.key}
          onChange={(name, value) => setValue(name, value, true)}
          error={!!errors[FORM_FIELDS_MAP.seatCount.key]}
          errorText={errors[FORM_FIELDS_MAP.seatCount.key]?.message}
          value={watch(FORM_FIELDS_MAP.seatCount.key)}
          type="number"
        />
      </NumberedForm.Item>
    </>
  );
};

export default SearchProjectMasterListFields;
