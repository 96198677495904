import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { container, navBarHeight } from 'UI/constants/dimensions';
import { colors } from 'UI/res';

const BORDER_STYLE = `1px solid ${THEME.palette.customColors.borderColor}`;

export const useStyles = makeStyles(theme => ({
  panelContainer: {
    border: BORDER_STYLE,
    backgroundColor: colors.sideBar,
    height: `calc(100vh - ${navBarHeight}px)`,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },

  leftHeaderContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50]
  },
  leftHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  leftContent: {
    flexGrow: 1,
    overflowY: 'auto',
    paddingBottom: theme.spacing(3)
  },

  rightHeaderContainer: {
    backgroundColor: colors.sideBar,
    borderRadius: 0,
    boxShadow: container.boxShadow,
    padding: theme.spacing(2),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rightHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  rightHeaderTitle: {
    marginLeft: theme.spacing(2)
  }
}));
