import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(4),
  width: '100%'
}));

export const LoaderContaier = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center'
});
