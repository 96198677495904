import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InlineFilters } from 'features/command-center';
import { ENDPOINTS } from 'features/command-center/constants';
import omit from 'lodash/omit';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { When } from 'UI/components/atoms/When';
import { ActivityDrawer } from 'UI/components/organisms/ActivityDrawer';
import { RowActions } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getDefaultDashboardPeriod } from 'UI/utils';

import useActivitiesListing from './hooks/useActivitiesListing';
import { ActivitiesListing } from './ActivitiesListing';
import { NOT_REGISTERED_CALL_ENTITY, TABLE_CONFIG_BY_ENTITY_MAP } from './ActivityTable.constants';
import { buildDateRangeFilters, processNumberOfContactsFilters } from './ActivityTable.utils';

export const ActivityTable = ({ entity, recruiterId, globalFilters, canExpandRow }) => {
  const [activity, setActivity] = useState({ id: null, entityId: null, entity: null });
  const [filters, setFilters] = useState({});

  const entityConfig = useMemo(() => TABLE_CONFIG_BY_ENTITY_MAP[entity], [entity]);

  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const isActionsColumnEnabled = checkIfFeatureFlagEnabled(FeatureFlags.ActivityDrawerInTablesCC);

  const dateRangeValue = globalFilters?.dateRange?.value;

  const finalFilters = useMemo(() => {
    const processedFilters = processNumberOfContactsFilters(filters);
    const dateRangeFilters = buildDateRangeFilters(dateRangeValue || getDefaultDashboardPeriod());
    return {
      ...omit(globalFilters, 'dateRange'),
      ...processedFilters,
      ...dateRangeFilters
    };
  }, [dateRangeValue, filters, globalFilters]);

  const handleFiltersChange = useCallback(newFilters => {
    setFilters(newFilters);
  }, []);

  const endpoint = useMemo(
    () => `${ENDPOINTS.recruitersActivity}?entityType=${entity}`.replace(':userId', recruiterId),
    [entity, recruiterId]
  );

  const handleOpenActivityDrawer = useCallback(
    item => {
      const { entityIdKey = '' } = entityConfig;
      setActivity({ id: item.id, entityId: item[entityIdKey] });
    },
    [entityConfig]
  );

  const handleCloseDrawer = () => setActivity({ id: null, entityId: null });

  const rowActions = useMemo(
    () =>
      isActionsColumnEnabled && entity !== NOT_REGISTERED_CALL_ENTITY
        ? [
            {
              name: RowActions.View,
              customAction: 'rowClickBuilder',
              onClick: handleOpenActivityDrawer
            }
          ]
        : [],
    [entity, handleOpenActivityDrawer, isActionsColumnEnabled]
  );

  const {
    dataTable,
    finalData,
    handleExpansion,
    handleFormCancel,
    handleSubmitSucceed,
    expandedRows,
    outcomeOptions
  } = useActivitiesListing({
    columns: entityConfig?.columns,
    pageKey: entityConfig?.persistenceKey,
    formatResponse: entityConfig?.formatResponse,
    orderByOptions: entityConfig?.orderBy,
    endpoint,
    initialFilters: finalFilters,
    entity
  });

  const refreshTable = () => dataTable.getData();

  return (
    <>
      <Grid item xs={12}>
        <InlineFilters
          persistenceKey={entityConfig.persistenceKey}
          filters={entityConfig.filtersConfig}
          onFiltersChange={handleFiltersChange}
        />
      </Grid>
      <Grid item xs={12}>
        <ActivitiesListing
          dataTable={dataTable}
          finalData={finalData}
          expandedRows={expandedRows}
          handleExpansion={handleExpansion}
          handleFormCancel={handleFormCancel}
          handleSubmitSucceed={handleSubmitSucceed}
          outcomeOptions={outcomeOptions}
          entity={entity}
          entityConfig={entityConfig}
          endpoint={endpoint}
          columnsDefinitions={entityConfig.columns}
          filters={finalFilters}
          canExpandRow={canExpandRow}
          rowActions={rowActions}
        />
      </Grid>
      <When condition={activity.id}>
        <ActivityDrawer
          activity={activity}
          entity={entity}
          open={activity.id}
          onClose={handleCloseDrawer}
          onSubmitSucceed={refreshTable}
        />
      </When>
    </>
  );
};
