import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { Loader } from 'features/command-center/components/shared/Widget/Loader';
import { ENDPOINTS } from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { DRAWER_CONTENT_TYPES } from 'UI/pages/CommandCenter/OverallPerformance/OverallPerformance.constants';

import { DrawerContent } from '../DrawerContent';
import { FORM_FIELDS_MAP } from '../FeedbackNoteForm/FeedbackNoteForm.constants';

import { useStyles } from './FeedbackNoteDrawer.styles';

const { feedbackNotes: STRINGS } = strings.commandCenter;

export const FeedbackNoteDrawer = ({ initialDrawerContent, employeeNumber, onClose }) => {
  const [selectedNoteId, setSelectedNoteId] = useState(null);

  const [uiState, setUIState] = useState({
    drawerContent: initialDrawerContent,
    isLoading: false,
    isSaving: false
  });

  const isFeedbackLogView = uiState.drawerContent === DRAWER_CONTENT_TYPES.FeedbackLog;

  const classes = useStyles({ isFeedbackLogView });
  const dispatch = useDispatch();

  const form = useForm();
  const { handleSubmit, reset } = form;

  const handleNewNoteClick = () => {
    setUIState(prev => ({ ...prev, drawerContent: DRAWER_CONTENT_TYPES.NotesForm }));
  };

  const cleanForm = () =>
    reset({
      [FORM_FIELDS_MAP.ReachOutReason.key]: '',
      [FORM_FIELDS_MAP.AddressableIssues.key]: '',
      [FORM_FIELDS_MAP.Description.key]: '',
      [FORM_FIELDS_MAP.Contract.key]: ''
    });

  const handleEditNoteClick = async id => {
    if (!id) return;
    setSelectedNoteId(id);
    setUIState(prev => ({
      ...prev,
      isLoading: true,
      drawerContent: DRAWER_CONTENT_TYPES.NotesForm
    }));
    const endpoint = ENDPOINTS.feedbackNotesById.replace(':id', id);
    const { data } = await SECONDARY_API_CLIENT.get(endpoint);
    reset({
      [FORM_FIELDS_MAP.ReachOutReason.key]: data.reachOutReason,
      [FORM_FIELDS_MAP.AddressableIssues.key]: data.addressableIssues,
      [FORM_FIELDS_MAP.Description.key]: data.description,
      [FORM_FIELDS_MAP.Contract.key]: data.contract
    });
    setUIState(prev => ({
      ...prev,
      isLoading: false
    }));
  };

  const closeForm = () => {
    setSelectedNoteId(null);
    setUIState(prev => ({
      ...prev,
      drawerContent: DRAWER_CONTENT_TYPES.FeedbackLog
    }));
    cleanForm();
  };

  const onSubmit = async formData => {
    try {
      setUIState(prev => ({
        ...prev,
        isSaving: true
      }));
      const request = selectedNoteId ? SECONDARY_API_CLIENT.put : SECONDARY_API_CLIENT.post;
      const endpoint = selectedNoteId
        ? `${ENDPOINTS.feedbackNotesById.replace(':id', selectedNoteId)}`
        : ENDPOINTS.feedbackNotes;

      const addressableIssueIds = formData[FORM_FIELDS_MAP.AddressableIssues.key]
        .map(item => item.id)
        .join(',');

      const dataToSend = {
        reachOutReasonId: formData[FORM_FIELDS_MAP.ReachOutReason.key].id,
        addressableIssueIds,
        description: formData[FORM_FIELDS_MAP.Description.key],
        contract: formData[FORM_FIELDS_MAP.Contract.key]
      };

      if (!selectedNoteId) {
        dataToSend.empNo = employeeNumber;
      }

      const action = selectedNoteId ? 'updated' : 'created';

      const response = await request(endpoint, dataToSend);

      if (
        [HTTPStatusCodes.Ok, HTTPStatusCodes.Created, HTTPStatusCodes.NoContent].includes(
          response.status
        )
      ) {
        dispatch(
          showAlert({
            severity: 'success',
            title: STRINGS.title,
            body: strings.formatString(STRINGS.drawer.form.alert.success, {
              action
            })
          })
        );
        closeForm();
      }
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: STRINGS.title,
          autoHideDuration: 5000,
          body: STRINGS.drawer.form.alert.error
        })
      );
    } finally {
      setUIState(prev => ({
        ...prev,
        isSaving: false
      }));
    }
  };

  return (
    <DrawerContentLayout
      title={strings.commandCenter.feedbackNotes.drawer.title}
      footerActionsProps={{
        hidePrimaryButton: isFeedbackLogView
      }}
      drawerProps={{
        open: !!employeeNumber,
        classes: { paper: classes.drawerLarge }
      }}
      uiState={{ isSaving: uiState.isSaving, isFormLoading: uiState.isLoading }}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      contentProps={{ className: classes.drawerContent }}
    >
      {uiState.isLoading ? (
        <Loader />
      ) : (
        <FormContext {...form}>
          <DrawerContent
            drawerContent={uiState.drawerContent}
            employeeNumber={employeeNumber}
            selectedNoteId={selectedNoteId}
            onNewItemClick={handleNewNoteClick}
            onEditNoteClick={handleEditNoteClick}
            onEditComplete={closeForm}
            onBackClick={closeForm}
          />
        </FormContext>
      )}
    </DrawerContentLayout>
  );
};
