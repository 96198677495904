// @flow
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import API from 'services/API';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import FPTextEditor from 'UI/components/molecules/FPTextEditor';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { tinymceConfig } from 'UI/constants/config';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage, toLocalTime } from 'UI/utils';

import { useStyles } from './styles';

type NoteFormProps = {
  endpoint: string,
  selectedItem: any,
  onNoteCompleted: (note: any) => void,
  onNoteClosed: () => void,
  itemName: string,
  initialValues: Object,
  isQuickViewMode: boolean
};

const DRAWER_ACTIONS = {
  update: 'Updated',
  create: 'Created'
};

const NoteForm = ({
  endpoint,
  selectedItem,
  onNoteCompleted,
  onNoteClosed,
  itemName,
  initialValues,
  isQuickViewMode
}: NoteFormProps) => {
  const editorRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [finalItem, setFinalItem] = useState(selectedItem);

  const isEditing = !!finalItem?.id;

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isEditing,
    isFormDisabled: false,
    isReadOnly: false
  });

  const localTime = finalItem?.created_at && toLocalTime(finalItem.created_at);
  const onlyCreationDate = localTime && localTime.format(DateFormats.SimpleDate);
  const onlyCreationTime = localTime && localTime.format(DateFormats.SimpleTime);

  const finalEndpoint = useMemo(() => `${endpoint}/${Endpoints.Notes}`, [endpoint]);

  const { register, errors, handleSubmit, setValue, watch } = useForm(
    selectedItem ? { defaultValues: { ...selectedItem } } : { defaultValues: initialValues }
  );
  const watchers = watch(['body']);

  useEffect(() => {
    register(
      { name: 'body' },
      { required: strings.inventoryProfiles.sections.tabs.activityNote.form.validation.note }
    );
  }, [register]);

  useEffect(() => {
    selectedItem?.id &&
      (async () => {
        const response = await API.get(`${finalEndpoint}/${selectedItem.id}`);
        if (response.status === HTTPStatusCodes.Ok) {
          setFinalItem(prev => ({ ...prev, ...response.data }));
        }
      })();
  }, [endpoint, selectedItem, finalEndpoint]);

  const handleEditorChange = editorValue => setValue('body', editorValue, true);

  const saveForm = async formData => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));
    await saveNote(formData);
  };

  const saveNote = async (note: any) => {
    try {
      const response = !uiState.isEditing
        ? await API.post(finalEndpoint, note)
        : await API.put(`${finalEndpoint}/${finalItem.id}`, note);

      if (response.data) {
        dispatch(
          showAlert({
            severity: 'success',
            title: strings.formatString(
              strings.inventoryProfiles.sections.tabs.activityNote.creationAction,
              {
                section: 'Note',
                action: uiState.isEditing ? DRAWER_ACTIONS.update : DRAWER_ACTIONS.create
              }
            ),
            body: itemName
          })
        );

        onNoteCompleted(!uiState.isEditing ? response.data.data : response.data);
      }
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Error',
          body: getErrorMessage(error)
        })
      );
      setUiState(prevState => ({
        ...prevState,
        isSuccess: false,
        isSaving: false
      }));
    }
  };

  const initEditor = (e, editor) => {
    editorRef.current = editor;
  };

  const drawerCopies = {
    button: isQuickViewMode
      ? strings.inventoryProfiles.sections.tabs.activityNote.drawer.backAction
      : strings.shared.ui.close,
    title: strings.inventoryProfiles.sections.tabs.activityNote.drawer.title.note
  };

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(saveForm)}
      onClose={onNoteClosed}
      uiState={uiState}
      secondaryButtonText={drawerCopies.button}
      title={drawerCopies.title}
    >
      <div className={classes.root}>
        <Box mt={2} mb={3}>
          <TextBox
            name="title"
            label="Title *"
            error={!!errors.title}
            errorText={errors.title && errors.title.message}
            inputRef={register({
              required: strings.inventoryProfiles.sections.tabs.activityNote.form.validation.title
            })}
            disabled={uiState.isFormDisabled}
          />
        </Box>
        {uiState.isFormDisabled || (
          <FPTextEditor
            onInit={initEditor}
            config={tinymceConfig}
            value={watchers.body}
            onChange={handleEditorChange}
            disabled={uiState.isFormDisabled}
            error={!!errors.body}
            errorText={errors.body && errors.body.message}
          />
        )}
        {finalItem?.user && (
          <FormHelperText className={classes.helper}>
            Created by{' '}
            <b>
              {finalItem?.user.initials} - {finalItem?.user.email}
            </b>
            {onlyCreationDate && (
              <>
                on <b>{onlyCreationDate}</b> at <b>{onlyCreationTime}</b>
              </>
            )}
          </FormHelperText>
        )}
      </div>
    </DrawerContentLayout>
  );
};

export default NoteForm;
