// @flow
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { restoreSearchProject } from 'services/searchProjects';
import { getAllSearchProjectsFolders } from 'services/searchProjects/Folders';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import TreeViewSelector from 'UI/components/molecules/TreeViewSelector/TreeViewSelector';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import {
  DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS,
  SEARCH_PROJECT_ROOT_FOLDERS
} from 'UI/constants/defaults';

const FORM_FIELDS_MAP = {
  parentFolder: {
    key: 'parentFolder'
  }
};

const { restoreSearchProject: formCopies } = strings.searchProjects.forms;

const RestoreSearchProjectDrawer = ({ searchProject, onClose, onRestore }) => {
  const dispatch = useDispatch();
  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });

  const form = useForm({
    defaultValues: { [FORM_FIELDS_MAP.parentFolder.key]: SEARCH_PROJECT_ROOT_FOLDERS.mine }
  });
  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async formData => {
    setUiState(prev => ({ ...prev, isSaving: true }));
    const { alert, success } = await restoreSearchProject({
      searchProjectId: searchProject.id,
      folderId: formData[FORM_FIELDS_MAP.parentFolder.key].id
    });
    dispatch(showAlert(alert));
    setUiState({ isSaving: false, isSuccess: success });

    if (success) {
      onRestore && onRestore();
      onClose();
    }
  });

  return (
    <DrawerContentLayout
      title={formCopies.title}
      drawerProps={{ open: true }}
      onClose={onClose}
      onSubmit={onSubmit}
      uiState={uiState}
    >
      <FormContext {...form}>
        <NumberedForm>
          <NumberedForm.Item title={formCopies.sections.name.title}>
            <TextBox value={searchProject?.name} disabled />
          </NumberedForm.Item>
          <NumberedForm.Item title={formCopies.sections.parentFolder.title}>
            <TreeViewSelector
              shouldRegisterWithin
              formFieldName={FORM_FIELDS_MAP.parentFolder.key}
              placeholder={formCopies.sections.parentFolder.placeholder}
              TreeViewLoaderProps={{
                enableIconClick: true,
                onlyShowFolders: true,
                service: {
                  method: getAllSearchProjectsFolders,
                  queryParams: { excludeIds: DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS }
                }
              }}
            />
          </NumberedForm.Item>
        </NumberedForm>
      </FormContext>
    </DrawerContentLayout>
  );
};

export default RestoreSearchProjectDrawer;
