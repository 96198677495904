import { TABLES_KEYS } from 'features/command-center/constants';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { FilterType } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

export const PAGE_KEY = TABLES_KEYS.overallPerformanceReport;

const COPIES = strings.commandCenter.dailyPerformance.overallPerformanceReport;

export const FiltersGroups = [
  {
    id: 'recruiters',
    name: 'Recruiters',
    filters: ['employeeNumbers', 'teams', 'healthStatuses', 'startDate']
  },
  {
    id: 'sendouts',
    name: 'Sendouts',
    filters: [
      'rangeByCurrentWeekSendouts',
      'rangeByLastWeekSendouts',
      'rangeByThirdWeekSendouts',
      'rangeByFourthWeekSendouts',
      'rangeByFifthWeekSendouts',
      'rangeBySendoutPlacementRatio',
      'rangeByActiveSendouts',
      'rangeByCurrentWeekSendovers',
      'rangeByActiveSendovers'
    ]
  },
  {
    id: 'financial',
    name: 'Financial',
    filters: [
      'rangeByOutstandingBillings',
      'rangeByDrawBalance',
      'rangeByCashin',
      'rangeByYearlyProduction',
      'rangeByTrackingProduction'
    ],
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceFinancialFilters)
  },
  {
    id: 'metrics',
    name: 'Metrics',
    filters: [
      'rangeByInMails',
      'rangeByInMailsByBillingPeriod',
      'rangeByBulks',
      'rangeByPeopleReached',
      'rangeByPosts',
      'rangeByPhoneTime',
      'rangeByConnectedCalls',
      'rangeByNotConnectedCalls',
      'rangeByTotalCalls'
    ],
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceMetricsFilters)
  }
];

export const COLUMNS = [
  {
    name: 'idFortpac',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'empNo',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'recruiterName',
    label: 'Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CommandCenterOverviewRecruiter,
          idKey: 'empNo',
          textKey: 'name',
          enableInTabRedirect: true
        }
      },
      setCellHeaderProps: () => ({ className: 'dataTableStickyLeftColumnHeader' }),
      setCellProps: () => ({
        className: 'dataTableStickyLeftColumn'
      })
    }
  },
  {
    name: 'managerName',
    label: 'Team',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CommandCenterOverviewTeam,
          idKey: 'managerEmpNo',
          textKey: 'team',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'healthStatus',
    label: 'Current Health Status',
    options: {
      sort: true,
      display: true,
      renderer: 'healthStatusChip'
    }
  },
  {
    name: 'hireDate',
    label: 'Start Date',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'lastPlacementDate',
    label: 'Last Placement',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'thisWeekSendouts',
    label: 'Current Week Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'lastWeekSendouts',
    label: 'Last Week Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'thirdWeekSendouts',
    label: 'Prev. Week 2 Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'fourthWeekSendouts',
    label: 'Prev. Week 3 Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'fifthWeekSendouts',
    label: 'Prev. Week 4 Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'activeSendouts',
    label: 'Active Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'soPlRatio',
    label: 'SO to PL',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'currentWeekActiveSendovers',
    label: 'Current Week Sendovers',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'activeSendovers',
    label: 'Active Sendovers',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'outstandingBillings',
    label: 'Current O. Billings',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'drawBalance',
    label: 'Current Draw Balance',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'cashin',
    label: 'Cash In YTD',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'production',
    label: 'Production YTD',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'trackingProduction',
    label: 'Tracking Production',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'avgInMailsByBillingPeriod',
    label: 'InMails',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'inMailsByBillingPeriod',
    label: 'Billing P. InMails',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'bulks',
    label: 'Bulks',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'peopleReached',
    label: 'People Reached',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'todayLivePosts',
    label: 'Prev. Day Active Posts',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'callsTime',
    label: 'Phone Time',
    options: {
      sort: true,
      display: true,
      renderer: 'phoneTime'
    }
  },
  {
    name: 'calls',
    label: 'Calls',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'notConnectedCalls',
    label: 'Not Conn. Calls',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'totalCalls',
    label: 'Total Calls',
    options: {
      sort: false,
      display: true,
      renderer: 'formattedNumber'
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'recruiterName',
  direction: 'asc'
};

export const INLINE_FILTERS = ['team', 'healthStatus'];

export const METRICS_DESCRIPTION = [
  COPIES.footer.metrics.inMails,
  COPIES.footer.metrics.SOtoPL,
  COPIES.footer.metrics.calls,
  COPIES.footer.metrics.posts
];

export const extraFilters = [
  {
    name: 'rangeByCurrentWeekSendouts',
    paramName: 'currentWeekSendouts',
    title: COPIES.filters.sendouts.currentWeek,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.currentWeek
  },
  {
    name: 'rangeByLastWeekSendouts',
    paramName: 'lastWeekSendouts',
    title: COPIES.filters.sendouts.lastWeek,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.lastWeek
  },
  {
    name: 'rangeByThirdWeekSendouts',
    paramName: 'thirdWeekSendouts',
    title: COPIES.filters.sendouts.previousWeekTwo,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.previousWeekTwo
  },
  {
    name: 'rangeByFourthWeekSendouts',
    paramName: 'fourthWeekSendouts',
    title: COPIES.filters.sendouts.previousWeekThree,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.previousWeekThree
  },
  {
    name: 'rangeByFifthWeekSendouts',
    paramName: 'fifthWeekSendouts',
    title: COPIES.filters.sendouts.previousWeekFour,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.previousWeekFour
  },
  {
    name: 'rangeBySendoutPlacementRatio',
    paramName: 'sendoutToPlacementRatio',
    title: COPIES.filters.sendouts.placementRatio,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.placementRatio
  },
  {
    name: 'rangeByActiveSendouts',
    paramName: 'activeSendouts',
    title: COPIES.filters.sendouts.active,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.active
  },
  {
    name: 'rangeByCurrentWeekSendovers',
    paramName: 'currentWeekActiveSendovers',
    title: COPIES.filters.sendouts.currentWeekSendovers,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.currentWeekSendovers
  },
  {
    name: 'rangeByActiveSendovers',
    paramName: 'activeSendovers',
    title: COPIES.filters.sendouts.activeSendovers,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.activeSendovers
  },
  {
    name: 'rangeByOutstandingBillings',
    paramName: 'outstandingBillings',
    title: COPIES.filters.financial.outstandingBillings,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.outstandingBillings,
    inputType: 'currency',
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceFinancialFilters)
  },
  {
    name: 'rangeByDrawBalance',
    paramName: 'drawBalance',
    title: COPIES.filters.financial.drawBalance,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.drawBalance,
    inputType: 'currency',
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceFinancialFilters)
  },
  {
    name: 'rangeByCashin',
    paramName: 'cashin',
    title: COPIES.filters.financial.cashin,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.cashin,
    inputType: 'currency',
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceFinancialFilters)
  },
  {
    name: 'rangeByYearlyProduction',
    paramName: 'production',
    title: COPIES.filters.financial.yearlyProduction,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.yearlyProduction,
    inputType: 'currency',
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceFinancialFilters)
  },
  {
    name: 'rangeByTrackingProduction',
    paramName: 'trackingProduction',
    title: COPIES.filters.financial.trackingProduction,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.trackingProduction,
    inputType: 'currency',
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceFinancialFilters)
  },
  {
    name: 'rangeByInMailsByBillingPeriod',
    paramName: 'inMailsByBillingPeriod',
    title: COPIES.filters.metrics.billingPeriodInMails,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.billingPeriodInMails,
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceMetricsFilters)
  },
  {
    name: 'rangeByInMails',
    paramName: 'inMails',
    title: COPIES.filters.metrics.inMails,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.inMails,
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceMetricsFilters)
  },
  {
    name: 'rangeByBulks',
    paramName: 'bulks',
    title: COPIES.filters.metrics.bulks,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.bulks,
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceMetricsFilters)
  },
  {
    name: 'rangeByPeopleReached',
    paramName: 'peopleReached',
    title: COPIES.filters.metrics.peopleReached,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.peopleReached,
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceMetricsFilters)
  },
  {
    name: 'rangeByPosts',
    paramName: 'posts',
    title: COPIES.filters.metrics.posts,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.posts,
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceMetricsFilters)
  },
  {
    name: 'rangeByPhoneTime',
    paramName: 'phoneTime',
    title: COPIES.filters.metrics.phoneTime,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.phoneTime,
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceMetricsFilters)
  },
  {
    name: 'rangeByConnectedCalls',
    paramName: 'connectedCalls',
    title: COPIES.filters.metrics.connectedCalls,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.connectedCalls,
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceMetricsFilters)
  },
  {
    name: 'rangeByNotConnectedCalls',
    paramName: 'notConnectedCalls',
    title: COPIES.filters.metrics.notConnectedCalls,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.notConnectedCalls
  },
  {
    name: 'rangeByTotalCalls',
    paramName: 'totalCalls',
    title: COPIES.filters.metrics.totalCalls,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.totalCalls
  }
];
