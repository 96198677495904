import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Drawers, OverallPerformance } from 'features/command-center';
import { DrawerManagerProvider } from 'features/command-center/contexts';
import usePageTitle from 'hooks/usePageTitle';
import { PageTitles } from 'UI/constants/defaults';

export const Overview = () => {
  usePageTitle({ title: PageTitles.CommandCenterActivity });

  return (
    <DrawerManagerProvider>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <OverallPerformance />
        </Grid>
      </Grid>
      <Drawers />
    </DrawerManagerProvider>
  );
};
