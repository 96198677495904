import React from 'react';
import List from '@material-ui/core/List';
import { useInfiniteScroll } from 'features/message-center/hooks';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import LoaderContent from 'UI/components/templates/DrawerContentLayout/LoaderContent';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { SMSEmptyState } from 'UI/res';

import { Conversation } from './Conversation';
import { useStyles } from './styles';

const COPIES = strings.messageCenter;

export const ConversationList = ({
  conversations = [],
  isLoading,
  onClickConversation,
  onFetchMoreConversations,
  searchKeyword,
  selectedConversationId
}) => {
  const classes = useStyles();
  const { listEndRef } = useInfiniteScroll({ onScrollEndTrigger: onFetchMoreConversations });

  const handleClickConversation = selection => onClickConversation(selection);

  return (
    <div className={classes.container}>
      <div className={classes.listContainer}>
        <When condition={conversations.length === 0 && !isLoading}>
          <EmptyPlaceholder
            title={
              searchKeyword ? COPIES.emptyState.search.title : COPIES.emptyState.conversations.title
            }
            subtitle={
              searchKeyword
                ? COPIES.emptyState.search.subtitle
                : COPIES.emptyState.conversations.subtitle
            }
            customEmptyState={<SMSEmptyState height={300} />}
          />
        </When>
        <List>
          {conversations.map(conversation => (
            <Conversation
              key={conversation.id}
              isSelected={selectedConversationId === conversation.id}
              conversation={conversation}
              onClickConversation={handleClickConversation}
              shouldTruncate
            />
          ))}
        </List>

        {isLoading && <LoaderContent color="primary" />}

        <div ref={listEndRef} />
      </div>
    </div>
  );
};
