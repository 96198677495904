import React, { useCallback, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { StatsItemsCard } from 'features/command-center/components/shared';
import { getProductivityTotals } from 'features/command-center/services';
import strings from 'strings';
import PlacementEditForm from 'UI/components/organisms/placements/PlacementEditForm';
import SendoutManager from 'UI/components/organisms/sendouts';

import { formatStats } from './LastDateStatsSummary.utils';

export const LastDateStatsSummary = ({ requestParams, hasDrillDown }) => {
  const [selectedSendoutId, setSelectedSendoutId] = useState(null);
  const [selectedPlacementId, setSelectedPlacementId] = useState(null);

  const cachedPromises = useCallback(() => {
    const drawerHandlers = hasDrillDown
      ? {
          lastPlacement: ({ id }) => {
            id && setSelectedPlacementId(id);
          },
          lastSendout: ({ id }) => {
            id && setSelectedSendoutId(id);
          }
        }
      : null;

    const { recruiter } = requestParams;
    const groupBy = recruiter ? 'recruiter' : 'team';

    return Promise.all([getProductivityTotals({ ...requestParams, groupBy })]).then(
      ([sendoutsAndSignedAgreements]) => {
        return formatStats({ data: sendoutsAndSignedAgreements?.data?.data, drawerHandlers });
      }
    );
  }, [hasDrillDown, requestParams]);

  const closeSendoutDrawer = () => {
    setSelectedSendoutId(null);
  };

  const closePlacementDrawer = () => {
    setSelectedPlacementId(null);
  };

  return (
    <>
      <StatsItemsCard
        header={strings.commandCenter.activity.lastDateStatsSummary.title}
        promise={cachedPromises}
        textAlign="center"
      />
      {!!selectedSendoutId && (
        <Drawer open={!!selectedSendoutId} onClose={closeSendoutDrawer}>
          <div role="presentation">
            <SendoutManager onClose={closeSendoutDrawer} id={selectedSendoutId} />
          </div>
        </Drawer>
      )}

      {!!selectedPlacementId && (
        <Drawer open={!!selectedPlacementId} onClose={closePlacementDrawer}>
          <div role="presentation">
            <PlacementEditForm
              placementId={selectedPlacementId}
              onClose={closePlacementDrawer}
              onCompleted={closePlacementDrawer}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};
