import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useRefreshStats } from 'features/command-center/hooks';
import strings from 'strings';
import { Overview } from 'UI/pages/CommandCenter/Activity/Overview';

import { Layout } from '../Layout';

export const ActivityLayout = () => {
  const { refreshData, refreshKey } = useRefreshStats();
  return (
    <Layout
      title={strings.commandCenter.overallPerformance.title}
      path={strings.commandCenter.title}
      onRefresh={refreshData}
    >
      <Grid key={refreshKey} container spacing={3}>
        <Grid item xs={12}>
          <Overview />
        </Grid>
      </Grid>
    </Layout>
  );
};
