import queryString from 'query-string';
import { SECONDARY_API_CLIENT } from 'services/API';

import { ENDPOINTS } from '../constants';

export const getConversations = async (params = {}) => {
  const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });

  return SECONDARY_API_CLIENT.get(`${ENDPOINTS.messages}?${queryParams}`).then(({ data }) => data);
};
export const getConversationsV2 = async (params = {}) => {
  const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });

  return SECONDARY_API_CLIENT.get(`${ENDPOINTS.conversationsV2}?${queryParams}`).then(
    ({ data }) => data
  );
};

export const searchConversations = async (params = {}) => {
  const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });

  return SECONDARY_API_CLIENT.get(`${ENDPOINTS.searchByKeyword}?${queryParams}`).then(
    ({ data }) => data
  );
};
export const searchV2 = async (params = {}) => {
  const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });

  return SECONDARY_API_CLIENT.get(`${ENDPOINTS.search}?${queryParams}`).then(({ data }) => data);
};

export const getConversation = async (params = {}) => {
  const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });

  return SECONDARY_API_CLIENT.get(`${ENDPOINTS.conversations}?${queryParams}`).then(
    ({ data }) => data
  );
};
export const getConversationMessages = async (params = {}) => {
  const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });

  return SECONDARY_API_CLIENT.get(`${ENDPOINTS.messagesV2}?${queryParams}`).then(
    ({ data }) => data
  );
};

export const sendMessageToServer = async message => {
  const { to, from, message: body, attachment, contact } = message;
  const formattedMessage = {
    to,
    from,
    body,
    attachmentUrl: attachment.url,
    attachmentSize: attachment.size,
    contact
  };

  return SECONDARY_API_CLIENT.post(ENDPOINTS.messages, formattedMessage).then(({ data }) => data);
};

export const resetUnreadMessages = async (params = {}) => {
  const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });

  return SECONDARY_API_CLIENT.put(`${ENDPOINTS.resetUnreadMessages}?${queryParams}`).then(
    ({ data }) => data
  );
};
