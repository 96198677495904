export const updateConversationList = ({ conversations, id, updates }) => {
  if (!id) return conversations;

  const conversationIndex = conversations.findIndex(conversation => conversation.id === id);
  if (conversationIndex === -1) return conversations; // No matching conversation, return original

  const updatedConversation = {
    ...conversations[conversationIndex],
    ...updates
  };

  // Update in place without reordering
  return conversations.map((conversation, index) =>
    index === conversationIndex ? updatedConversation : conversation
  );
};

export const formatAttachmentsInResponse = responseMessages => {
  if (!responseMessages) return [];

  return responseMessages.map(item => ({
    ...item,
    attachment: item.attachments?.[0]
      ? { url: item.attachments[0], size: item?.attachmentSize }
      : null
  }));
};

/**
 * Finds the newest and oldest items in an array by date.
 * @param {Array} items - Array of objects with a `date` property.
 * @returns {{ newest: object | null, oldest: object | null }} - An object with `newest` and `oldest` items or `null` if invalid.
 */
export const findExtremeItemsByDate = items => {
  if (!Array.isArray(items) || items.length === 0) {
    return { newest: null, oldest: null };
  }

  const hasInvalidDate = items.some(
    item => !item.date || Number.isNaN(new Date(item.date).getTime())
  );

  if (hasInvalidDate) {
    return { newest: null, oldest: null };
  }

  return items.reduce(
    (extremes, current) => {
      const currentDate = new Date(current.date);
      const newest = currentDate > new Date(extremes.newest.date) ? current : extremes.newest;
      const oldest = currentDate < new Date(extremes.oldest.date) ? current : extremes.oldest;

      return { newest, oldest };
    },
    { newest: items[0], oldest: items[0] }
  );
};
