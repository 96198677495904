export const TEXT_MESSAGES_BASE_URL = '/texting';

export const ENDPOINTS = {
  conversations: `${TEXT_MESSAGES_BASE_URL}/conversations`,
  messages: `${TEXT_MESSAGES_BASE_URL}/messages`,
  searchByKeyword: `${TEXT_MESSAGES_BASE_URL}/search/contact`,
  resetUnreadMessages: `${TEXT_MESSAGES_BASE_URL}/messages/reset-unread-counter`,

  // v2 endpoints
  conversationsV2: `${TEXT_MESSAGES_BASE_URL}/v2/conversations`,
  messagesV2: `${TEXT_MESSAGES_BASE_URL}/v2/messages`,
  search: `${TEXT_MESSAGES_BASE_URL}/search`
};
