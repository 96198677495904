// @flow
import { EntityRoutes } from 'routes/constants';
import { Permissions } from 'services/Authorization';
import { DateFormats } from 'UI/constants/defaults';
import { Roles } from 'UI/constants/roles';

export const columnsSendouts = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-type'
        }
      }
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-status',
          variant: 'outlined'
        }
      }
    }
  },
  {
    name: 'sent_on',
    label: 'Resume Sent on',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'tracking_date',
    label: 'Tracking Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name'
        }
      }
    }
  },
  {
    name: 'jobOrder',
    label: 'Job Order',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'name'
        }
      }
    }
  },
  {
    name: 'candidate',
    label: 'Candidate',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'name'
        }
      }
    }
  },
  {
    name: 'hiringAuthority',
    label: 'Hiring Authority',
    options: {
      sort: true,
      display: false,
      renderer: {
        id: 'hiringAuthority',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'id',
          textKey: 'full_name'
        }
      }
    }
  },
  {
    name: 'industry',
    label: 'JO Industry',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'specialty',
    label: 'JO Specialty',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'functional_title',
    label: 'JO Functional Title',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'coach',
    label: 'Team',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'state_name',
    label: 'State',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'city_name',
    label: 'City',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'recruiters',
    label: 'Recruiters',
    options: {
      sort: false,
      display: true
    }
  },
  {
    name: 'fee_amount',
    label: 'Total Fee',
    options: {
      sort: false,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'interview_date',
    label: 'Interview Date',
    options: {
      sort: true,
      display: false,
      renderer: 'date'
    }
  },
  {
    name: 'placement_date',
    label: 'Placement Date',
    options: {
      sort: true,
      display: false,
      renderer: { id: 'dateBuilder', config: { format: DateFormats.SimpleDate } }
    }
  }
];

const sendoutsFilterRoles = {
  individual: [Roles.Recruiter, Roles.Staff],
  team: [Roles.Coach, Roles.AssistantRegionalDirector],
  all: [
    Roles.DataCoordinator,
    Roles.Operations,
    Roles.RegionalDirector,
    Roles.Admin,
    Roles.Finance,
    Roles.Leadership,
    Roles.ProductionDirector
  ],
  regional: [Roles.RegionalDirector, Roles.Leadership]
};

export const CustomScopeFilters = [
  {
    id: 6,
    title: 'My Inventory Sendouts',
    roles: sendoutsFilterRoles.individual
  },
  {
    id: 0,
    title: 'My Sendouts',
    roles: [...sendoutsFilterRoles.team, ...sendoutsFilterRoles.individual]
  },
  {
    id: 2,
    title: 'My Team Sendouts',
    roles: sendoutsFilterRoles.team
  },
  {
    id: 3,
    title: 'All Sendouts at gpac',
    roles: sendoutsFilterRoles.all,
    permissions: [Permissions.Sendouts.CanViewAllHotsheet]
  }
];

export const FiltersInclude = ['status'];

export const FiltersGroup = [
  {
    id: 'team',
    name: 'Team',
    filters: ['coaches', 'recruiters', 'jobOrderRecruiters', 'candidateRecruiters']
  },
  {
    id: 'general',
    name: 'Sendout',
    filters: ['sendoutTypes', 'sendoutStatuses', 'candidates', 'hiringAuthorities', 'companies']
  },
  {
    id: 'industries',
    name: 'Job Order Industry',
    filters: ['industries', 'specialties', 'positions']
  },
  { id: 'location', name: 'Company Location', filters: ['countries', 'states', 'cities', 'zips'] }
];

export const SendoutSortColumnBySection = {
  0: 'tracking_date',
  1: 'interview_date',
  2: 'placement_date'
};

export const SendoutPeriodColumnBySection = {
  ...SendoutSortColumnBySection
};

export const SendoutTrackingDateFilter = { id: 0, title: 'Tracking Date', column: 'tracking_date' };

export const SendoutInterviewDateFilter = {
  id: 1,
  title: 'Interview Date',
  column: 'interview_date'
};

export const SendoutPlacementDateFilter = {
  id: 2,
  title: 'Placement Date',
  column: 'placement_date'
};

export const SendoutDateFilters = [
  SendoutTrackingDateFilter,
  SendoutInterviewDateFilter,
  SendoutPlacementDateFilter
];
