import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ENDPOINTS } from 'features/command-center/constants';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import FPHtmlViewer from 'UI/components/atoms/FPHtmlViewer';
import InfoLabel from 'UI/components/molecules/InfoLabel';
import { DateFormats } from 'UI/constants/defaults';
import { UIStatus } from 'UI/constants/status';
import { toLocalTime } from 'UI/utils';

import { Container, LoaderContaier } from './FeedbackNoteDetails.styled';

const { fields: FIELDS_COPIES } = strings.commandCenter.feedbackNotes.noteDetailsDrawer;

export const FeedbackNoteDetails = ({ id }) => {
  const { state } = useFetchWithStatusV2({
    apiInstance: SECONDARY_API_CLIENT,
    endpoint: ENDPOINTS.feedbackNotesById.replace(':id', id),
    initialStatus: UIStatus.Default
  });

  const note = state?.results;
  const formattedIssuesToAddress = note?.addressableIssues
    ? note?.addressableIssues.map(item => ({
        label: item?.title ?? '',
        textColor: 'black'
      }))
    : [];

  return (
    <Container>
      {state.status === UIStatus.Loading ? (
        <LoaderContaier>
          <CircularProgress />
        </LoaderContaier>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h5">{note?.reachOutReason?.title}</Typography>
          </Grid>

          <Grid container item>
            <Grid item xs={6}>
              <InfoLabel
                cropped={false}
                title={FIELDS_COPIES.createdBy}
                description={note?.createdBy?.fullName}
              />
            </Grid>

            <Grid item xs={6}>
              <InfoLabel
                cropped={false}
                title={FIELDS_COPIES.createdAt}
                description={
                  note?.createdAt
                    ? toLocalTime(note?.createdAt).format(DateFormats.SimpleDateTime)
                    : ''
                }
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.issuesToAddress}
              description={formattedIssuesToAddress}
              mode="chip"
            />
          </Grid>

          <Grid item xs={12}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.description}
              customValueRender={<FPHtmlViewer content={note?.description} />}
            />
          </Grid>

          <Grid item xs={12}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.contract}
              description={note?.contract}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};
