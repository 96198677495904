// @flow
import React from 'react';
import moment from 'moment';
import { InfoLabelModes } from 'UI/components/molecules/InfoLabel';
import { DateFormats, Yes } from 'UI/constants/defaults';
import { currencyFormatter, dateFormatter, localTimeFormatter } from 'UI/utils';

import { GRID_CONFIG } from './config';
import { titles } from './titles';

const formatInterviews = dateTimes => {
  return Object.keys(dateTimes)
    .map(key => `• ${localTimeFormatter(dateTimes[key], DateFormats.SimpleDateTime)}`)
    .join('\n');
};

const joinWithCommas = arr => {
  const filteredArray = arr.filter(Boolean);

  if (filteredArray.length === 1) {
    return filteredArray[0];
  }
  return filteredArray.join(', ');
};

const UNHAPPY_JOB_NUMBER = '10';

export const candidateBackgroundLabels = ({
  educationItems,
  employmentHistoryItems,
  referencesItems,
  licensesAndCertificationsItems
}) => {
  const education = educationItems.length
    ? educationItems.map(item => [
        {
          title: titles.education.school,
          description: item?.school,
          config: GRID_CONFIG.default
        },
        {
          title: titles.education.degree,
          description: item?.degreeMajor?.degree,
          config: GRID_CONFIG.default
        },
        {
          title: titles.common.from,
          description: dateFormatter(item?.fromTo?.from, format => format.MonthDayNumberYear),
          config: GRID_CONFIG.default
        },
        {
          title: titles.common.to,
          description: dateFormatter(item?.fromTo?.to, format => format.MonthDayNumberYear),
          config: GRID_CONFIG.default
        },
        {
          title: titles.education.major,
          description: item?.degreeMajor?.major,
          config: GRID_CONFIG.fullWidth,
          shouldAddDivider: true
        }
      ])
    : [];

  const company = employmentHistoryItems.length
    ? employmentHistoryItems.map(item => [
        {
          title: titles.company.company,
          description: item?.companyTitle?.company,
          config: GRID_CONFIG.default
        },
        {
          title: titles.company.title,
          description: item?.companyTitle?.title,
          config: GRID_CONFIG.default
        },
        {
          title: titles.common.from,
          description: dateFormatter(item?.fromTo?.from, format => format.MonthDayNumberYear),
          config: GRID_CONFIG.default
        },
        {
          title: titles.common.to,
          description: dateFormatter(item?.fromTo?.to, format => format.MonthDayNumberYear),
          config: GRID_CONFIG.default
        },
        {
          title: titles.company.duties,
          description: item?.dutiesAndResponsibilities,
          config: GRID_CONFIG.fullWidth
        },
        {
          title: titles.company.accomplishments,
          description: item?.accomplishments,
          config: GRID_CONFIG.fullWidth,
          shouldAddDivider: true
        }
      ])
    : [];

  const references = referencesItems && [
    {
      title: titles.references,
      description: referencesItems.map((item, index) => (
        <React.Fragment key={item?.fullNameAndTitle?.title}>
          {`${index + 1}. ${item.fullNameAndTitle?.fullName} / ${item?.fullNameAndTitle?.title} / ${
            item?.companyAndPhone?.company
          } / ${item?.companyAndPhone?.phone} / ${item?.relationship}`}
          <br />
        </React.Fragment>
      )),
      config: GRID_CONFIG.fullWidth,
      shouldAddDivider: false
    }
  ];

  const licenses = licensesAndCertificationsItems
    ? licensesAndCertificationsItems.map(item => [
        {
          title: titles.licenses.licenses,
          description: item?.name,
          config: GRID_CONFIG.default
        },
        {
          title: titles.licenses.issuing,
          description: item?.issuingOrganization,
          config: GRID_CONFIG.default
        },
        {
          title: titles.licenses.credential,
          description: item?.credentialID,
          config: GRID_CONFIG.default
        },
        {
          title: titles.licenses.credentialUrl,
          mode: InfoLabelModes.EXTERNAL_LINK,
          linkProps: {
            url: item?.credentialURL ?? ''
          },
          config: GRID_CONFIG.default
        },
        {
          title: titles.licenses.issueDate,
          description: dateFormatter(
            item?.issueAndExpirationDate?.issueDate,
            format => format.MonthDayNumberYear
          ),
          config: GRID_CONFIG.default
        },
        {
          title: titles.licenses.expirationDate,
          description: dateFormatter(
            item?.issueAndExpirationDate?.expirationDate,
            format => format.MonthDayNumberYear
          ),
          config: GRID_CONFIG.wide,
          shouldAddDivider: true
        }
      ])
    : [];

  return [...company, ...education, ...licenses, ...references];
};

export const professionalInsights = ({ achievements, skills, experienceSummary }) => {
  const professionalAchievements =
    achievements.length &&
    achievements.map(item => [
      {
        title: titles.achievement,
        description: item?.achievement,
        config: GRID_CONFIG.wide
      }
    ]);

  const experience = experienceSummary
    ? {
        title: titles.experience,
        description: experienceSummary ?? '',
        config: GRID_CONFIG.fullWidth
      }
    : [];

  const professionalSkills =
    Array.isArray(skills) && skills.length > 0
      ? [
          {
            title: titles.skills,
            description: skills,
            mode: InfoLabelModes.CHIP,
            config: GRID_CONFIG.fullWidth
          }
        ]
      : [];

  return [...professionalAchievements, experience, ...professionalSkills];
};

export const workPreferencesLabels = ({
  salaryRange,
  salary,
  desiredBenefits,
  areThereAnyCompaniesYouWouldLike,
  areThereAnyCompaniesYouWouldNotLike,
  preferredNextTitle,
  relocation,
  workPreference,
  whatDoYouLikeMost
}) => {
  const destinations =
    relocation?.relocationDestinations?.length &&
    relocation?.relocationDestinations.map(i => ({ label: `${i.title}, ${i.slug}` }));

  const salaryItems = [
    {
      title: titles.salary.currentCompany,
      description: whatDoYouLikeMost,
      config: GRID_CONFIG.fullWidth
    },
    {
      title: titles.salary.nextTitle,
      description: preferredNextTitle,
      config: GRID_CONFIG.default
    },
    {
      title: titles.salary.relocate,
      description: relocation?.areYouWillingToRelocate,
      config: GRID_CONFIG.default
    },
    relocation?.relocationDestinations?.length && {
      title: titles.salary.relocationDestinations,
      mode: 'chip',
      description: destinations,
      config: GRID_CONFIG.fullWidth
    },
    {
      title: titles.salary.workPreference,
      description: workPreference?.title,
      config: GRID_CONFIG.default
    },
    {
      title: titles.salary.compensationRange,
      description: salary,
      config: GRID_CONFIG.default
    },
    {
      title: titles.salary.lowSalary,
      description: currencyFormatter(salaryRange?.lowSalary),
      config: GRID_CONFIG.default
    },
    {
      title: titles.salary.idealSalary,
      description: currencyFormatter(salaryRange?.idealSalary),
      config: GRID_CONFIG.default
    },
    {
      title: titles.salary.highSalary,
      description: currencyFormatter(salaryRange?.highSalary),
      config: GRID_CONFIG.default
    },
    {
      title: titles.salary.desiredBenefits,
      description: desiredBenefits,
      config: GRID_CONFIG.fullWidth
    },
    {
      title: titles.salary.companiesYouLike,
      description: areThereAnyCompaniesYouWouldLike,
      config: GRID_CONFIG.fullWidth
    },
    {
      title: titles.salary.companiesYouDontLike,
      description: areThereAnyCompaniesYouWouldNotLike,
      config: GRID_CONFIG.fullWidth
    }
  ];

  return [...salaryItems];
};

export const establishingUrgencyLabels = establishingUrgency => {
  const isAtHappyJob = establishingUrgency?.onAScaleOf1To10?.question !== UNHAPPY_JOB_NUMBER;

  const haveYouMakeSomethingAChange =
    establishingUrgency?.onAScaleOf1To10?.haveYouMadeSomething?.question === Yes.title;

  const personalReasonForMakeAChange =
    (Array.isArray(establishingUrgency?.isThereAnythingPersonal) &&
      establishingUrgency?.isThereAnythingPersonal.length > 0 &&
      joinWithCommas(establishingUrgency?.isThereAnythingPersonal)) ||
    '';

  const counteroffer =
    (Array.isArray(establishingUrgency?.ifACompanyOffersYou) &&
      establishingUrgency?.ifACompanyOffersYou.length > 0 &&
      joinWithCommas(establishingUrgency?.ifACompanyOffersYou)) ||
    '';

  const urgencyLabels = [
    {
      title: titles.urgency.reasonForLooking,
      description: establishingUrgency?.whatIsTheReason || '',
      config: GRID_CONFIG.thirdWidth
    },
    {
      title: titles.urgency.howLongYouBeenLooking,
      description:
        (establishingUrgency?.howLongHaveYouBeenLooking &&
          moment(establishingUrgency?.howLongHaveYouBeenLooking).fromNow(true)) ||
        '',
      config: GRID_CONFIG.thirdWidth
    },
    {
      title: titles.urgency.startNewPosition,
      description: establishingUrgency?.idealTimeToStart?.title || '',
      config: GRID_CONFIG.thirdWidth
    },
    {
      title: titles.urgency.lookingDetails,
      description: establishingUrgency?.reasonForLooking || '',
      config: GRID_CONFIG.fullWidth
    },
    {
      title: titles.urgency.questionHappyAtJob,
      description: establishingUrgency?.onAScaleOf1To10?.question || '',
      config: GRID_CONFIG.fullWidth
    },
    isAtHappyJob
      ? {
          title: titles.urgency.keepsAt10,
          description: establishingUrgency?.onAScaleOf1To10?.whatKeepsYouFromBeingA10 || '',
          config: GRID_CONFIG.fullWidth
        }
      : null,
    {
      title: titles.urgency.somethingToMakeAChange,
      description: establishingUrgency?.onAScaleOf1To10?.haveYouMadeSomething?.question || '',
      config: GRID_CONFIG.fullWidth
    },
    haveYouMakeSomethingAChange
      ? {
          title: titles.urgency.stepsToMakeAChange,
          description:
            establishingUrgency?.onAScaleOf1To10?.haveYouMadeSomething?.whatStepsHaveYouTaken || '',
          config: GRID_CONFIG.fullWidth
        }
      : null,
    haveYouMakeSomethingAChange
      ? {
          title: titles.urgency.companiesYouApplied,
          description:
            establishingUrgency?.onAScaleOf1To10?.haveYouMadeSomething
              ?.whatCompaniesHaveYouAppliedTo || '',
          config: GRID_CONFIG.fullWidth
        }
      : null,
    {
      title: titles.urgency.professionalToMakeAChange,
      description: personalReasonForMakeAChange,
      config: GRID_CONFIG.fullWidth
    },
    {
      title: titles.urgency.companyOffersPosition,
      description: counteroffer,
      config: GRID_CONFIG.fullWidth
    },
    {
      title: titles.urgency.interviews,
      description:
        establishingUrgency?.daysForInterviews &&
        formatInterviews(establishingUrgency?.daysForInterviews),
      config: GRID_CONFIG.fullWidth
    }
  ];

  const finalUrgencyLabels = urgencyLabels.filter(item => item !== null);
  return finalUrgencyLabels;
};
