import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';

export const useSendoutsFormFeatureFlags = () => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  return {
    isFileCategorizationFeatureEnabled: checkIfFeatureFlagEnabled(
      FeatureFlags.CategoryFilesSendout
    ),
    isSendoutDebriefFeatureEnabled: checkIfFeatureFlagEnabled(
      FeatureFlags.CategoryFilesSendoutDebrief
    ),
    isDuplicateCheckingFeatureEnabled: checkIfFeatureFlagEnabled(FeatureFlags.SendoutDuplicates),
    isResendEmailFeatureEnabled: checkIfFeatureFlagEnabled(
      FeatureFlags.ResendEmailHiringAuthoritySendout
    )
  };
};
