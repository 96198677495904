// @flow
import { FormSchema } from 'UI/constants/entityTypes';
import { getInitials } from 'utils';

import { ANSWER, MINIMUM_WORD_LENGTH, PHRASES_TO_IGNORE } from './EditCandidateDataSheet.constants';

export const normalizeString = (str: string) => str.toLowerCase().trim();

export const cleanLine = line => line.replace(/(?:•|●|- |❖|o\t||·|and )/g, '').trim();

export const extractFromLine = (line: string) => {
  const parts = cleanLine(line).split(', ');
  return parts.filter(part => part.length >= MINIMUM_WORD_LENGTH);
};

export const filterOutIgnoredPhrases = (experience: string) => {
  const lowercaseExperience = normalizeString(experience);
  return !PHRASES_TO_IGNORE.some(phrase => lowercaseExperience.includes(phrase));
};

export const extractSkillsFromLines = (lines: string[]) => {
  const skills: string[] = [];
  lines.forEach(line => {
    const deClutteredLine = cleanLine(line);
    if (deClutteredLine.length >= MINIMUM_WORD_LENGTH) {
      skills.push(...extractFromLine(deClutteredLine));
    }
  });
  return skills;
};

export const extractSkillsFromExperienceField = (experience: string) => {
  if (!filterOutIgnoredPhrases(experience)) return [];
  const lines = experience.split('\n');
  return extractSkillsFromLines(lines);
};

export const formatDateTimeToDateString = dateTime => {
  if (!dateTime) return undefined;
  try {
    const [dateString] = dateTime.split(' ');

    return new Date(dateString).toISOString().slice(0, 10);
  } catch (error) {
    return undefined;
  }
};

export const createDataSheetFormData = (formData, blueSheet) => {
  if (!blueSheet) return null;

  const formatDateTimes = (dateTimes = []) => {
    return dateTimes.map(dateTime => {
      if (!dateTime) return undefined;

      const date = new Date(dateTime);

      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        0
      ).toISOString();
    });
  };

  const interviewDates = formatDateTimes(blueSheet.interview_dates);

  return {
    ...formData,
    establishingUrgency: {
      ...formData?.establishingUrgency,
      idealTimeToStart: blueSheet.timeToStart,
      howLongHaveYouBeenLooking: formatDateTimeToDateString(blueSheet.time_looking),
      daysForInterviews: {
        dateTime1: interviewDates[0] ? String(interviewDates[0]) : undefined,
        dateTime2: interviewDates[1] ? String(interviewDates[1]) : undefined,
        dateTime3: interviewDates[2] ? String(interviewDates[2]) : undefined
      },
      reasonForLooking: String(blueSheet.reason_leaving)
    },
    workPreferences: {
      ...formData?.workPreferences,
      salaryRange: {
        lowSalary: String(blueSheet.minimum_salary),
        idealSalary: String(blueSheet.no_brainer_salary),
        highSalary: String(blueSheet.good_salary)
      },
      workPreference: blueSheet.workTypeOption,
      relocation: {
        ...formData?.workPreferences?.relocation,
        relocationDestinations: blueSheet.relocations.map(relocation => {
          if (!('city' in relocation)) {
            return {
              id: relocation.id,
              is_state: relocation.is_state,
              title: relocation.title,
              state: relocation.state,
              slug: relocation.slug
            };
          }

          return {
            id: relocation.city.id,
            is_state: relocation.city.is_state,
            title: relocation.city.title,
            state: relocation.city.state.title,
            slug: relocation.city.state.slug
          };
        }),
        areYouWillingToRelocate: blueSheet.relocation ? ANSWER.POSITIVE : ANSWER.NEGATIVE
      }
    },
    professionalInsights: {
      ...formData?.professionalInsights,
      experienceSummary: blueSheet.experience,
      achievements: [
        { achievement: blueSheet.achievement_one },
        { achievement: blueSheet.achievement_two },
        { achievement: blueSheet.achievement_three }
      ],
      skills: blueSheet.experience && extractSkillsFromExperienceField(blueSheet.experience)
    },
    qualification: {
      ...formData?.qualification,
      candidateType: blueSheet.candidateType,
      candidateStatus: blueSheet.status,
      notes: blueSheet.notes ? String(blueSheet.notes) : undefined
    }
  };
};

export const createDataToSend = ({
  formData,
  candidate,
  user,
  schemaVersion,
  count,
  blueSheetId,
  dataSheetId: id
}) => {
  return {
    ...(id && { id }),
    candidate: {
      id: candidate.id,
      name: candidate.personalInformation.full_name,
      legacySheetId: blueSheetId
    },
    schema: {
      key: FormSchema.CandidateDataSheetFull,
      version: schemaVersion
    },
    progress: {
      current: count.answered,
      total: count.total
    },
    data: formData,
    trace: {
      user: {
        id: user.id,
        fullName: user.personalInformation.full_name,
        email: user.email,
        initials: getInitials(user.personalInformation.full_name)
      }
    }
  };
};
