// @flow
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { addContact } from 'actions/ringCentral';
import { TeamworkEntities } from 'hooks/accountability';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import LogRocket from 'logrocket';
import moment from 'moment';
import queryString from 'query-string';
import { filtersToParams } from 'selectors/app';
import API, { getCancelToken } from 'services/API';
import { getFilters, getPreferences, saveFilters, savePreferences } from 'services/FiltersStorage';
import strings from 'strings';
import type { DatePeriod } from 'types/app';
import { PHONE_TYPE_MAP } from 'UI/components/organisms/PhoneNumbersForm/utils';
import { DateFormats, RingCentralOriginActions } from 'UI/constants/defaults';
import {
  accountabilityFilters,
  entityTypesFilters,
  ListingFilters
} from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getDefaultDashboardPeriod, hasFeatureFlag, nestTernary } from 'UI/utils';
import { Renderers } from 'UI/utils/renderers';

type Filters = { [name: string]: any };
type ColumnBySection = string | { [section: number | string]: string };
type OrderByOptions = {
  column: ColumnBySection,
  direction: string
};
type ColumnPreferences = {
  columns: object[],
  columnOrder: number[],
  orderBy: string,
  direction: string
};
type RendererDefinition =
  | string
  | {
      id: string,
      config: any
    };

type DataTableDefinition = {
  key: string,
  // Sets a different key for saved column preferences
  columnKey?: string,
  apiVersion?: number | string,
  columnPreferences: ColumnPreferences,
  columnsDefinitions: object[],
  enableHashHistory?: true,
  endpoint: string,
  entityName: string,
  entityType: string,
  excludeUserFilters?: number[],
  formatResponse: (response: object) => any,
  httpHeaders?: object,
  includes?: string,
  initialUiState?: object,
  name: string,
  numberOfActionColumns?: number,
  onFetchedData?: (data: any) => any,
  orderByOptions: OrderByOptions,
  paramsExtender?: any => any,
  periodFilterColumn?: ColumnBySection,
  section?: number | string,
  sharePreferencesBetweenSections: false,
  shouldScrollOnNavigate?: boolean
};

type UserFilter = {
  id: number,
  title: string,
  permission?: number[]
};

/**
 * Adds virtual/calculated props to a set of results
 * Useful if the backend is sending data in a format that does not fit the datatable renderers
 * @param {object[]} items Array of items that came from the API
 * @param {object[]} virtualProps The virtual props definition. For example:
 * [
    {
      propName: 'myNewProp',
      attributes: ['id', 'full_name']
    }
   ]
   Would add to each item a new prop called myNewProp with { id: XX, full_name: 'XX' } as value
   If copyFrom is provided, then a copy of that property is created.
 * @return {object[]} items The same items plus the new virtual/calculated props
 */
export const addVirtualProps = (items, virtualProps) => {
  if (!items || !items.length) return [];

  const itemsWithVirtualColumns =
    virtualProps?.length > 0
      ? items.map(item => {
          const newProps = {};
          virtualProps.forEach(({ propName, attributes, copyFrom }) => {
            newProps[propName] = attributes
              ? pick(item, attributes)
              : nestTernary(copyFrom, item[copyFrom], undefined);
          });
          return {
            ...item,
            ...newProps
          };
        })
      : items;
  return itemsWithVirtualColumns;
};

/**
 * Merge columns definitions with saved column preferences
 * @param {object[]} columnsDefinitions The columns definitions of the datatable
 * @param {object[]} savedColumns The ordering options. It supports passing just one column or a map of columns by section
 * @param {context} object An object containing the context on which the column is being displayed. Used for displaying columns based on given conditions

 * @return {OrderByOptions} The default ordering options for a section
 */
const mergeColumnPreferences = (
  columnsDefinitions: object[],
  savedColumns: object[],
  context: object
) => {
  const mergedColumns = columnsDefinitions.map(column => {
    const savedColumn = savedColumns && savedColumns.find(({ name }) => name === column.name);

    const shouldDisplay =
      typeof column.options?.display === 'function'
        ? column.options?.display(context)
        : column.options?.display;

    return {
      ...column,
      options: {
        ...column.options,
        display: savedColumn ? savedColumn.options?.display : shouldDisplay
      }
    };
  });

  return mergedColumns;
};

/**
 * Merges columns order with saved preferences. 
 * If no preferences were saved, it returns an empty array that corresponds to the default order.
 * @param {object[]} columnsDefinitions The columns definitions of the datatable
 * @param {object[]} savedColumnOrder The saved order of columns
 * @param {number} numberOfActionColumns Defines the number of additional action columns that are not part of the column definition

 * @return {columnsOrder} An array of columns order merged with the saved preferences. 
 */
export const mergeColumnOrder = (
  columnsDefinitions: object[],
  savedColumnOrder: object[] = [],
  numberOfActionColumns = 1
) => {
  const numberOfDefinedColumns = columnsDefinitions?.length;
  const numberOfSavedColumns = savedColumnOrder?.length;
  const numberOfAllColumns = numberOfDefinedColumns + numberOfActionColumns;

  if (!numberOfDefinedColumns || !numberOfSavedColumns) return [];

  if (numberOfAllColumns === numberOfSavedColumns) return savedColumnOrder;

  return [];
};

/**
 * Get the default ordering options for a datatable
 * @param {number} section A section or tab number in which the datatable is in
 * @param {OrderByOptions} options The ordering options. It supports passing just one column or a map of columns by section
 * @return {OrderByOptions} The default ordering options for a section
 */

const getDefaultOrderBy = (section: number | string, options: OrderByOptions) => {
  const defaultDirection = 'desc';
  const orderByOptions = {
    column: '',
    direction: defaultDirection
  };

  if (typeof options === 'object') {
    orderByOptions.column =
      typeof options.column === 'object' ? options.column[section] : options.column;
    orderByOptions.direction = options.direction || defaultDirection;
  }

  return orderByOptions;
};

/**
 * Get column preferences based on column definitions and saved preferences
 * @param {string} key A unique key for this datatable
 * @param {number} section A section or tab number in which the datatable is in
 * @param {OrderByOptions} options The ordering options. It supports passing just one column or a map of columns by section
 * @param {object[]} columnsDefinitions The columns definitions of the datatable
 * @param {number} numberOfActionColumns Defines the number of additional action columns that are not part of the column definition

 * @return {ColumnPreferences} The default or merged column preferences for a datatable in a given section
 */

export const getColumnPreferences = (
  key: string,
  section: number | number,
  options: OrderByOptions,
  columnsDefinitions,
  numberOfActionColumns = 1,
  url?: string = ''
): ColumnPreferences => {
  const sectionKey = `${key}-${section}`;
  const defaultOrderBy = getDefaultOrderBy(section, options);

  const queryParams = queryString.parse(url);
  const { orderBy: orderByParam, direction: directionParam } = queryParams;

  const { columns, columnOrder, orderBy, direction } = getPreferences(sectionKey);

  return {
    columns: mergeColumnPreferences(columnsDefinitions, columns || [], {
      activeSection: section
    }),
    columnOrder: mergeColumnOrder(columnsDefinitions, columnOrder || [], numberOfActionColumns),
    orderBy: orderByParam || orderBy || defaultOrderBy.column,
    direction: directionParam || direction || defaultOrderBy.direction
  };
};

/**
 * This functions extracts specific keys from a Datatable row -which is a flat array, not an object-
 * @param {object[]} columns The columns definitions of the datatable
 * @param {string[]} keys The keys to extract from the data array
 * @param {object[]} rowData The data array for each row
 * @return {object[]} Column definitions with proper renderers
 */

export const extractObjectFromDataTable = (columns: any[], keys: string[], rowData: any[]) => {
  const extractedObject = {};
  keys.forEach(key => {
    const columnIndex = columns.findIndex(each => each.name === key);
    if (columnIndex > -1) extractedObject[key] = rowData[columnIndex];
  });
  return extractedObject;
};

/**
 * This function adds a contact to the RingCentral store slice in redux
 * @param {object[]} rowData The data array for each row
 * @param {object[]} columns The columns definitions of the datatable
 * @param {Function} dispatch Function to execute a reducer action
 * @param {string} role The rowData role
 * @param {string} origin The origin from which the action was performed. Actions column or Multiple Phones column
 */

type AddRingCentralToContactStoreParams = {
  rowData: any[],
  columns: object[],
  dispatch: () => void,
  role: string,
  origin?: string
};

export const addRingCentralContactToStore = ({
  rowData,
  columns,
  dispatch,
  role,
  origin = RingCentralOriginActions.Default
}: AddRingCentralToContactStoreParams) => {
  const item = extractObjectFromDataTable(columns, ['id'], rowData);
  const { id } = item;
  const phone = getSelectedPhone({ rowData, origin });

  if (!id || !phone || !role) return;

  dispatch(
    addContact({
      id,
      role,
      phone,
      createdAt: new Date()
    })
  );
};

/**
 * This function gets the phone number to call or text, checking both direct 'phone' property and 'phones' array within rowData objects.
 * @param {object[]} rowData The data array for each row
 * @param {string} origin The origin from which the action was performed. Actions column or Multiple Phones column
 */
const getSelectedPhone = ({ rowData, origin }: { rowData: any[], origin: string }) => {
  const targetObject = rowData.find(element => {
    if (typeof element === 'object' && element !== null) {
      if (origin === RingCentralOriginActions.Default) {
        return 'phone' in element;
      }

      if (origin === RingCentralOriginActions.MultiplePhonesDropdown) {
        return 'phone' in element && 'use' in element;
      }
    }
    return false;
  });

  // For those records that has only other phone
  if (targetObject) {
    if (targetObject.phone) {
      return targetObject.phone;
    }
    if (
      targetObject.phones &&
      Array.isArray(targetObject.phones) &&
      targetObject.phones.length > 0
    ) {
      const phoneEntry = targetObject.phones.find(phone => phone.phone);
      if (phoneEntry) {
        return phoneEntry.phone;
      }
    }
  }

  return null;
};

const findPhoneColumn = (colName: string, rowData: []) =>
  rowData.find(item => typeof item === 'object' && item !== null && colName in item);

export const getDefaultPhoneNumber = (rowData: []) => {
  if (!rowData) return null;
  const phones = findPhoneColumn('phones', rowData);

  if (phones) {
    return phones.phones?.find(phone => phone?.is_default === true);
  }

  const legacyPhone = findPhoneColumn('phone', rowData);

  if (legacyPhone?.phone) {
    const mobileId = PHONE_TYPE_MAP[2].id;
    return { type_id: mobileId };
  }
  return null;
};

/**
 * Get columns to render with proper renderers
 * @param {object[]} columns The columns definitions of the datatable

 * @return {object[]} Column definitions with proper renderers
 */

export const getColumnsToRender = (columns: object[], extraRenderers: any = {}) => {
  const renderers = {
    ...Renderers,
    ...extraRenderers
  };
  return columns
    .filter(each => each)
    .map(each => ({
      ...each,
      options: {
        ...each.options,
        customBodyRender: each.options.renderer && getRenderer(renderers, each.options.renderer)
      }
    }));
};

/**
 * Get renderer for a given definition
 * @param {any} renderers Renderers to look for in
 * @param {RendererDefinition} rendererDefinition The renderer definitions. 
 * It could be a string or an object containing and id and the related config to pass to a renderer builder

 * @return {function} The calculated renderer function
 */
const getRenderer = (renderers: any, rendererDefinition: RendererDefinition) => {
  if (!rendererDefinition) {
    return undefined;
  }
  if (typeof rendererDefinition === 'string') {
    return renderers[rendererDefinition];
  }
  if (typeof rendererDefinition === 'object') {
    const rendererBuilder = renderers[rendererDefinition.id];
    return rendererBuilder(rendererDefinition.config);
  }
  return rendererDefinition;
};

/**
 * Utility function to build a button renderer configuration
 * @deprecated Rather use buildButtonRendererDefinitionByObject it's the same 
 * but recieves params as object to make it easier to use
 * @param {string} class name to apply to the button
 * @param {string} text Button text. 
 * @param {(rowData => void)} onClick onClick event. The handler is passed the rowData. 

 * @return {function} A renderer definition for a button inside a data table
 */
export const buildButtonRendererDefinition = (
  className?: string,
  text: string,
  onClick: (rowData: any) => void,
  additionalPropsBuilder: (rowIndex: number) => object,
  color?: string
) => ({
  name: 'actions',
  label: 'Action',
  options: {
    filter: false,
    sort: false,
    renderer: {
      id: 'button',
      config: {
        className,
        onClick,
        text,
        additionalPropsBuilder,
        color
      }
    }
  }
});

/**
 * @typedef {Object} ButtonRendererDefinitionTypeDef
 * @property {string} [className]
 * @property {string} buttonName
 * @property {function} onClick
 * @property {function} additionalPropsBuilder
 * @property {string} [color]
 */

/**
 * Same function as above but receives object to make it simpler to map props
 * @param {ButtonRendererDefinitionTypeDef} options
 * @returns {Object}
 */
export const buildButtonRendererDefinitionByObject = ({
  className,
  buttonName,
  onClick,
  additionalPropsBuilder,
  color
}) => buildButtonRendererDefinition(className, buttonName, onClick, additionalPropsBuilder, color);

/**
 * Utility function to build a row actions (phone and email) renderer configuration
 * @return {function} A renderer definition for a row actions (call, text, email, etc) inside a data table
 */

export const buildRowActionsRendererDefinition = ({
  namespace = '',
  handleAction,
  customName,
  actions = [],
  columns
}) => {
  const iconWidth = 55;
  const maxActionsToShow = 4;
  const calculatedIconWidth =
    (actions.length > maxActionsToShow ? maxActionsToShow : actions.length) * iconWidth;

  const shouldShowActions = actions.length >= 1;

  return {
    name: customName || 'communication_actions',
    label: 'Actions',
    options: {
      display: shouldShowActions,
      rowHover: true,
      sort: false,
      renderer: {
        id: 'rowActions',
        config: { actions, namespace, handleAction, columns }
      },
      viewColumns: true,
      setCellHeaderProps: () => ({ className: 'dataTableStickyColumnHeader' }),
      setCellProps: () => ({
        className: 'dataTableStickyColumn',
        style: {
          width: actions?.length ? calculatedIconWidth : iconWidth
        }
      })
    }
  };
};

export const buildMultiplePhonesActionsRenderer = ({
  handleAction,
  customName,
  featureFlagToCheck,
  label,
  phonesKey = 'phones',
  phoneKey = 'phone',
  columns = [],
  entityType
}) => {
  if (
    !hasFeatureFlag(FeatureFlags.MultiplePhonesColumn) ||
    (featureFlagToCheck && !hasFeatureFlag(featureFlagToCheck))
  )
    return null;
  return {
    name: customName || 'multiple_phones_actions',
    label: label || 'Phones',
    options: {
      display: true,
      rowHover: true,
      sort: false,
      renderer: {
        id: 'phoneNumbers',
        config: { columns, handleAction, phonesKey, phoneKey, entityType }
      },
      viewColumns: true,
      setCellProps: () => ({
        className: 'phoneNumbersColumn'
      })
    }
  };
};

/**
 * Configuration for a new column to be inserted.
 * @typedef {Object} NewColumnConfig
 * @property {Object} column - The new column to add.
 * @property {string} afterColumn - The name of the column after which the new column will be inserted.
 */

/**
 * Adds multiple columns to an existing array of columns at specific positions.
 *
 * @param {Array<Object>} originalColumns - The original array of columns.
 * @param {Array<NewColumnConfig>} newColumnsConfig - Array of configurations for the new columns.
 * @returns {Array<Object>} A new array of columns with the new columns inserted.
 */
export const addMultipleColumns = (originalColumns, newColumnsConfig) => {
  const sortedNewColumns = newColumnsConfig.sort((a, b) => {
    const indexA = originalColumns.findIndex(col => col.name === a.afterColumn);
    const indexB = originalColumns.findIndex(col => col.name === b.afterColumn);
    return indexA - indexB;
  });

  return sortedNewColumns.reduce((columns, { column, afterColumn }) => {
    const index = columns.findIndex(col => col.name === afterColumn);
    if (index === -1) {
      return [...columns, column];
    }

    return [...columns.slice(0, index + 1), column, ...columns.slice(index + 1)];
  }, originalColumns);
};

/**
 * Method to get the current useFilter with the customs userFilters
 * This will return the savedFilter if exist in the currents CustomFilters
 * @returns {UserFilter} returns the saved filter if exist
 */
export const getCurrentCustomUserFilter = (
  customScopeFilters: UserFilter[],
  savedFilter: UserFilter
) => {
  const currentUserFilter = customScopeFilters.find(
    each => each?.id === savedFilter?.id && each?.title === savedFilter?.title
  );
  return currentUserFilter || customScopeFilters[customScopeFilters.length - 1];
};

const formatResponseDefault = (response: any) => response;

/**
 * Scroll to top
 */

const scrollToTop = () => {
  const contentWrapper = document.getElementById('content_wrapper');
  if (!contentWrapper) return;
  contentWrapper.scrollTo({ top: 0, behavior: 'instant' });
};

/**
 * A function to be passed as an argument.
 * @callback formatResponse
 * @param {object} response - The response from the API
 * @returns {object} The formatted response
 */

/**
 * @param {Object} data The useDatatable hook parameters
 * @param {Object} data.httpHeaders  The headers to be sent with the request
 * @param {formatResponse} data.formatResponse The function to format the response
 */
const useDatatable = ({
  apiInstance,
  apiVersion = 1,
  columnKey,
  columnsDefinitions,
  customScopeFilters,
  dateParamName = 'date',
  enableFetchData = true,
  enableHashHistory = false,
  endpoint = '',
  entityName,
  entityType,
  excludeUserFilters = null,
  formatResponse = formatResponseDefault,
  httpHeaders,
  includes = '',
  initialPreferences,
  initialScopeFilter = ListingFilters.Mine,
  initialSection = 0,
  initialUiState = null,
  key,
  numberOfActionColumns = 0,
  onFetchedData,
  orderByOptions,
  paramsExtender,
  periodDefaultValue,
  periodFilterColumn,
  periodFormat = DateFormats.QueryFormat,
  sendDateWithTimezone = false,
  sharePreferencesBetweenSections = false,
  shouldScrollOnNavigate = false,
  shouldUseSavedFilters = true,
  shouldUseSavedPagination = true,
  virtualProps
}: DataTableDefinition): string => {
  const history = useHistory();
  const api = apiInstance || API;
  const cancelToken = useRef();

  const mainOptions = TeamworkEntities.includes(entityType)
    ? accountabilityFilters(entityName)
    : entityTypesFilters(entityName, excludeUserFilters);

  const [activeSection, setActiveSection] = useState(initialSection);
  const columnPreferencesKey = `${columnKey || key}-${
    sharePreferencesBetweenSections ? initialSection : activeSection
  }`;

  const [uiState, setUiState] = useState(() => {
    const { params: savedParams, userFilter: savedUserFilter } = getFilters(key);
    const defaultUserFilter =
      savedUserFilter || mainOptions.find(option => option.id === initialScopeFilter);
    const perPage = initialUiState?.perPage ?? savedParams?.perPage ?? 10;

    return {
      showWholeSkeleton: true,
      isLoading: false,
      isSideMenuOpen: false,
      keyword: savedParams?.keyword || '',
      filtersQuery: '',
      filtersWithoutPeriod: null,
      page: shouldUseSavedPagination && !isNil(savedParams?.page) ? savedParams.page - 1 : 0,
      perPage,
      userFilter: customScopeFilters
        ? getCurrentCustomUserFilter(customScopeFilters, savedUserFilter)
        : defaultUserFilter
    };
  });
  const { keyword, page, perPage, userFilter } = uiState;

  const defaultPeriod =
    periodDefaultValue === undefined ? getDefaultDashboardPeriod() : periodDefaultValue;
  const [selectedPeriod, setSelectedPeriod] = useState<DatePeriod>(
    periodFilterColumn && defaultPeriod ? defaultPeriod : null
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const periodColumn =
    typeof periodFilterColumn === 'object' ? periodFilterColumn[activeSection] : periodFilterColumn;

  const [columnPreferences, setColumnPreferences] = useState(initialPreferences);
  const { columns, columnOrder, orderBy, direction } = columnPreferences;

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState(shouldUseSavedFilters ? getFilters(key).filters : {});

  const [queryParams, setQueryParams] = useState({
    params: '',
    type: entityType
  });

  const handleSectionChange = (event, newValue = 0) => {
    const canChangeTab = !uiState.isLoading && activeSection !== newValue;
    if (!canChangeTab) return;

    setActiveSection(newValue);
    enableHashHistory && history.replace(`${history.location.pathname}#${newValue}`);

    const sectionPreferences = getColumnPreferences(
      columnKey || key,
      sharePreferencesBetweenSections ? initialSection : newValue,
      orderByOptions,
      columnsDefinitions,
      numberOfActionColumns
    );

    setColumnPreferences(sectionPreferences);

    setUiState(prevState => ({
      ...prevState,
      showWholeSkeleton: true,
      page: 0
    }));
  };

  const handleFiltersApply = (_: string, newFilters: Filters) => {
    setFilters(newFilters);
    setUiState(prevState => ({ ...prevState, isSideMenuOpen: false, page: 0 }));

    if (key) {
      const { params: lastParams, userFilter: lastUserFilter } = getFilters(key);
      saveFilters(key, { filters: newFilters, params: lastParams, userFilter: lastUserFilter });
    }
  };

  const handleFiltersChange = useCallback((newFilters: Filters) => {
    setFilters(newFilters);
    setUiState(prevState => ({
      ...prevState,
      page: 0
    }));
  }, []);

  const handleOneFilterChange = (name?: string, value: any) =>
    setUiState(prevState => ({
      ...prevState,
      [name]: value,
      page: 0
    }));

  const handleKeywordChange = newKeyword =>
    setUiState(prevState => ({
      ...prevState,
      keyword: newKeyword,
      page: 0
    }));

  const handleColumnSortChange = ({ orderBy: orderByParam, direction: directionParam }) => {
    setColumnPreferences(prevState => ({
      ...prevState,
      orderBy: orderByParam,
      direction: directionParam
    }));

    setUiState(prevState => ({
      ...prevState,
      page: 0
    }));
    key &&
      savePreferences(columnPreferencesKey, { orderBy: orderByParam, direction: directionParam });
  };

  const handlePerPageChange = newPerPage =>
    setUiState(prevState => ({
      ...prevState,
      perPage: newPerPage,
      page: 0
    }));

  const handlePageChange = newPage => {
    setUiState(prevState => ({
      ...prevState,
      page: newPage
    }));
    shouldScrollOnNavigate && scrollToTop();
  };

  const handleColumnDisplayChange = ({ column, display }) => {
    const newColumns = columns.map(each =>
      each.name !== column
        ? each
        : {
            ...each,
            options: {
              ...each.options,
              display
            }
          }
    );
    setColumnPreferences(prevState => ({ ...prevState, columns: newColumns }));
    key && savePreferences(columnPreferencesKey, { columns: newColumns });
  };
  const handleColumnOrderChange = (newColumnOrder: array) => {
    setColumnPreferences(prevState => ({ ...prevState, columnOrder: newColumnOrder }));
    key && savePreferences(columnPreferencesKey, { columnOrder: newColumnOrder });
  };

  const handleFiltersToggle = () =>
    setUiState(prevState => ({ ...prevState, isSideMenuOpen: !prevState.isSideMenuOpen }));

  const handlePeriodChange = useCallback((period: DatePeriod) => {
    setUiState(prevState => ({
      ...prevState,
      page: 0
    }));
    setSelectedPeriod(period);
  }, []);

  const handleDateChange = useCallback(date => {
    setUiState(prevState => ({
      ...prevState,
      page: 0
    }));
    setSelectedDate(date);
  }, []);

  const processParameters = useCallback(() => {
    const additionalParams =
      paramsExtender && paramsExtender({ activeSection, userFilter }, filters);
    const onlyFiltersParams = filtersToParams(filters);

    return {
      keyword,
      userFilter: userFilter?.id,
      ...onlyFiltersParams,
      ...additionalParams
    };
  }, [filters, keyword, userFilter, activeSection, paramsExtender]);

  const getData = useCallback(async () => {
    try {
      cancelToken.current && cancelToken.current.cancel(strings.shared.requests.cancelTokenMessage);
      cancelToken.current = getCancelToken();

      const allFilterParams = processParameters();
      const dateParam = selectedDate ? { [dateParamName]: selectedDate } : null;
      const dateFormat = sendDateWithTimezone ? DateFormats.DateTimeWithTimeZone : periodFormat;
      const periodParams =
        periodColumn && selectedPeriod
          ? {
              [`start_${periodColumn}`]: moment(selectedPeriod.startDate).format(dateFormat),
              [`end_${periodColumn}`]: moment(selectedPeriod.endDate).format(dateFormat)
            }
          : null;

      const params = {
        orderBy,
        direction,
        page: page + 1,
        perPage,
        size: perPage,
        offset: (page + 1) * perPage,
        ...periodParams,
        ...allFilterParams,
        ...dateParam
      };
      const qParams = queryString.stringify(params, { arrayFormat: 'comma' });

      setQueryParams(prev => ({ ...prev, params: qParams }));

      key && saveFilters(key, { filters, params, userFilter });

      setUiState(prevState => ({
        ...prevState,
        isLoading: true,
        filtersQuery: queryString.stringify(allFilterParams, { arrayFormat: 'comma' }),
        filtersWithoutPeriod: allFilterParams
      }));
      // if headers are defined, they will be added to the request
      const finalEndpoint = endpoint.includes('?')
        ? `${endpoint}&${qParams}`
        : `${endpoint}?${qParams}`;

      const response = await api.get(`${finalEndpoint}${includes && `&includes=${includes}`}`, {
        apiVersion,
        cancelToken: cancelToken.current.token,
        ...(httpHeaders && { headers: httpHeaders })
      });
      setUiState(prevState => ({
        ...prevState,
        isLoading: false,
        showWholeSkeleton: false
      }));

      const formattedResponse = formatResponse(response);
      const items = formattedResponse.data.data || formattedResponse.data;
      const itemsWithVirtualColumns = addVirtualProps(items, virtualProps);

      onFetchedData && onFetchedData(formattedResponse);
      setData(itemsWithVirtualColumns);
      setCount(Number(formattedResponse.data.total || formattedResponse.total));
    } catch (error) {
      LogRocket.captureException(error);
      error.constructor.name !== 'Cancel' &&
        setUiState(prevState => ({
          ...prevState,
          isLoading: false,
          showWholeSkeleton: false
        }));
    }
  }, [
    apiVersion,
    direction,
    endpoint,
    filters,
    includes,
    key,
    orderBy,
    page,
    periodColumn,
    perPage,
    processParameters,
    selectedPeriod,
    userFilter,
    virtualProps,
    formatResponse,
    httpHeaders,
    onFetchedData,
    sendDateWithTimezone,
    api,
    periodFormat,
    selectedDate,
    dateParamName
  ]);

  useEffect(() => {
    enableFetchData && getData();
  }, [enableFetchData, getData]);

  return {
    activeSection,
    columnOrder,
    columnPreferences,
    columns,
    count,
    data,
    filters,
    getData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleDateChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handleOneFilterChange,
    handlePageChange,
    handlePeriodChange,
    handlePerPageChange,
    handleSectionChange,
    listState: uiState,
    mainOptions,
    queryParams,
    selectedPeriod,
    selectedDate
  };
};

export default useDatatable;
