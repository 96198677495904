import React, { useContext, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { confirm, showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { Loader } from 'features/command-center/components/shared/Widget/Loader';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { UserProviderContext } from 'providers/UserProvider';
import API from 'services/API';
import { userHasRole } from 'services/Authorization';
import strings from 'strings';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { UIStatus } from 'UI/constants/status';
import { nestTernary } from 'UI/utils';

import { DRAWER_CONFIG_BY_ENTITY } from './ActivityDrawer.constants';
import { ActivityForm } from './ActivityForm';

export const ActivityDrawer = ({ activity, entity, open, onClose, onSubmitSucceed }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [activityData, setActivityData] = useState({
    isCreatedBySystem: false,
    userInitials: null,
    userEmail: null,
    createdAt: null,
    contextualData: null,
    entity,
    userId: null
  });

  const dispatch = useDispatch();
  const [user] = useContext(UserProviderContext);
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const areOutcomeFieldsEnabled = checkIfFeatureFlagEnabled(
    FeatureFlags.ActivityOutcomeFieldsInForm
  );

  const isExistingActivity = !!activity.id;
  const actionVerb = isExistingActivity ? 'updated' : 'created';

  const isOperationsUser = userHasRole(Roles.Operations, user);
  const isOwner = user.id === activityData.userId;

  const canSubmitForm = areOutcomeFieldsEnabled
    ? nestTernary(isExistingActivity, isOwner || isOperationsUser, true)
    : !activityData?.isCreatedBySystem;

  const existingActivityEndpoint = isExistingActivity
    ? `${DRAWER_CONFIG_BY_ENTITY[entity].endpointPath}/${activity.entityId}/${Endpoints.Activities}/${activity.id}`
    : null;

  const { state } = useFetchWithStatusV2({
    endpoint: existingActivityEndpoint,
    initialStatus: UIStatus.Default
  });

  const form = useForm();
  const { handleSubmit, reset } = form;

  useEffect(() => {
    if (!isExistingActivity) return;

    if (state.results) {
      setActivityData(prev => ({
        ...prev,
        id: state.results?.id,
        isCreatedBySystem: state.results?.created_by_system,
        createdAt: state.results?.created_at,
        userEmail: state.results?.user_email,
        userInitials: state.results?.user_initials,
        contextualData: state.results?.contextualData,
        userId: state.results?.user_id
      }));

      const defaultValues = {
        activityType: { title: state.results?.title, id: state.results?.activity_log_type_id },
        activityBody: state.results?.created_by_system
          ? state.results?.contextualData?.mailBody ??
            state.results?.contextualData?.bulkEmailPreview ??
            state.results?.body ??
            ''
          : state.results?.body,
        activityOutcome: {
          typeId: state.results?.activityOutcomeTypeId ?? null,
          typeTitle: state.results?.activityOutcomeTypeTitle ?? null
        },
        activityOutcomeComments: state.results?.activityOutcomeComments ?? null
      };
      reset(defaultValues);
    }
  }, [isExistingActivity, reset, state.results]);

  const isLoading = state.status === UIStatus.Loading;

  const onSubmit = async formData => {
    if (!isExistingActivity && !isOperationsUser) {
      dispatch(
        confirm({
          severity: 'warning',
          title: strings.inventoryProfiles.sections.tabs.activityNote.activityActionText,
          message: strings.inventoryProfiles.sections.tabs.activityNote.drawer.alert,
          cancelButtonText: strings.shared.ui.cancel,
          confirmButtonText:
            strings.inventoryProfiles.sections.tabs.activityNote.drawer.confirmButton,
          onConfirm: async ok => {
            if (!ok) return;
            saveForm(formData);
          }
        })
      );
    } else {
      saveForm(formData);
    }
  };

  const saveForm = async formData => {
    try {
      const request = isExistingActivity ? API.put : API.post;
      const finalEndpoint = isExistingActivity
        ? existingActivityEndpoint
        : `${DRAWER_CONFIG_BY_ENTITY[entity].endpointPath}/${activity.entityId}/${Endpoints.Activities}`;

      const outcome = {
        comments: formData.activityOutcomeComments ?? null,
        activityOutcomeTypeId: formData.activityOutcome?.typeId ?? null
      };

      const dataToSend = {
        body: formData.activityBody,
        activity_log_type_id: formData.activityType?.id,
        outcome
      };

      setIsSaving(true);
      const response = await request(finalEndpoint, dataToSend);

      if (response.status === HTTPStatusCodes.Ok || response.status === HTTPStatusCodes.Created) {
        dispatch(
          showAlert({
            severity: 'success',
            title: 'Activities',
            body: `The Activity was ${actionVerb} succesfully`
          })
        );
        onClose && onClose();
        onSubmitSucceed && onSubmitSucceed();
      }
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Activities',
          autoHideDuration: 5000,
          body: `Something went wrong!`
        })
      );
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <DrawerContentLayout
      title="Activity"
      drawerProps={{ open }}
      onClose={onClose}
      uiState={{ isSaving, isFormLoading: isLoading }}
      primaryProps={{
        type: 'button',
        onClick: handleSubmit(onSubmit),
        disabled: isSaving || isLoading || !canSubmitForm
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <FormContext {...form}>
          <ActivityForm activityData={activityData} />
        </FormContext>
      )}
    </DrawerContentLayout>
  );
};
