// @flow
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DrawerProps } from '@material-ui/core/Drawer';
import { useContactConversion } from 'hooks/useContactConversion';
import usePaginationByItem from 'hooks/usePaginationByItem';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { EntityType } from 'UI/constants/entityTypes';
import FPCandidateProfile from 'UI/pages/CandidateProfile';
import FPCompanyProfile from 'UI/pages/CompanyProfile';
import ContactProfileContent from 'UI/pages/ContactProfile/ContactProfileContent';
import HiringAuthorityProfile from 'UI/pages/HiringAuthorityProfile';
import FPJobOrderProfile from 'UI/pages/JobOrderProfile';
import { getEntityType } from 'UI/utils/inventory';

import { PROFILE_ENTITIES_PROPS } from '../commons';

import { useStyles } from './styles';
import { handleAssignEntityRole } from './utils';

const PRIMARY_BUTTON_TEXT = strings.inventoryProfiles.quickView.initialText;
const GRID_SIZE = 2;

const ProfileWrapper = ({
  conversionStatus,
  profileProps: { enableUrlNavigation, layoutClassName },
  profileModeProps,
  onEditionCompleted
}) => {
  return conversionStatus?.candidate ? (
    <FPCandidateProfile
      id={conversionStatus?.candidate?.id}
      enableUrlNavigation={enableUrlNavigation}
      layoutClassName={layoutClassName}
      onEditionCompleted={onEditionCompleted}
      {...profileModeProps}
    />
  ) : (
    <HiringAuthorityProfile
      id={conversionStatus?.hiringAuthority?.id}
      enableUrlNavigation={enableUrlNavigation}
      layoutClassName={layoutClassName}
      onEditionCompleted={onEditionCompleted}
      {...profileModeProps}
    />
  );
};

const ConvertedContact = ({
  profileProps: { nameId, enableUrlNavigation, layoutClassName },
  profileModeProps,
  onEditionCompleted
}) => {
  const { entityConversionState, isSuccess, shouldRenderProfileContent, enableConvertedContacts } =
    useContactConversion({
      nameId
    });

  const conversionStatus = entityConversionState.results;

  const profileContent = (
    <ContactProfileContent
      id={nameId}
      layoutClassName={layoutClassName}
      enableUrlNavigation={enableUrlNavigation}
      onEditionCompleted={onEditionCompleted}
    />
  );

  const componentRender = shouldRenderProfileContent ? (
    profileContent
  ) : (
    <ProfileWrapper
      conversionStatus={conversionStatus}
      profileProps={{ nameId, enableUrlNavigation, layoutClassName }}
      profileModeProps={profileModeProps}
      onEditionCompleted={onEditionCompleted}
    />
  );

  return (
    <>
      <When condition={enableConvertedContacts}>
        <When condition={isSuccess}>{componentRender}</When>
        <When condition={!isSuccess}>
          <CircularProgress />
        </When>
      </When>
      <When condition={!enableConvertedContacts}>{profileContent}</When>
    </>
  );
};

type FPQuickViewProps = {
  entityType: string,
  id: number,
  drawerProps: DrawerProps,
  NavigationControlsProps: Object,
  quickViewEntityRoleProps: Object,
  onEditionCompleted: () => Object,
  onVerificationCompleted: () => Object
};

const FPQuickView = ({
  entityType,
  id,
  drawerProps,
  itemsToNavigate,
  onClose,
  quickViewEntityRoleProps = {},
  NavigationControlsProps: { enableNavigationControls },
  onEditionCompleted,
  onVerificationCompleted = undefined
}: FPQuickViewProps) => {
  const classes = useStyles();

  const [relationId, setRelationId] = useState(null);

  const { goToNext, goToPrevious, selectedProfileId, hasNextItem, hasPreviousItem, currentIndex } =
    usePaginationByItem(itemsToNavigate, id);

  const profileEntity = getEntityType(entityType);
  const [currentEntity, setCurrentEntity] = useState(profileEntity?.id);

  const sharedProps = {
    id: relationId || selectedProfileId,
    layoutClassName: classes.layout,
    enableUrlNavigation: false,
    onEditionCompleted,
    onVerificationCompleted
  };

  const handleQuickViewSwitch = (updatedId, mode) => {
    setCurrentEntity(mode);
    setRelationId(updatedId);
  };

  const profileModeProps = {
    profileModeProps: {
      toggleProfileQuickViewProps: {
        isQuickViewMode: true,
        onQuickViewOptionClick: handleQuickViewSwitch,
        mode: currentEntity
      }
    }
  };

  const profiles = {
    [EntityType.Candidate]: <FPCandidateProfile {...sharedProps} {...profileModeProps} />,
    [EntityType.Company]: (
      <FPCompanyProfile
        profileId={selectedProfileId}
        layoutClassName={classes.layout}
        enableUrlNavigation={false}
        onEditionCompleted={sharedProps.onEditionCompleted}
      />
    ),
    [EntityType.Contact]: (
      <ConvertedContact
        profileProps={{
          nameId: sharedProps.id,
          layoutClassName: sharedProps.layoutClassName,
          enableUrlNavigation: sharedProps.enableUrlNavigation
        }}
        profileModeProps={profileModeProps}
        onEditionCompleted={sharedProps.onEditionCompleted}
      />
    ),
    [EntityType.HiringAuthority]: <HiringAuthorityProfile {...sharedProps} {...profileModeProps} />,
    [EntityType.Joborder]: <FPJobOrderProfile {...sharedProps} />
  };

  const resetNavigation = () => {
    setCurrentEntity(profileEntity?.id);
    setRelationId(null);
  };

  const handleGoBack = () => {
    resetNavigation();
    goToPrevious();

    if (quickViewEntityRoleProps) {
      handleAssignEntityRole({
        item: itemsToNavigate[currentIndex - 1],
        quickViewEntityRoleProps,
        profileEntity,
        onSetCurrentEntity: setCurrentEntity
      });
    }
  };

  const handleGoNext = () => {
    resetNavigation();
    goToNext();

    if (quickViewEntityRoleProps) {
      handleAssignEntityRole({
        item: itemsToNavigate[currentIndex + 1],
        quickViewEntityRoleProps,
        profileEntity,
        onSetCurrentEntity: setCurrentEntity
      });
    }
  };

  const shouldEnableNavigationControls = itemsToNavigate?.length && enableNavigationControls;

  const { profileRoute } = PROFILE_ENTITIES_PROPS[entityType];

  const sharedPrimaryButtonProps = {
    text: PRIMARY_BUTTON_TEXT,
    component: RouterLink,
    target: '_blank',
    to: profileRoute.replace(':id', selectedProfileId)
  };

  return (
    <DrawerContentLayout
      key={selectedProfileId}
      drawerProps={{ classes: { paper: classes.drawerPanel }, ...drawerProps }}
      contentProps={{ className: classes.drawerContainer }}
      onClose={onClose}
      variant="borderless"
      initialText={PRIMARY_BUTTON_TEXT}
      primaryProps={sharedPrimaryButtonProps}
      FPButtonBuilderProps={{
        GridContainerProps: { classes: { container: classes.gridContainer }, spacing: GRID_SIZE }
      }}
      NavigationControlsProps={{
        enableNavigationControls: shouldEnableNavigationControls,
        onGoBack: () => handleGoBack(),
        onGoNext: () => handleGoNext(),
        isGoBackEnabled: hasPreviousItem,
        isGoNextEnabled: hasNextItem
      }}
    >
      {id && selectedProfileId && profiles[currentEntity]}
    </DrawerContentLayout>
  );
};

FPQuickView.defaultProps = {
  NavigationControlsProps: { enableNavigationControls: true },
  quickViewEntityRoleProps: null
};

export default FPQuickView;
