import moment from 'moment';
import { DateFormats } from 'UI/constants/defaults';
import { localTimeFormatter } from 'UI/utils';

export const formatDate = (dateTime, todayDateTime = moment().toISOString()) => {
  const longDate = moment(dateTime);
  const today = moment(todayDateTime);

  if (longDate.isSame(today, 'day')) {
    return localTimeFormatter(dateTime, DateFormats.SimpleTime);
  }

  const yesterday = today.subtract(1, 'day');
  if (longDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  }

  const startOfWeek = longDate.startOf('isoWeek');
  const isThisWeek = startOfWeek.isSame(today, 'isoWeek');

  if (isThisWeek) {
    return localTimeFormatter(dateTime, 'dddd');
  }

  return localTimeFormatter(dateTime, DateFormats.SimpleDate);
};
