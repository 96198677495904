import List from '@material-ui/core/List';
import styled from '@material-ui/core/styles/styled';

export const SearchResultsContainer = styled(List)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  '& ul': {
    padding: 0
  },
  '& .MuiListSubheader-root': {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    lineHeight: 'inherit'
  }
}));
