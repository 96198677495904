// @flow
import { EntityRoutes } from 'routes/constants';
import { defaultProfileColumnsConfig } from 'UI/constants/defaults';

export const companyProfileJobOrderColumns = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'job_order_id',
          textKey: 'title'
        }
      }
    }
  },
  {
    name: 'whiteSheet.jobOrderType',
    label: 'Type',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-joborder-type'
        }
      }
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-joborder-status'
        }
      }
    }
  },
  {
    name: 'position',
    label: 'Functional Title',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'custom',
        config: {
          customValue: 'title'
        }
      }
    }
  },
  {
    name: 'specialty.industry.title',
    label: 'Industry',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'default'
    }
  },
  {
    name: 'specialty.title',
    label: 'Specialty',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'default'
    }
  },
  {
    name: 'whiteSheet',
    label: 'Compensation Range',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'compensationRange'
    }
  },
  {
    name: 'hiringAuthorities',
    label: 'Hiring Authority',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'id',
          textKey: 'full_name'
        }
      }
    }
  },
  {
    name: 'address',
    label: 'Location',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'location'
    }
  },
  {
    name: 'created_at',
    label: 'Creation Date',
    options: {
      sort: true,
      renderer: 'date'
    }
  },
  {
    name: 'whiteSheet.workTypeOption.title',
    label: 'Work Preference',
    options: {
      sort: true,
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'last_activity_date',
    label: 'Last Activity on',
    options: {
      sort: true,
      display: false,
      renderer: 'date'
    }
  },
  {
    name: 'lastActivityLog.recruiter',
    label: 'Last Activity by',
    options: {
      sort: false,
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'lastActivityLog.title',
    label: 'Last Activity',
    options: {
      sort: false,
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'vRecruiter.user_name',
    label: 'Recruiter',
    options: {
      sort: true,
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'vRecruiter.coach_name',
    label: 'Team',
    options: {
      sort: true,
      display: false,
      renderer: 'default'
    }
  }
];
