// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { restoreSearchProject } from 'services/searchProjects';
import strings from 'strings';
import DetailedConfirmationDialog from 'UI/components/organisms/DetailedConfirmationDialog';
import { ARCHIVE_AVAILABLE_DAYS } from 'UI/constants/defaults';
import { dateFormatter } from 'UI/utils';

const { restoreSearchProject: restoreSearchProjectDialogCopies } = strings.searchProjects.dialogs;

const RestoreSearchProjectDialog = ({
  fetchingDialog,
  onCloseDialog,
  onFetchingDialog,
  onSuccess,
  searchProject
}) => {
  const dispatch = useDispatch();
  const handleRestoreSearchProject = async () => {
    onFetchingDialog(true);
    const { success, alert } = await restoreSearchProject({
      searchProjectId: searchProject?.id
    });
    dispatch(showAlert(alert));
    onFetchingDialog(false);
    if (success) {
      onSuccess();
      onCloseDialog();
    }
  };
  const formattedMessage = strings.formatString(restoreSearchProjectDialogCopies.main.message, {
    availabilityDays: ARCHIVE_AVAILABLE_DAYS
  });
  const details = [
    { title: restoreSearchProjectDialogCopies.detailsTitles.name, detail: searchProject?.name },
    {
      title: restoreSearchProjectDialogCopies.detailsTitles.archivedDate,
      detail: dateFormatter(searchProject?.archivedAt)
    }
  ];

  return (
    <DetailedConfirmationDialog
      decisionDialogProps={{
        ...restoreSearchProjectDialogCopies.main,
        fetching: fetchingDialog,
        message: formattedMessage,
        onConfirm: handleRestoreSearchProject,
        onClose: onCloseDialog
      }}
      details={details}
    />
  );
};

export default RestoreSearchProjectDialog;
