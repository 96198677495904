import React from 'react';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import strings from 'strings';
import TabsView from 'UI/components/templates/TabsView';
import { FeatureFlags } from 'UI/constants/featureFlags';

import { FeedbackNotesList } from './FeedbackNotesList';
import { SupportActivityList } from './SupportActivityList';

const TABS_CONFIG = {
  feedbackNotes: {
    id: 'feedbackNotes',
    title: strings.commandCenter.feedbackNotes.drawer.tabs.feedbackNotes
  },
  supportActivity: {
    id: 'supportActivity',
    title: strings.commandCenter.feedbackNotes.drawer.tabs.supportActivity
  }
};

export const FeedbackLog = ({ employeeNumber, onNewItemClick, onEditNoteClick }) => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const tabs = [
    {
      id: TABS_CONFIG.feedbackNotes.id,
      label: TABS_CONFIG.feedbackNotes.title,
      view: (
        <FeedbackNotesList
          employeeNumber={employeeNumber}
          onNewItemClick={onNewItemClick}
          onEditNoteClick={onEditNoteClick}
        />
      )
    },
    {
      id: TABS_CONFIG.supportActivity.id,
      label: TABS_CONFIG.supportActivity.title,
      view: <SupportActivityList employeeNumber={employeeNumber} />,
      visible: checkIfFeatureFlagEnabled(FeatureFlags.SupportActivity)
    }
  ];

  return (
    <TabsView
      initialTab={TABS_CONFIG.feedbackNotes.id}
      panelHeight="unset"
      tabs={tabs ?? []}
      useIndexes={false}
    />
  );
};
