import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  iconButton: {
    margin: theme.spacing(0.5, 1, 0, 0)
  },
  backTitle: {
    marginBottom: theme.spacing(1)
  }
}));
