// @flow
import React, { useContext } from 'react';
import { UserProviderContext } from 'providers/UserProvider';
import { canUserOrStaffEditEntity } from 'services/Authorization';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import { componentDimensions } from 'UI/constants/dimensions';
import { EntityType, TabKeys } from 'UI/constants/entityTypes';
import { SvgNoSendouts } from 'UI/res/icons/milano';

import ProfileTableLayout from '../ProfileTableLayout';

const {
  inventoryProfiles: {
    emptyStates: { sendouts: sendoutsEmptyStrings }
  }
} = strings;

const { emptyState } = componentDimensions;

type SendoutsTabProps = {
  profile: Array<Object>,
  endpoint: string,
  onSendoutClick: () => any,
  onNewItemClick: () => any,
  profileModule: 'joborder' | 'candidate' | 'company',
  shouldRefresh: boolean,
  DataTableProps: ProfileDataTableProps,
  initialColumns: Array<Object>,
  extraRenderers: Object,
  paramsBuilder: Array<Object>
};

const SendoutsTab = ({
  profile,
  onNewItemClick,
  onSendoutClick,
  profileModule,
  shouldRefresh,
  DataTableProps,
  initialColumns,
  extraRenderers,
  paramsBuilder,
  tablePreferencesKey,
  ...rest
}: SendoutsTabProps) => {
  const [currentUser] = useContext(UserProviderContext);
  const canUseSendout = canUserOrStaffEditEntity(currentUser, profile);
  const isJobOrderMode = profileModule === EntityType.Joborder;

  const actionButtonProps = { disabled: !canUseSendout || !profile?.id };
  const finalActions = [
    {
      name: 'view',
      callback: onSendoutClick
    }
  ];

  return (
    <ProfileTableLayout
      actionButtonProps={actionButtonProps}
      actionText="Create Sendout"
      customEmptyState={<SvgNoSendouts size={emptyState.width} />}
      DataTableProps={DataTableProps}
      extraRenderers={extraRenderers}
      finalActions={finalActions}
      hasProfileLoaded={profile?.id}
      initialColumns={initialColumns}
      onNewItemClick={isJobOrderMode ? onNewItemClick : null}
      profileModule={profileModule}
      shouldRefresh={shouldRefresh}
      tabKey={TabKeys.Sendouts}
      paramsBuilder={paramsBuilder}
      orderByOptions={{ column: 'sent_on', direction: 'desc' }}
      tablePreferencesKey={tablePreferencesKey}
      {...sendoutsEmptyStrings}
      {...rest}
    />
  );
};

export default SendoutsTab;
