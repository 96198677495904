import { NOT_REGISTERED_CALL_ENTITY } from 'features/command-center/components/activity/ActivityTable/ActivityTable.constants';
import {
  CandidateEntity,
  CompanyEntity,
  EntityType,
  HiringAuthorityEntity
} from 'UI/constants/entityTypes';

export const DRAWER_CONFIG_BY_ENTITY = {
  [EntityType.Candidate]: {
    title: CandidateEntity.title,
    endpointPath: 'candidates'
  },
  [EntityType.Company]: {
    title: CompanyEntity.title,
    endpointPath: 'companies'
  },
  [EntityType.HiringAuthority]: {
    title: HiringAuthorityEntity.title,
    endpointPath: 'hiring-authorities'
  },
  [EntityType.Contact]: {
    title: 'Prospects',
    endpointPath: 'names'
  },
  [NOT_REGISTERED_CALL_ENTITY]: {
    title: 'Not Registered Calls',
    endpointPath: ''
  },
  [EntityType.Joborder]: {
    title: 'Job Order',
    endpointPath: 'job-orders'
  }
};
