import React, { useState } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Endpoints } from 'UI/constants/endpoints';
import { getId } from 'UI/utils';

import { useStyles } from '../styles';

import SendoutExpandableRow from './SendoutExpandableRow';
import { isColumnToHide } from './utils';

const ExpandableRowButton = ({ item, status, onMouseDown }) => {
  const classes = useStyles();
  const isGpacOrRegionalRow = ['gpac', 'regional'].includes(item.team);
  return (
    <>
      {isGpacOrRegionalRow ? (
        ''
      ) : (
        <span
          role="button"
          tabIndex={0}
          className={classes.expandableRowButton}
          key={`${item.team}`}
          onMouseDown={onMouseDown}
        >
          {status.expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </span>
      )}
    </>
  );
};

const SendoutRow = ({ item, year, month, week }) => {
  const [status, setStatus] = useState({
    coach: '',
    expanded: false
  });
  const classes = useStyles();
  const isYearView = !!year?.date;
  const tableColumns = isYearView
    ? [
        {
          label: item.team === 'regional' ? item.regional : item.team,
          class: classes.team
        },
        { label: item.january },
        { label: item.february },
        { label: item.march },
        { label: item.april },
        { label: item.may },
        { label: item.june },
        { label: item.july },
        { label: item.august },
        { label: item.september },
        { label: item.october },
        { label: item.november },
        { label: item.december },
        { label: item.total }
      ]
    : [
        {
          label: item.team === 'regional' ? item.regional : item.team,
          class: classes.team
        },
        { label: item.daily, key: 'daily' },
        { label: item.m, key: 'm' },
        { label: item.t, key: 't' },
        { label: item.w, key: 'w' },
        { label: item.th, key: 'th' },
        { label: item.f, key: 'f' },
        { label: item.goal, key: 'goal' },
        { label: item.actual, key: 'total' },
        { label: item.adjusted, key: 'adjusted' },
        { label: item.percentage, key: 'percentage' }
      ];

  const className = item.team === '' ? '' : classes[item.team];

  return (
    <>
      <tr className={className}>
        <td className={classes.expandableButton}>
          <ExpandableRowButton
            item={item}
            status={status}
            onMouseDown={() =>
              setStatus({
                coach: item.team,
                coachId: item.coach_id,
                expanded: !status.expanded
              })
            }
          />
        </td>

        {tableColumns.map(
          column =>
            !isColumnToHide(column.key) && (
              <td key={getId()} className={column.class || ''}>
                {column.label}
              </td>
            )
        )}
      </tr>
      {status.expanded ? (
        <SendoutExpandableRow
          endpoint={Endpoints.SendoutDashBoardRecruiters}
          coach={status.coach}
          coachId={status.coachId}
          year={year}
          month={month}
          week={week}
        />
      ) : null}
    </>
  );
};

export default SendoutRow;
