export const FORM_FIELDS_MAP = {
  ReachOutReason: {
    key: 'reachOutReason'
  },
  AddressableIssues: {
    key: 'addressableIssues'
  },
  Description: {
    key: 'description'
  },
  Contract: {
    key: 'contract'
  }
};
