import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { getCurrentWeekRange } from 'UI/utils';

const STRINGS = strings.commandCenter.activity.overallPerformance.footer.overallPerformanceFooter;

export const COLUMNS = [
  {
    name: 'idFortpac',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'recruiterName',
    label: 'Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CommandCenterOverviewRecruiter,
          idKey: 'empNo',
          textKey: 'name',
          enableInTabRedirect: true
        }
      },
      setCellHeaderProps: () => ({ className: 'dataTableStickyLeftColumnHeader' }),
      setCellProps: () => ({
        className: 'dataTableStickyLeftColumn'
      })
    }
  },
  {
    name: 'team',
    label: 'Team',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CommandCenterOverviewTeam,
          idKey: 'managerEmpNo',
          textKey: 'team',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'healthStatus',
    label: 'Current Health Status',
    options: {
      sort: true,
      display: true,
      renderer: 'healthStatusChip'
    }
  },
  {
    name: 'hireDate',
    label: 'Start Date',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'tenure',
    label: 'Tenure',
    options: {
      sort: true,
      display: true,
      renderer: 'tenure'
    }
  },
  {
    name: 'daysInStatus',
    label: 'Days in Status *',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'inMails',
    label: 'InMails',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'inMails',
          goalKey: 'inMailsGoals'
        }
      }
    }
  },
  {
    name: 'avgInMails',
    label: 'Avg Daily InMails',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'avgInMails',
          goalKey: 'avgInMailsGoals'
        }
      }
    }
  },
  {
    name: 'bulks',
    label: 'Bulks',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'bulks',
          goalKey: 'bulksGoal'
        }
      }
    }
  },
  {
    name: 'peopleReached',
    label: 'People Reached',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'peopleReached',
          goalKey: 'peopleReachedGoal'
        }
      }
    }
  },
  {
    name: 'posts',
    label: 'Posts',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'todayLivePosts',
          goalKey: 'livePostingsDailyGoal'
        }
      }
    }
  },
  {
    name: 'calls',
    label: 'Calls',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'calls',
          goalKey: 'callsGoal'
        }
      }
    }
  },
  {
    name: 'callsTime',
    label: 'Phone Time',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'callsTime',
          goalKey: 'callsTimeGoal',
          prefix: 'min'
        }
      }
    }
  },
  {
    name: 'sendouts',
    label: 'Sendouts',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'sendouts',
          goalKey: 'sendoutsGoal'
        }
      }
    }
  },
  {
    name: 'placements',
    label: 'Placements',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'feeAgreementsSent',
    label: 'Fee Agreements Sent',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'feeAgreementsSigned',
    label: 'Signed Agreements',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'estimates',
    label: 'Estimates',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'billings',
    label: 'Billings',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'production',
    label: 'Production',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'cashIn',
    label: 'Cash in',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'outStandingBillings',
    label: 'Current O. Billings',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'drawBalance',
    label: 'Current Draw Balance',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'productionYTD',
    label: 'Production YTD',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'cashInYTD',
    label: 'Cash in YTD',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  }
];

export const DATE_RANGE_FILTER_DEF = {
  type: 'dateRange',
  name: 'dateRange',
  paramName: 'dateRange',
  placeholder: 'Date Range',
  defaultRange: getCurrentWeekRange(),
  disableTimeRange: true
};

export const ORDER_BY_OPTIONS = {
  column: 'recruiterName',
  direction: 'asc'
};

export const INLINE_FILTERS = ['dateRange', 'healthStatus', 'tenure'];

export const METRICS =
  'recruiterName,team,healthStatus,daysInStatus,inMails,inMailsGoals,bulks,peopleReached,posts,calls,callsGoal,callsTime,placements,feeAgreementsSent,feeAgreementsSigned,hireDate,tenure,bulksGoal,peopleReachedGoal,livePostingsDailyGoal,callsTimeGoal,sendoutsGoal,cashIn,cashInYTD,production,productionYTD,sendouts,sendovers,billings,outStandingBillings,estimates,avgInMails,avgInMailsGoals,drawBalance';

export const METRICS_DESCRIPTION = [
  STRINGS.metrics.calls,
  STRINGS.metrics.phoneTime,
  STRINGS.metrics.bulks,
  STRINGS.metrics.peopleReached,
  STRINGS.metrics.inMails,
  STRINGS.metrics.posts
];
