// @flow
import intersection from 'lodash/intersection';
import type { UserRole } from 'types/app';
import { Roles } from 'UI/constants/roles';
import { AdditionalRecruiterStatus, AdditionalRecruiterType } from 'UI/constants/status';

import { getCurrentUser } from './Authentication';

export const Permissions = {
  BulkEmail: {
    MicroserviceSend: 'bulk.sendByMicroservice',
    OutlookSendProvider: 'bulk.outlookProvider'
  },
  CommandCenter: {
    Activity: {
      CanUseModule: 'commandCenter.activity.usage'
    },
    DailyPerformance: {
      CanUseModule: 'commandCenter.dailyPerformance.usage'
    },
    DominateSheet: {
      CanUseModule: 'commandCenter.dominateSheet.usage'
    },
    Overview: {
      CanUseModule: 'commandCenter.overview.usage'
    },
    FeedbackLogs: {
      CanUseModule: 'commandCenter.feedbackLogs.usage'
    }
  },
  Company: {
    editPriorityTarget: 'company.priorityTarget.usage'
  },
  Contacts: {
    CanUseModule: 'moduleContacts.usage'
  },
  Dashboard: {
    CanUseModule: 'dashboard.usage'
  },
  FeeAgreements: {
    ModifyGuarantee: 'feeAgreements.modifyGuarantee',
    ModifyPercentage: 'feeAgreements.modifyPercentage',
    CanUseModule: 'newFeeAgreements.usage',
    ManageTemplates: 'newFeeAgreements.manageTemplates'
  },
  Inventory: {
    OverrideAssignment: 'inventory.overrideAssignment'
  },
  NewHome: {
    CanUseModule: 'newHome.usage'
  },
  Placements: {
    CanUseModule: 'placements.usage',
    OverrideApproval: 'placements.overrideApproval'
  },
  SearchProjects: {
    CanMarkAsMasterList: 'searchProjects.canMarkAsMasterList'
  },
  Sendouts: {
    CanUseModule: 'sendout.usage',
    CanViewAllHotsheet: 'sendouts.view.all.hotsheet'
  },
  Textel: {
    CanUseModule: 'textel.usage'
  }
};

export const canUserEditEntity = (user: any, entity: any, options: object): boolean => {
  if (!user || !entity) return false;

  const isAssistantDirector = userHasRole(Roles.AssistantRegionalDirector);
  const teamIdFromUser = user?.teamRelation?.coachId;

  const isAdditionalRecruiterValid =
    entity.additionalRecruiters &&
    entity.additionalRecruiters.some(
      rcr =>
        rcr.status === AdditionalRecruiterStatus.Approved &&
        (rcr.type === AdditionalRecruiterType.Accountable ||
          (options?.includeCollaborator && rcr.type === AdditionalRecruiterType.Collaborator)) &&
        (rcr.recruiter_id === user.id ||
          rcr.coach?.id === user.id ||
          (isAssistantDirector && teamIdFromUser === rcr.coach?.id))
    );

  const isNotAssignable = !entity.recruiter && !entity.coach; // For entitities like names, everyone can edit.
  return (
    user.id === entity.recruiter?.id ||
    user.id === entity.coach?.id ||
    user.id === entity.regional?.id ||
    (isAssistantDirector && entity.coach?.id === teamIdFromUser) ||
    isAdditionalRecruiterValid ||
    isNotAssignable
  );
};

export const canUserOrStaffEditEntity = (user: any, entity: any): boolean =>
  canUserEditEntity(user, entity, {
    includeCollaborator: true
  }) || userHasRoles([Roles.Operations, Roles.DataCoordinator]);

export const doesUserOwnItem = (user: any, item: any): boolean =>
  !user || !item ? false : user?.id === item?.user?.id;

export const userHasPermission = (permission: string, userParam: any) => {
  const user = userParam || getCurrentUser();
  if (!user || !user.permissions || !user.permissions.length) {
    return false;
  }

  return user.permissions.some(permissn => permissn.title === permission);
};

export const FeeAgreementRoleHierarchy = [
  Roles.ProductionDirector,
  Roles.Operations,
  Roles.Leadership,
  Roles.Staff,
  Roles.Finance,
  Roles.DataCoordinator,
  Roles.RegionalDirector,
  Roles.Coach,
  Roles.AssistantRegionalDirector,
  Roles.Recruiter
];

export const getHighestUserRoleInHierarchy = (user: User, roleHierarchy: number[]): UserRole => {
  if (!user || !user.roles || !user.roles.length || !roleHierarchy || !roleHierarchy.length)
    return null;
  const userRoles = user.roles;
  const intersectedRoles = intersection(
    roleHierarchy,
    userRoles.map(({ role }) => role.id)
  );
  return intersectedRoles.length > 0
    ? userRoles.find(({ role }) => role.id === intersectedRoles[0]).role
    : null;
};

/**
 * @param {number} roleId - Number param, can be one of these:
 * 1 - Recruiter
 * 2 - Coach
 * 3 - Admin
 * 4 - Regional Director
 * 5 - Production Director
 * 6 - Operations
 * 7 - Data Coordinator
 * 8 - Finance
 * Get role from roles const in UI/constants/roles
 *
 * @returns {boolean}
 */
export const userHasRole = (roleId: Number, currentUser) => {
  const user = currentUser || getCurrentUser();
  if (!user || !user.roles || !user.roles.length) return false;
  return user.roles.some(role => role.role.id === roleId);
};

export const userHasRoles = (roles: number[], currentUser) => {
  const user = currentUser || getCurrentUser();

  return userHasSomeRoles(user, roles);
};

export const userHasSomeRoles = (user: Object, roles: number[]) => {
  if (!user || !user.roles || !user.roles.length) {
    return false;
  }

  const intersectedRoles = intersection(
    roles,
    user.roles.map(({ role }) => role.id)
  );
  return intersectedRoles.length > 0;
};

export const SearchProjectCollaborationsRoles = {
  Owner: 'Owner',
  Collaborator: 'Collaborator',
  Guest: 'Guest'
};
