// @flow
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { confirm, showAlert } from 'actions/app';
import { extractObjectFromDataTable } from 'hooks/datatable';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { UserProviderContext } from 'providers/UserProvider';
import API from 'services/API';
import { userHasRole } from 'services/Authorization';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import { When } from 'UI/components/atoms/When';
import { ActivityDrawer } from 'UI/components/organisms/ActivityDrawer';
import NoteForm from 'UI/components/organisms/NoteForm';
import { drawerAnchor } from 'UI/constants/defaults';
import { componentDimensions } from 'UI/constants/dimensions';
import { TabKeys } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { SvgNoActivities, SvgNoNotes } from 'UI/res/icons/milano';
import { getErrorMessage, nestTernary } from 'UI/utils/index';

import ProfileTableLayout from '../ProfileTableLayout';

import { getFinalActivityColumns } from './activity.utils';
import { extraNoteRenderers, notesColumns } from './notesColumns';
import { useStyles } from './styles';

type NotesTabProps = {
  DataTableProps: ProfileDataTableProps,
  endpoint: string,
  isQuickViewMode: boolean,
  itemName: string,
  onActivityComplete: any => void,
  tablePreferencesKey: string,
  profileId: string,
  profileModule: string,
  type: 'activity' | 'notes'
};

const {
  inventoryProfiles: {
    sections: {
      tabs: {
        activityNote: { activityActionText, noteActionText }
      }
    }
  }
} = strings;
const { emptyState } = componentDimensions;

const MODES = {
  activity: 'activity',
  notes: 'notes'
};

const ActivityNoteTabLayout = ({
  DataTableProps,
  endpoint,
  tablePreferencesKey,
  isQuickViewMode,
  itemName,
  onActivityComplete,
  profileId,
  profileModule,
  type = MODES.activity
}: NotesTabProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uiState, setUiState] = useState({
    isDrawerOpen: false,
    itemType: type,
    selectedItem: null,
    shouldRefreshTableData: false,
    id: null,
    entityId: null,
    entity: profileModule
  });

  const [user] = useContext(UserProviderContext);
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const isActivityMode = type === MODES.activity;
  const itemSectionName = isActivityMode ? MODES.activity : MODES.notes;
  const section = isActivityMode ? 'activityLogs' : 'notes';
  const initialCols = isActivityMode ? getFinalActivityColumns(profileModule) : notesColumns;
  const itemSingularName = isActivityMode ? 'Activity' : 'Note';

  const areOutcomeFieldsEnabled = checkIfFeatureFlagEnabled(
    FeatureFlags.ActivityOutcomeFieldsInForm
  );

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   A C T I O N S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const openDrawer = () => {
    setUiState(prev => ({
      ...prev,
      itemType: type,
      isDrawerOpen: true,
      shouldRefreshTableData: false,
      entityId: profileId
    }));
  };

  const handleEditNoteClick = (item: any) => {
    setUiState(prev => ({
      ...prev,
      id: item.id,
      itemType: type,
      isDrawerOpen: true,
      selectedItem: item,
      entityId: profileId,
      shouldRefreshTableData: false
    }));
  };

  const closeDrawer = () => {
    setUiState(prev => ({
      ...prev,
      id: null,
      isDrawerOpen: false,
      entityId: null,
      entity: profileModule,
      selectedItem: null
    }));
  };

  const handleNoteCompleted = data => {
    setUiState(prev => ({
      ...prev,
      shouldRefreshTableData: true
    }));
    onActivityComplete && onActivityComplete(data);
    closeDrawer();
  };

  const handleDeleteClick = async (itemId: number, getData) =>
    dispatch(
      confirm({
        severity: 'warning',
        title: strings.formatString(
          strings.inventoryProfiles.sections.tabs.activityNote.deleteAction.title,
          {
            section: itemSingularName
          }
        ),
        message: `Are you sure you want to delete this ${itemSectionName}?`,
        ...strings.shared.ui.keepDeleteButtonCopies,
        onConfirm: async ok => {
          if (!ok) return;

          try {
            const response = await API.delete(`${endpoint}/${section}/${itemId}`);
            if (response.status === 200) {
              await getData();
              dispatch(
                showAlert({
                  severity: 'success',
                  title: `${itemSingularName} deleted successfully`
                })
              );
            }
          } catch (error) {
            dispatch(
              showAlert({
                severity: 'error',
                title: 'Error',
                body: getErrorMessage(error)
              })
            );
          }
        }
      })
    );

  const handleOnSubmitActivitySucced = () =>
    setUiState(prev => ({ ...prev, shouldRefreshTableData: true }));

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   C O L U M N S   C O N F I G
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const isOperationsUser = userHasRole(Roles.Operations, user);

  const extraActions = [
    { name: 'edit', callback: handleEditNoteClick, applyPermissions: true },
    { name: 'delete', callback: handleDeleteClick, pickId: true, applyPermissions: true }
  ];

  const actionsToDisplay = isActivityMode
    ? nestTernary(isOperationsUser, extraActions, [])
    : extraActions;

  const finalActions = [{ name: 'view', callback: handleEditNoteClick }, ...actionsToDisplay];

  const tabEmptyState = isActivityMode ? (
    <SvgNoActivities size={emptyState.width} />
  ) : (
    <SvgNoNotes size={emptyState.width} />
  );

  const shouldEnableOpsActions = (data, profileData) => {
    const { id } = extractObjectFromDataTable(initialCols, ['id'], data?.rowData);
    const selectedItem = profileData.find(item => item.id === id);
    return areOutcomeFieldsEnabled ? true : !selectedItem?.created_by_system;
  };

  return (
    <>
      <ProfileTableLayout
        actionText={isActivityMode ? activityActionText : noteActionText}
        className={classes.activityNoteTable}
        customEmptyState={tabEmptyState}
        customName="created_by_system"
        customRowPermissionResolver={shouldEnableOpsActions}
        DataTableProps={DataTableProps}
        extraRenderers={extraNoteRenderers}
        finalActions={finalActions}
        hasProfileLoaded={profileId}
        initialColumns={initialCols}
        isQuickViewMode={isQuickViewMode}
        onNewItemClick={openDrawer}
        orderByOptions={{ column: 'updated_at', direction: 'desc' }}
        profileModule={profileModule}
        shouldRefresh={uiState.shouldRefreshTableData}
        tabKey={isActivityMode ? TabKeys.ActivityLogs : TabKeys.Notes}
        tablePreferencesKey={tablePreferencesKey}
      />
      <Drawer
        anchor={drawerAnchor}
        open={uiState.isDrawerOpen && type === MODES.notes}
        onClose={closeDrawer}
        ModalProps={{ hideBackdrop: isQuickViewMode }}
        classes={isQuickViewMode ? { paper: classes.activityDrawerInQuickView } : null}
      >
        <div role="presentation">
          <NoteForm
            endpoint={endpoint}
            onNoteClosed={closeDrawer}
            selectedItem={uiState.selectedItem}
            onNoteCompleted={handleNoteCompleted}
            itemName={itemName}
            isQuickViewMode={isQuickViewMode}
          />
        </div>
      </Drawer>
      <When condition={uiState.isDrawerOpen && type === MODES.activity}>
        <ActivityDrawer
          activity={uiState}
          entity={profileModule}
          open={uiState.isDrawerOpen && type === MODES.activity}
          onClose={closeDrawer}
          onSubmitSucceed={handleOnSubmitActivitySucced}
        />
      </When>
    </>
  );
};

ActivityNoteTabLayout.defaultProps = {
  itemName: '',
  isQuickViewMode: false
};

export default ActivityNoteTabLayout;
