import { EntityRoutes } from 'routes/constants';
import { DateFormats, defaultProfileColumnsConfig } from 'UI/constants/defaults';

export const companyProfilePlacementColumns = [
  {
    name: 'id',
    label: 'Number',
    options: {
      renderer: 'default'
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'status'
    }
  },
  {
    name: 'jobOrder',
    label: 'Job Order',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'title'
        }
      }
    }
  },
  {
    name: 'candidate',
    label: 'Candidate',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'fullName'
        }
      }
    }
  },
  {
    name: 'industry_title',
    label: 'Industry',
    options: {
      renderer: 'default',
      sort: false,
      display: true
    }
  },
  {
    name: 'specialty_title',
    label: 'Specialty',
    options: {
      renderer: 'default',
      display: true,
      sort: false
    }
  },
  {
    name: 'subspecialty_title',
    label: 'Subspecialty',
    options: {
      renderer: 'default',
      display: true,
      sort: false
    }
  },
  {
    name: 'fee_amount',
    label: 'Fee Amount',
    options: {
      renderer: 'currency',
      display: true,
      sort: true
    }
  },
  {
    name: 'recruiters',
    label: 'Recruiters',
    options: {
      renderer: 'default',
      display: true,
      sort: false
    }
  },
  {
    name: 'vRecruiter.coach_name',
    label: 'Team',
    options: {
      renderer: 'default',
      sort: false
    }
  },
  {
    name: 'start_date',
    label: 'Start Date',
    options: {
      renderer: 'date',
      display: true
    }
  },
  {
    name: 'created_at',
    label: 'Created at',
    options: {
      renderer: 'date',
      display: true
    }
  },
  {
    name: 'invoice_date',
    label: 'Invoiced Date',
    options: {
      renderer: 'date',
      sort: false
    }
  },
  {
    name: 'approved_date',
    label: 'Approve Date',
    options: {
      renderer: 'date',
      sort: false
    }
  },
  {
    name: 'fall_off_date',
    label: 'Fall Off Date',
    options: {
      renderer: 'date',
      sort: false
    }
  },
  {
    name: 'estimate_creation_date',
    label: 'Estimate Creation Date',
    options: {
      renderer: 'date',
      sort: false
    }
  },
  {
    name: 'estimate_approval_date',
    label: 'Estimate Approval Date',
    options: {
      renderer: 'date'
    }
  },
  {
    name: 'hiringAuthority',
    label: 'Hiring Authority',
    options: {
      display: false,
      sort: false,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'id',
          textKey: 'fullName'
        }
      }
    }
  }
];

const defaultColumns = [
  {
    name: 'id',
    label: 'Nº',
    options: {
      display: false
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'status'
    }
  },
  {
    name: 'start_date',
    label: 'Start Date',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.SimpleDate }
      }
    }
  },
  {
    name: 'fee_amount',
    label: 'Fee Amount',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'currency'
    }
  },
  {
    name: 'company_recruiter',
    label: 'Company Recruiter',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'default'
    }
  },
  {
    name: 'candidate_recruiter',
    label: 'Candidate Recruiter',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'default'
    }
  }
];

export const hiringAuthorityProfilePlacementsColumns = [...defaultColumns];

export const candidateProfilePlacementsColumns = [
  ...defaultColumns,
  {
    name: 'hiringAuthority',
    label: 'Hiring Authority',
    options: {
      display: false,
      sort: false,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'id',
          textKey: 'fullName'
        }
      }
    }
  },
  {
    name: 'jobOrder',
    label: 'Job Order',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'title'
        }
      }
    }
  }
];

export const jobOrderProfilePlacementsColumns = [
  ...defaultColumns,
  {
    name: 'hiringAuthority',
    label: 'Hiring Authority',
    options: {
      display: false,
      sort: false,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'id',
          textKey: 'fullName'
        }
      }
    }
  },
  {
    name: 'candidate',
    label: 'Candidate',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'fullName'
        }
      }
    }
  }
];

const defaultPlacementIncludes = 'status,invoices,splits,hiringAuthority';

export const IncludesByMode = {
  joborder: `${defaultPlacementIncludes},candidate`,
  candidate: `${defaultPlacementIncludes},jobOrder`,
  company: `${defaultPlacementIncludes},candidate,jobOrder,vRecruiter`
};
