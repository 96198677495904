import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { Loader } from 'features/command-center/components/shared/Widget/Loader';
import { ENDPOINTS } from 'features/command-center/constants';
import useDatatable, {
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import { rowClickBuilder } from 'hooks/profileActions';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import DataTable from 'UI/components/organisms/DataTable';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { RowActions } from 'UI/constants/defaults';
import { useDataTableStyles } from 'UI/globalStyles/DataTableStyles';

import { useStyles } from '../FeedbackNotesList/FeedbackNotesList.styled';
import { SupportActivityDetails } from '../SupportActivityDetails';

import {
  ACTIVITY_TYPE_MAP,
  ACTIVIY_MODE,
  COLUMNS,
  ORDER_BY_OPTIONS,
  PAGE_KEY
} from './SupportActivityList.constants';
import { formatResponse } from './SupportActivityList.utils';

export const SupportActivityList = ({ employeeNumber }) => {
  const classes = useStyles();
  const dataTableClasses = useDataTableStyles();
  const dispatch = useDispatch();

  const [selectedActivity, setSelectedActivity] = useState(null);
  const [uiState, setUIState] = useState({
    noteId: null,
    mode: null,
    isLoading: false,
    isSaving: false
  });

  const form = useForm();
  const { reset, getValues, triggerValidation } = form;

  const initialPreferences = getColumnPreferences(PAGE_KEY, 0, ORDER_BY_OPTIONS, COLUMNS);
  const {
    columnPreferences,
    count,
    data,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePerPageChange,
    listState,
    getData
  } = useDatatable({
    apiInstance: SECONDARY_API_CLIENT,
    columnsDefinitions: COLUMNS,
    endpoint: ENDPOINTS.supportActivitiesByEmployee.replace(':employeeNumber', employeeNumber),
    formatResponse,
    initialPreferences,
    key: PAGE_KEY,
    orderByOptions: ORDER_BY_OPTIONS,
    periodDefaultValue: null,
    sendDateWithTimezone: true,
    shouldScrollOnNavigate: true
  });

  const { isLoading, showWholeSkeleton, page, perPage, keyword } = listState;
  const { columns, columnOrder, orderBy, direction } = columnPreferences;

  const actions = [
    {
      name: RowActions.Edit,
      onClick: rowClickBuilder({
        columns,
        data,
        pickId: true,
        callback: id => onActivitySelect({ id, mode: ACTIVIY_MODE.Edit })
      })
    },
    {
      name: RowActions.View,
      onClick: rowClickBuilder({
        columns,
        data,
        pickId: true,
        callback: id => onActivitySelect({ id, mode: ACTIVIY_MODE.View })
      })
    }
  ];

  const actionsColumn = buildRowActionsRendererDefinition({
    actions,
    columns
  });

  const finalColumns = getColumnsToRender([...columns, actionsColumn]);

  const handleCloseDrawer = () => {
    setUIState({ isLoading: false, isSaving: false, mode: null, noteId: null });
    setSelectedActivity(null);
  };

  const onActivitySelect = async ({ id, mode }) => {
    if (!id) return;

    try {
      setUIState(prev => ({ ...prev, noteId: id, mode, isLoading: true }));
      const endpoint = ENDPOINTS.supportActivityById.replace(':id', id);
      const { data: activityData } = await SECONDARY_API_CLIENT.get(endpoint);
      if (ACTIVIY_MODE.Edit) {
        reset({
          notes: activityData.notes
        });
      }
      setSelectedActivity(activityData);
      setUIState(prev => ({
        ...prev,
        isLoading: false
      }));
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: strings.commandCenter.feedbackNotes.noteDetailsDrawer.title,
          body: strings.commandCenter.feedbackNotes.supportActivity.form.error.somethingWrong
        })
      );
    } finally {
      setUIState(prev => ({
        ...prev,
        isLoading: false
      }));
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    triggerValidation();

    const formValues = getValues();

    setUIState(prev => ({
      ...prev,
      isSaving: true
    }));

    try {
      const endpoint = ENDPOINTS.supportActivityById.replace(':id', uiState.noteId);
      const notes = { notes: formValues.notes };
      const response = await SECONDARY_API_CLIENT.put(endpoint, notes);
      if (response.status === HTTPStatusCodes.Ok || response.status === HTTPStatusCodes.NoContent) {
        dispatch(
          showAlert({
            severity: 'success',
            title: strings.commandCenter.feedbackNotes.noteDetailsDrawer.title,
            body: strings.commandCenter.feedbackNotes.supportActivity.form.success
          })
        );
      }
      getData();
      handleCloseDrawer();
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: strings.commandCenter.feedbackNotes.noteDetailsDrawer.title,
          autoHideDuration: 5000,
          body: strings.commandCenter.feedbackNotes.supportActivity.form.error.somethingWrong
        })
      );
    } finally {
      setUIState(prev => ({
        ...prev,
        isSaving: false
      }));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <DataTable
          classes={{ responsiveBase: 'responsiveBase' }}
          className={dataTableClasses.withoutToolbar}
          columnOrder={columnOrder?.length && columnOrder}
          columns={finalColumns}
          count={count ?? 0}
          data={data}
          draggableColumns={{
            enabled: true
          }}
          elevation={0}
          filter={false}
          loading={showWholeSkeleton}
          onColumnDisplayClick={handleColumnDisplayChange}
          onColumnOrderChange={handleColumnOrderChange}
          onColumnSortChange={handleColumnSortChange}
          onPageClick={handlePageChange}
          onPerPageClick={handlePerPageChange}
          onSearchTextChange={handleKeywordChange}
          onToggleFilters={handleFiltersToggle}
          page={page}
          refreshing={isLoading}
          rowsPerPage={perPage}
          searchText={keyword}
          selectableRows="none"
          selectToolbarPlacement="none"
          sortOrder={{ name: orderBy, direction }}
          title={strings.commandCenter.feedbackNotes.emptyState.supportActivity.title}
        />
      </Grid>

      {!!uiState.noteId && (
        <DrawerContentLayout
          title={
            ACTIVITY_TYPE_MAP[selectedActivity?.type] ||
            strings.commandCenter.feedbackNotes.noteDetailsDrawer.title
          }
          footerActionsProps={{
            hidePrimaryButton: uiState.mode === ACTIVIY_MODE.View
          }}
          uiState={{ isSaving: uiState.isSaving, isFormLoading: uiState.isLoading }}
          drawerProps={{
            open: !!uiState.noteId
          }}
          onClose={handleCloseDrawer}
          contentProps={{ className: classes.drawerContent }}
          onSubmit={onSubmit}
        >
          {uiState.isLoading ? (
            <Loader />
          ) : (
            <FormContext {...form}>
              <SupportActivityDetails activity={selectedActivity} mode={uiState.mode} />
            </FormContext>
          )}
        </DrawerContentLayout>
      )}
    </Grid>
  );
};
