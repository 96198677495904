import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  CallsGauge,
  Drawers,
  HealthDistribution,
  InMailsGauge,
  LayoutSkeleton,
  PDAByTenure,
  PeopleReachedGauge,
  PhoneTimeGauge,
  PlacementsStatsSummary,
  ProductionCashInByMonth,
  TeamLayout,
  WeeklyActivityStatsSummary,
  WeeklyInventoryGrowthStatsSummary,
  WeeklyProductivityStatsSummary
} from 'features/command-center';
import { DrawerManagerProvider } from 'features/command-center/contexts';
import { useRefreshStats } from 'features/command-center/hooks';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { When } from 'UI/components/atoms/When';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { UIStatus } from 'UI/constants/status';

export const TeamOverview = ({ id, match }) => {
  const [teamName, setTeamName] = useState('');
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const { refreshData, refreshKey } = useRefreshStats();

  const { state: weeksCatalogState } = useFetchWithStatusV2({
    endpoint: Endpoints.ToolsUsageReportWeeks
  });

  const weeks = weeksCatalogState?.results;
  const teamId = id ?? match?.params?.id;
  const canDoDrillDown = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterDrillDown);
  const requestParams = { team: teamId, ...(canDoDrillDown && { version: 1 }) };

  const handleTeamLoaded = useCallback(newTeamData => {
    const nameParts = newTeamData?.managerName ? newTeamData.managerName.split(' ') : [''];
    setTeamName(nameParts[0]);
  }, []);

  return (
    <DrawerManagerProvider>
      <TeamLayout teamId={teamId} onRefresh={refreshData} onTeamLoaded={handleTeamLoaded}>
        <When condition={weeksCatalogState.status === UIStatus.Loading}>
          <LayoutSkeleton />
        </When>
        <When condition={weeksCatalogState.status !== UIStatus.Loading}>
          <Grid key={refreshKey} container spacing={3}>
            <Grid item xs={12}>
              <HealthDistribution requestParams={requestParams} hasDrillDown={canDoDrillDown} />
            </Grid>

            <Grid item xs={12}>
              <PlacementsStatsSummary requestParams={requestParams} hasDrillDown={canDoDrillDown} />
            </Grid>

            <Grid item xs={12}>
              <WeeklyActivityStatsSummary
                requestParams={requestParams}
                hasDrillDown={canDoDrillDown}
              />
            </Grid>

            <Grid item xs={12}>
              <WeeklyProductivityStatsSummary
                requestParams={requestParams}
                hasDrillDown={canDoDrillDown}
              />
            </Grid>

            <Grid item xs={12}>
              <WeeklyInventoryGrowthStatsSummary
                requestParams={requestParams}
                hasDrillDown={canDoDrillDown}
              />
            </Grid>

            {checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterPDA) && (
              <Grid item xs={12}>
                <PDAByTenure requestParams={requestParams} />
              </Grid>
            )}

            <Grid item xs={12}>
              <ProductionCashInByMonth enableDateFilter requestParams={requestParams} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <PeopleReachedGauge
                requestParams={{ ...requestParams, groupBy: 'team' }}
                weeks={weeks}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InMailsGauge requestParams={{ ...requestParams, groupBy: 'team' }} weeks={weeks} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CallsGauge requestParams={{ ...requestParams, groupBy: 'team' }} weeks={weeks} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <PhoneTimeGauge requestParams={{ ...requestParams, groupBy: 'team' }} weeks={weeks} />
            </Grid>
          </Grid>
        </When>
      </TeamLayout>
      <Drawers prefixTitle={teamName ? `Team ${teamName}'s` : ''} />
    </DrawerManagerProvider>
  );
};
