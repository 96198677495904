// @flow
import React from 'react';
import { THEME } from 'GlobalStyles';
import { EntityRoutes } from 'routes/constants';
import { CandidateIncludes, ContactIncludes, HiringAuthorityIncludes } from 'services/includes';
import FPIcon from 'UI/components/atoms/FPIcon';
import FileManager from 'UI/components/organisms/FileManager';
import { columns as activityColumns } from 'UI/components/organisms/inventoryProfiles/ActivityNoteTabLayout/activityColumns';
import {
  extraNoteRenderers,
  notesColumns
} from 'UI/components/organisms/inventoryProfiles/ActivityNoteTabLayout/notesColumns';
import CandidateOverview from 'UI/components/organisms/inventoryProfiles/CandidateOverview';
import CompanyOverview from 'UI/components/organisms/inventoryProfiles/CompanyOverview';
import ContactOverview from 'UI/components/organisms/inventoryProfiles/ContactOverview';
import HiringAuthorityOverview from 'UI/components/organisms/inventoryProfiles/HiringAuthoritiesOverview';
import JobOrderOverview from 'UI/components/organisms/inventoryProfiles/JobOrderOverview';
import {
  columns as searchProjectColumns,
  virtualProps as searchProjectVirtualProps
} from 'UI/components/organisms/inventoryProfiles/SearchProjectsTab/columns';
import { EMAIL_VALIDATION_STATUSES, FP_SECTIONS } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import {
  CandidateEntity,
  CompanyEntity,
  ContactEntity,
  EntityType,
  HiringAuthorityEntity,
  JobOrderEntity,
  TabKeys
} from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { candidateTabsDefinition } from 'UI/pages/CandidateProfile/tabs';
import { companiesTabDefinition } from 'UI/pages/Companies/tabs';
import { hiringAuthorityTabs } from 'UI/pages/HiringAuthorityProfile/tabs';
import { jobOrdersTabDefinition } from 'UI/pages/JobOrderProfile/tabs';
import { SvgCheck, SvgDeleteTwo, SvgDontContact, SvgWarning } from 'UI/res';
import { hasFeatureFlag } from 'UI/utils';

import {
  formatResponseActivityTable,
  formatResponseJobOrderActivityTable
} from './ActivityNoteTabLayout/activity.utils';
import ActivityNoteTabLayout from './ActivityNoteTabLayout';
import { ChangeLogsColumns, ChangeLogsTab } from './ChangeLogsTab';
import SearchProjectsTab from './SearchProjectsTab';
import { ProfileTablesPreferenceKeys } from './tabKeys';

export { default as ReferenceCheckTabLayout } from './ReferenceCheckTabLayout';

export const PROFILE_ENTITIES_PROPS = {
  [EntityType.Contact]: {
    apiVersion: 2,
    endpoint: Endpoints.Names,
    entityType: ContactEntity.id,
    fileManagerModule: ContactEntity.module,
    overviewComponent: ContactOverview,
    profileRoute: EntityRoutes.ContactProfile,
    defaultTabsProps: {
      searchProjectsProps: {
        tablePreferencesKey: ProfileTablesPreferenceKeys.ContactSearchProjects
      },
      notesPageKey: ProfileTablesPreferenceKeys.ContactsNotes,
      activityPageKey: ProfileTablesPreferenceKeys.ContactsActivity,
      changeLogProps: {
        tablePreferencesKey: ProfileTablesPreferenceKeys.ContactsChangeLogs
      }
    },
    includes: ContactIncludes
  },
  [EntityType.Candidate]: {
    apiVersion: 2,
    endpoint: Endpoints.Candidates,
    entityType: CandidateEntity.id,
    fileManagerModule: CandidateEntity.module,
    includes: CandidateIncludes,
    overviewComponent: CandidateOverview,
    overviewProps: (isBlueSheetLoading, profile) => ({
      blueSheet: profile?.blueSheets?.[0],
      isBlueSheetLoading
    }),
    profileRoute: EntityRoutes.CandidateProfile,
    tabsDefinition: candidateTabsDefinition,
    defaultTabsProps: {
      notesPageKey: ProfileTablesPreferenceKeys.CandidatesNotes,
      activityPageKey: ProfileTablesPreferenceKeys.CandidatesActivity,
      searchProjectsProps: {
        tablePreferencesKey: ProfileTablesPreferenceKeys.CandidatesSearchProjects
      },
      changeLogProps: {
        tablePreferencesKey: ProfileTablesPreferenceKeys.CandidatesChangeLogs
      }
    }
  },
  [EntityType.HiringAuthority]: {
    apiVersion: 2,
    activityNoteTabProps: { apiVersion: 2 },
    endpoint: Endpoints.HiringAuthorities,
    entityType: HiringAuthorityEntity.id,
    fileManagerModule: HiringAuthorityEntity.module,
    includes: HiringAuthorityIncludes,
    overviewComponent: HiringAuthorityOverview,
    profileRoute: EntityRoutes.HiringAuthorityProfile,
    tabsDefinition: hiringAuthorityTabs,
    defaultTabsProps: {
      searchProjectsProps: {
        tablePreferencesKey: ProfileTablesPreferenceKeys.HiringAuthoritySearchProjects
      },
      notesPageKey: ProfileTablesPreferenceKeys.HiringAuthorityNotes,
      activityPageKey: ProfileTablesPreferenceKeys.HiringAuthorityActivity,
      activityLogProps: {
        apiVersion: 2
      },
      notesProps: {
        apiVersion: 2
      },
      changeLogProps: {
        tablePreferencesKey: ProfileTablesPreferenceKeys.HiringAuthorityChangeLogs
      }
    }
  },
  [EntityType.Company]: {
    apiVersion: 2,
    endpoint: Endpoints.Companies,
    entityType: CompanyEntity.id,
    fileManagerModule: CompanyEntity.module,
    overviewComponent: CompanyOverview,
    profileRoute: EntityRoutes.CompanyProfile,
    tabsDefinition: companiesTabDefinition,
    defaultTabsProps: {
      searchProjectsProps: {
        tablePreferencesKey: ProfileTablesPreferenceKeys.CompanySearchProjects
      },
      notesPageKey: ProfileTablesPreferenceKeys.CompanyNotes,
      activityPageKey: ProfileTablesPreferenceKeys.CompanyActivity,
      changeLogProps: {
        tablePreferencesKey: ProfileTablesPreferenceKeys.CompanyChangeLogs
      }
    }
  },
  [EntityType.Joborder]: {
    apiVersion: 2,
    endpoint: Endpoints.JobOrders,
    entityType: JobOrderEntity.id,
    fileManagerModule: JobOrderEntity.module,
    overviewComponent: JobOrderOverview,
    overviewProps: isWriteUpLoading => ({ isWriteUpLoading }),
    profileRoute: EntityRoutes.JobOrderProfile,
    tabsDefinition: jobOrdersTabDefinition,
    defaultTabsProps: {
      notesPageKey: ProfileTablesPreferenceKeys.JobOrdersNotes,
      activityPageKey: ProfileTablesPreferenceKeys.JobOrdersActivity,
      changeLogProps: {
        tablePreferencesKey: ProfileTablesPreferenceKeys.JobOrdersChangeLogs
      }
    }
  }
};

export const ENTITIES_MODE_LABELS = {
  [EntityType.Candidate]: 'Candidate',
  [EntityType.Contact]: 'Contact',
  [EntityType.HiringAuthority]: 'Hiring Authority',
  [EntityType.Company]: 'Company',
  [EntityType.Joborder]: 'Job Order'
};

const DO_NOT_CONTACT_ICON_SIZE = 14;
const DO_NOT_CONTACT_STATUS_ID = 4;

const JOB_ORDER_INCLUDES = 'activityLogType,user';
const COMMON_ACTIVITY_INLCUDES = 'activityLogType,user,outcomes';

export const LAYOUT_BREAKPOINT = `(max-width: 1024px)`;

const doNotContactCompanyIcon = results =>
  results?.type?.id === DO_NOT_CONTACT_STATUS_ID ? <FPIcon component={SvgDontContact} /> : null;

export const noContactStatusChipPreset = results => ({
  icon: doNotContactCompanyIcon(results),
  iconStyles: {
    color: 'white',
    width: DO_NOT_CONTACT_ICON_SIZE,
    height: DO_NOT_CONTACT_ICON_SIZE
  }
});

export const OPT_OUT_COLUMNS_WITH_FEATURE_FLAG = ['opt_out_date', 'opt_out_reason', 'is_opted_out'];

const tabDefinitionBuilder = ({ tabsDefinition, endpoint, entity }) => {
  const tabs = tabsDefinition.map(item =>
    item?.props?.DataTableProps
      ? {
          ...item,
          props: {
            entity,
            ...item.props,
            DataTableProps: {
              endpoint: item?.props?.DataTableProps?.endpoint || `${endpoint}/${item?.endpoint}`,
              ...item.props.DataTableProps
            }
          }
        }
      : {
          ...item,
          props: {
            entity,
            ...item.props
          }
        }
  );

  return tabs;
};

export const tabDefinitionResolver = ({
  defaultTabsProps,
  enableSearchProjects = true,
  endpoint,
  fileManagerProps = {},
  loading,
  overviewComponent,
  overviewProps = () => {},
  profile,
  tabsDefinition = [],
  isChangeLogsTabEnabled = true,
  entity
}) => {
  const {
    activityLogProps = {},
    notesProps = {},
    changeLogProps = {},
    searchProjectsProps = {},
    notesPageKey,
    activityPageKey
  } = defaultTabsProps;

  const activityIncludes =
    entity === EntityType.Joborder ? JOB_ORDER_INCLUDES : COMMON_ACTIVITY_INLCUDES;

  const defaultTabsPresset = [
    {
      id: TabKeys.SearchProjects,
      label: 'Search Projects',
      visible: enableSearchProjects,
      Component: SearchProjectsTab,
      props: {
        ...searchProjectsProps,
        DataTableProps: {
          endpoint: `${endpoint}/${Endpoints.SearchProjects}`,
          virtualProps: searchProjectVirtualProps
        },
        tabKey: TabKeys.SearchProjects,
        initialColumns: searchProjectColumns
      }
    },
    {
      id: TabKeys.ActivityLogs,
      label: 'Activity',
      Component: ActivityNoteTabLayout,
      props: {
        endpoint,
        tablePreferencesKey: activityPageKey,
        DataTableProps: {
          endpoint: `${endpoint}/activity-logs`,
          apiVersion: 1,
          includes: activityIncludes,
          formatResponse:
            entity === EntityType.Joborder
              ? formatResponseJobOrderActivityTable
              : formatResponseActivityTable,
          ...activityLogProps
        },
        tabKey: TabKeys.ActivityLogs,
        initialColumns: activityColumns,
        type: 'activity'
      }
    },
    {
      id: TabKeys.Notes,
      label: 'Notes',
      Component: ActivityNoteTabLayout,
      mode: 'custom',
      props: {
        endpoint,
        tablePreferencesKey: notesPageKey,
        DataTableProps: {
          endpoint: `${endpoint}/notes`,
          apiVersion: 1,
          includes: 'user',
          ...notesProps
        },
        tabKey: TabKeys.Notes,
        initialColumns: notesColumns,
        extraRenderers: extraNoteRenderers,
        type: 'notes'
      },
      tabPanelProps: {
        style: { overflow: 'hidden' }
      },
      actionsTabActiveModules: [FP_SECTIONS.Bulk],
      additionalProps: [
        { propName: 'profileId', pathToValue: 'id' },
        {
          propName: 'profileModule',
          pathToValue: entity
        },
        {
          propName: 'isQuickViewMode',
          value: true
        }
      ],
      entityProps: [
        {
          propName: 'itemName',
          [EntityType.Candidate]: {
            pathToValue: 'personalInformation.full_name'
          },
          [EntityType.Contact]: {
            pathToValue: 'personalInformation.full_name'
          },
          [EntityType.HiringAuthority]: {
            pathToValue: 'full_name'
          },
          [EntityType.Company]: {
            pathToValue: 'name'
          }
        }
      ]
    },
    {
      id: TabKeys.Files,
      label: 'Attachments',
      Component: FileManager,
      mode: 'cardLayout',
      props: {
        layoutInColumns: true,
        showFileSize: true,
        showItemDate: true,
        showItemMenu: true,
        viewItemOnClick: true,
        ...fileManagerProps
      }
    },
    {
      id: TabKeys.ChangeLogs,
      label: 'Changelog',
      Component: ChangeLogsTab,
      visible: isChangeLogsTabEnabled,
      props: {
        endpoint,
        search: false,
        DataTableProps: {
          endpoint: `${endpoint}/change-logs`,
          includes: 'vCreatedBy'
        },
        initialColumns: ChangeLogsColumns,
        ...changeLogProps
      }
    }
  ];

  const finalTabs = [...tabsDefinition, ...defaultTabsPresset];

  const profileTabs = tabDefinitionBuilder({ tabsDefinition: finalTabs, endpoint, entity });

  const tabs = profileTabs.map(item => ({ ...item, props: { entity, ...item.props } }));

  return [
    {
      id: TabKeys.Overview,
      label: 'Overview',
      Component: overviewComponent,
      mode: 'overview',
      props: {
        loading,
        profile,
        ...overviewProps(loading, profile)
      }
    },
    ...tabs
  ];
};

export const getEmailValidationIcon = ({ validationStatus }) => {
  if (!validationStatus || !hasFeatureFlag(FeatureFlags.OtherEmailValidationProfiles)) return null;
  const verdict = validationStatus?.verdict?.name;

  const ValidationIcons = {
    [EMAIL_VALIDATION_STATUSES.INVALID]: <SvgDeleteTwo size={16} fill={THEME.palette.error.main} />,
    [EMAIL_VALIDATION_STATUSES.RISKY]: <SvgWarning size={16} fill={THEME.palette.warning.main} />,
    [EMAIL_VALIDATION_STATUSES.VALID]: <SvgCheck size={16} fill={THEME.palette.success.main} />
  };

  return ValidationIcons[verdict];
};
