import { makeStyles } from '@material-ui/core';

import { flexAlignCenterSpaceBetween } from '../styles';

export const GlobalSearchPersonStyles = {
  root: {
    ...flexAlignCenterSpaceBetween,
    width: '100%'
  },
  main: {
    flexGrow: 1
  }
};

export const useTemplateAutocompleteStyles = makeStyles(() => ({
  container: {
    width: '100%'
  },
  font: {
    fontSize: 16
  }
}));

export const useStyles = makeStyles(() => ({
  trimText: {
    display: '-webkit-box',
    minWidth: 250,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2
  }
}));
