import { Permissions, userHasPermission } from 'services/Authorization';
import { ReferenceCheckTabLayout } from 'UI/components/organisms/inventoryProfiles';
import JobOrdersTab from 'UI/components/organisms/inventoryProfiles/JobOrdersTab';
import {
  CandidateProfileJobOrderColumns,
  VirtualProps as jobOrderVirtualProps
} from 'UI/components/organisms/inventoryProfiles/JobOrdersTab/candidatesJOcolumns';
import PlacementsTab from 'UI/components/organisms/inventoryProfiles/PlacementsTab';
import {
  candidateProfilePlacementsColumns,
  IncludesByMode
} from 'UI/components/organisms/inventoryProfiles/PlacementsTab/columns';
import SendoutsTab from 'UI/components/organisms/inventoryProfiles/SendoutsTab';
import {
  SENDOUTS_COLUMNS,
  SENDOUTS_RENDERERS,
  SENDOUTS_VIRTUAL_PROPS
} from 'UI/components/organisms/inventoryProfiles/SendoutsTab/columns';
import { ProfileTablesPreferenceKeys } from 'UI/components/organisms/inventoryProfiles/tabKeys';
import { EnvApiUrls } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { filterColumnsByFeatureFlag, hasFeatureFlag } from 'UI/utils';
import { formatResponseBuilder } from 'UI/utils/inventory';

import formatReferenceCheckResponse from './utils/format-reference-check-response';
import { REFERENCE_CHECK_COLUMNS } from './CandidateProfile.constants';

const { TabKeys } = require('UI/constants/entityTypes');

const formatResponse = response => formatReferenceCheckResponse(response);

const JOB_ORDER_KEYWORDS_DEFINITION = [
  {
    entry: 'company_name',
    valuesToAttach: [
      { name: 'id', path: 'company.id' },
      { name: 'name', path: 'company.name' }
    ]
  }
];

const jobOrderColumnsWithFeatureFlag = [
  {
    featureFlag: FeatureFlags.JobOrderColumnsHAProfile,
    columns: ['vRecruiter.user_name', 'last_activity_date', 'whiteSheet.jobOrderType', 'status']
  }
];

const jobOrderColumns = filterColumnsByFeatureFlag(
  CandidateProfileJobOrderColumns,
  jobOrderColumnsWithFeatureFlag
);

const handleJobOrderResponseChange = response =>
  formatResponseBuilder(response)(JOB_ORDER_KEYWORDS_DEFINITION);

const includesDefinition = 'position,specialty,address,whiteSheet,company,status,lastActivityLog';

export const candidateTabsDefinition = [
  {
    id: TabKeys.JobOrders,
    label: 'Job Orders',
    endpoint: Endpoints.JobOrders,
    Component: JobOrdersTab,
    props: {
      tablePreferencesKey: ProfileTablesPreferenceKeys.CandidatesJobOrders,
      DataTableProps: {
        includes: hasFeatureFlag(FeatureFlags.JobOrderColumnsHAProfile)
          ? `${includesDefinition},vRecruiter`
          : includesDefinition,
        apiVersion: 1,
        virtualProps: jobOrderVirtualProps,
        formatResponse: handleJobOrderResponseChange,
        orderByOptions: { column: 'created_at', direction: 'desc' }
      },
      initialColumns: jobOrderColumns
    }
  },
  {
    id: TabKeys.Sendouts,
    label: 'Sendouts',
    Component: SendoutsTab,
    endpoint: Endpoints.Sendouts,
    props: {
      tablePreferencesKey: ProfileTablesPreferenceKeys.CandidatesSendouts,
      initialColumns: SENDOUTS_COLUMNS,
      extraRenderers: SENDOUTS_RENDERERS,
      DataTableProps: {
        apiVersion: 1,
        virtualProps: SENDOUTS_VIRTUAL_PROPS,
        includes:
          'type,status,interviews,hiringAuthorithies,jobOrderAccountable,candidateAccountable,joborder,candidate,jobOrderAccountableCoach,candidateAccountableCoach'
      }
    }
  },
  {
    id: TabKeys.Placements,
    label: 'Placements',
    Component: PlacementsTab,
    visible: () => userHasPermission(Permissions.Placements.CanUseModule),
    endpoint: Endpoints.Placements,
    props: {
      tablePreferencesKey: ProfileTablesPreferenceKeys.CandidatesPlacement,
      initialColumns: candidateProfilePlacementsColumns,
      DataTableProps: {
        includes: IncludesByMode.candidate,
        apiVersion: 2
      }
    }
  },
  {
    id: TabKeys.ReferenceCheck,
    label: 'Reference Checks',
    Component: ReferenceCheckTabLayout,
    props: {
      tablePreferencesKey: ProfileTablesPreferenceKeys.CandidatesReferenceChecks,
      initialColumns: REFERENCE_CHECK_COLUMNS,
      DataTableProps: {
        endpoint: `${EnvApiUrls.API_URL_AWS}/${Endpoints.ReferenceCheckByCandidate}`,
        httpHeaders: {
          'x-api-key': EnvApiUrls.API_KEY_AWS
        },
        formatResponse
      },
      paramsBuilder: [
        {
          name: 'id',
          pathToValue: 'id'
        },
        {
          name: 'cache',
          value: 'false'
        }
      ]
    }
  }
];
