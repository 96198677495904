export const MESSAGE_STATUS = Object.freeze({
  delivered: 'delivered',
  received: 'received',
  sending: 'sending',
  error: 'error',
  success: 'success'
});

export const MESSAGE_DIRECTION_PARAM = Object.freeze({
  DESC: 'DESC',
  ASC: 'ASC'
});
