// @flow
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { useGlobalStyles } from 'GlobalStyles';
import useUnload from 'hooks/unload';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import DraftsProvider, { DraftsProviderContext, milisecondsToSave } from 'providers/DraftsProvider';
import queryString from 'query-string';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import { userHasRole } from 'services/Authorization';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { FPCard, FPCardContent, FPCardHeader } from 'UI/components/atoms/FPCard';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FooterActionsControlsLegacy from 'UI/components/molecules/FooterActionsControlsLegacy';
import DraftsManager from 'UI/components/organisms/DraftsManager';
import FileManager from 'UI/components/organisms/FileManager';
import HiringAuthorityDrawer from 'UI/components/organisms/HiringAuthorityDrawer';
import JobOrderForm from 'UI/components/organisms/JobOrderForm';
import { FormFieldsMap } from 'UI/components/organisms/JobOrderForm/fields';
import JobOrderSheet from 'UI/components/organisms/JobOrderSheet';
import { FormFieldsMap as SheetFormFieldsMap } from 'UI/components/organisms/JobOrderSheet/fields';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import {
  DefaultNewJobOrderFileCategories,
  drawerAnchor,
  PageTitles,
  waitingTimeBeforeRedirect
} from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, JobOrderEntity, TabKeys } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { getErrorMessage } from 'UI/utils';
import { decryptId } from 'UI/utils/encrypt';
import { prepareFormToSubmit } from 'UI/utils/forms';
import { Selectors } from 'UI/utils/renderers';

import { AssignmentSheetDrawer } from './components/AssigmentSheetDrawer';
import useNewJobOrderFeatureFlags from './NewJobOrder.hooks';
import { useStyles } from './styles';

type NewJobOrderProps = {
  history: any,
  location: any,
  onShowAlert: any => void
};

const chainedSelects = {
  company_id: [FormFieldsMap.HiringAuthority.key]
};

const DELETE_API_VERSION = 1;

const assignmentSheetVersionsStrings = strings.inventory.jobOrders.create.assignmentSheet;
const AllForms = ({ onShowAlert, location, history }: NewJobOrderProps) => {
  const [attachments, setAttachments] = useState([]);
  const classes = useStyles();

  const { canUseAssignmentSheetOnCreation } = useNewJobOrderFeatureFlags();

  const assignmentSheetStrings =
    assignmentSheetVersionsStrings[canUseAssignmentSheetOnCreation ? 'new' : 'old'];

  const { companyId } = useMemo(() => {
    try {
      const queryParams = queryString.parse(location.search);
      const { company } = queryParams;
      return {
        companyId: company ? decryptId(company, true)[0] : queryParams?.companyId
      };
    } catch (error) {
      onShowAlert({
        severity: 'error',
        title: 'Invalid Company Reference.',
        body: error.message
      });
      return {};
    }
  }, [location.search, onShowAlert]);
  const {
    hasRequiredFieldsForDraft,
    draft,
    drafts,
    saveDraft,
    updateDraft,
    deleteDraftById,
    setDraft,
    saveOnCancel,
    updateLocalStorageDrafts
  } = useContext(DraftsProviderContext);

  const initialFiles = draft?.id ? draft.attachments : attachments;

  const [whitesheet, setWhitesheet] = useState(draft?.id ? draft.sheetData : null);
  const assignmentSheet = useRef(null);
  const [hasHAOptions, setHasHAOptions] = useState(null);
  const [hasHiringAuthority, setHasHiringAuthority] = useState(
    !!(draft?.id && draft?.formData?.hiring_authority_id)
  );
  const isUserCoach = userHasRole(Roles.Coach);

  const form = useForm({
    defaultValues: draft?.id ? draft.formData : {}
  });

  const { register, unregister, setValue, handleSubmit, getValues, watch } = form;
  const globalClasses = useGlobalStyles();

  const formValues = watch();
  const currentCompany = formValues[FormFieldsMap.Company.key];
  const currentCompanyId = currentCompany?.id;
  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isWhitesheetOpen: false,
    isHAOpen: false
  });
  const isHiringAuthorityEnabled = !!currentCompany;
  const selectedRecruiter = watch(FormFieldsMap.Recruiter.key);

  useEffect(() => {
    if (hasHiringAuthority) {
      register(
        { name: FormFieldsMap.HiringAuthority.key },
        { required: 'Hiring Authority is required' }
      );
    } else {
      register({ name: FormFieldsMap.HiringAuthority.key });
    }
  }, [register, hasHiringAuthority]);

  useEffect(() => {
    if (isUserCoach) register({ name: FormFieldsMap.Recruiter.key });
    return () => unregister(FormFieldsMap.Recruiter.key);
  }, [register, unregister, isUserCoach]);

  const toggleDrawer = (drawer, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setUiState(prevState => ({ ...prevState, [drawer]: open }));

    if (canUseAssignmentSheetOnCreation && assignmentSheet.current) {
      setDraft(prev => ({ ...prev, assignmentSheet: assignmentSheet.current }));
    }
  };

  const handleValuesChanged = useCallback(
    values => {
      setDraft(prev => ({ ...prev, formData: values }));
    },
    [setDraft]
  );

  const handleWhitesheetCompleted = newWhitesheet => {
    setUiState(prevState => ({
      ...prevState,
      isWhitesheetOpen: false
    }));
    setValue('whitesheet_completed', true, true);
    setWhitesheet(newWhitesheet);
  };

  useEffect(() => {
    if (canUseAssignmentSheetOnCreation && draft?.assignmentSheet?.id) {
      if (!draft?.assignmentSheet?.progress) {
        setValue('whitesheet_completed', undefined, true);
        return;
      }

      if (draft?.assignmentSheet?.progress.answered !== draft?.assignmentSheet?.progress.total) {
        setValue('whitesheet_completed', undefined, true);
        return;
      }

      setValue('whitesheet_completed', true, true);
    }
  }, [draft, canUseAssignmentSheetOnCreation, setValue]);

  const handleUpdateDraft = useCallback(
    () => updateDraft({ ...draft, formData: getValues() }),
    [draft, getValues, updateDraft]
  );

  const saveOrUpdate = useCallback(() => {
    if (hasRequiredFieldsForDraft && !uiState.isSuccess) {
      draft.id ? handleUpdateDraft(draft) : saveDraft({ shouldConfirm: false });
    }
  }, [draft, handleUpdateDraft, hasRequiredFieldsForDraft, saveDraft, uiState.isSuccess]);

  useUnload(e => {
    e.preventDefault();
    saveOrUpdate();
  });

  useEffect(() => {
    const interval = setInterval(() => {
      saveOrUpdate();
    }, milisecondsToSave);
    return () => {
      clearInterval(interval);
    };
  }, [saveOrUpdate]);

  const handleSetDraftWhitesheet = useCallback(
    sheetData => {
      setDraft(prev => ({ ...prev, sheetData }));
      setWhitesheet(sheetData);
    },
    [setDraft, setWhitesheet]
  );

  const refreshHiringAuthorities = async () => {
    await API.get(`${Endpoints.Companies}/${currentCompanyId}/${Endpoints.HiringAuthorities}`)
      .then(response => {
        if (response.status === HTTPStatusCodes.Ok) setHasHAOptions(response.data);
      })
      .catch(err => {
        onShowAlert({
          severity: 'error',
          title: 'Hiring Authority',
          autoHideDuration: 5000,
          body: getErrorMessage(err)
        });
      });
  };

  const handleHiringAuthorityCompleted = newHiringAuthority => {
    setUiState(prevState => ({
      ...prevState,
      isHAOpen: false
    }));
    refreshHiringAuthorities();
    setHasHiringAuthority(true);
    handleComboChange(FormFieldsMap.HiringAuthority.key, newHiringAuthority);
  };

  const handleFilesChanged = useCallback(
    data => {
      const attachmentsChanged = flatMap(map(data, 'files'));
      setAttachments(attachmentsChanged);
      setDraft(prev => ({ ...prev, attachments: attachmentsChanged }));
    },
    [setDraft]
  );

  const handleCancelClick = () => {
    hasRequiredFieldsForDraft ? saveOnCancel() : history.goBack();
  };

  const handleComboChange = (name?: string, value: any) => {
    setValue(name, value, true);

    if (name && chainedSelects[name]) {
      chainedSelects[name].forEach(chainedSelect => {
        setValue(chainedSelect, null);
      });
    }
  };

  const handleHiringAuthorityLoaded = useCallback((options?: any[]) => {
    setHasHiringAuthority(options && options.length);
  }, []);

  const handleSaveAssignmentSheet = (
    assignmentSheetID: string,
    progress: {
      answered: number,
      total: number
    }
  ) => {
    assignmentSheet.current = { id: assignmentSheetID, progress };

    const draftToUpdate = { ...draft, assignmentSheet: { id: assignmentSheetID, progress } };

    const newDrafts = drafts.map(draftItem =>
      draftItem.id === draftToUpdate.id ? draftToUpdate : draftItem
    );
    updateLocalStorageDrafts(newDrafts);
  };

  const onSubmit = async formData => {
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      const preparedJobOrder = prepareFormToSubmit(formData, FormFieldsMap);
      const preparedSheet = canUseAssignmentSheetOnCreation
        ? { dataSheetId: draft.assignmentSheet.id }
        : { whiteSheet: prepareFormToSubmit(whitesheet, SheetFormFieldsMap) };
      const candidateData = {
        ...preparedJobOrder,
        ...preparedSheet,
        files: attachments.map(att => att.id)
      };
      const response = await API.post(Endpoints.JobOrders, candidateData);
      if (response.data && response.status === 201) {
        setUiState(prevState => ({
          ...prevState,
          isSuccess: true
        }));
        onShowAlert({
          severity: 'success',
          title: 'Job Orders',
          body: 'Awesome! The job order was created successfully'
        });

        if (draft?.id) deleteDraftById(draft?.id);

        setTimeout(() => {
          companyId
            ? history.push(
                `${EntityRoutes.CompanyProfile.replace(':id', companyId)}?tab=${TabKeys.JobOrders}`
              )
            : history.push(EntityRoutes.JobOrderProfile.replace(':id', response.data.data.id));
        }, waitingTimeBeforeRedirect);
      }
    } catch (err) {
      setUiState(prevState => ({
        ...prevState,
        isSuccess: false
      }));
      onShowAlert({
        severity: 'error',
        title: 'Job Order',
        autoHideDuration: 5000,
        body: getErrorMessage(err)
      });
    }
    setUiState(prevState => ({
      ...prevState,
      isSaving: false
    }));
  };

  const handleRecruiterSelect = (name?: string, value: any) => {
    setValue(name, value, true);
  };

  const saveAsDraftButton = draft?.id ? (
    <FPActionButton
      disabled={!hasRequiredFieldsForDraft}
      text={strings.drafts.button.update}
      variant="outlined"
      onClick={handleUpdateDraft}
    />
  ) : (
    <FPActionButton
      disabled={!hasRequiredFieldsForDraft}
      text={strings.drafts.button.save}
      className={classes.saveDraftBtn}
      variant="outlined"
      onClick={() => saveDraft({ shouldConfirm: true })}
    />
  );

  return (
    <Fragment key={drawerAnchor}>
      <Prompt when={hasRequiredFieldsForDraft} message={saveOrUpdate} />
      <FormContext {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={globalClasses.newItemsSection}>
            <FPCard>
              <FPCardHeader
                title="Job Order Data"
                subheader="Fields marked with * are required to add this Job Order"
                variant="section"
              />

              <FPCardContent variant="relaxed">
                <JobOrderForm companyId={companyId} onValuesChanged={handleValuesChanged} />
              </FPCardContent>
            </FPCard>
          </div>
          <div className={globalClasses.newItemsSection}>
            <FPCard>
              <FPCardHeader
                title="Hiring Authorities"
                subheader="A hiring authority related to this job order is required. Choose one"
                variant="section"
              />
              <FPCardContent variant="relaxed">
                <Tooltip
                  placement="top"
                  title={isHiringAuthorityEnabled ? '' : 'Please select a Company first'}
                >
                  <div className="inputContainer">
                    <AutocompleteSelect
                      disabled={!isHiringAuthorityEnabled}
                      defaultOptions={hasHAOptions}
                      name={FormFieldsMap.HiringAuthority.key}
                      displayKey="full_name"
                      selectedValue={formValues[FormFieldsMap.HiringAuthority.key]}
                      placeholder={`Hiring Authority ${hasHiringAuthority ? '*' : ''}`}
                      error={!!form.errors.hiring_authority_id}
                      errorText={
                        form.errors.hiring_authority_id && form.errors.hiring_authority_id.message
                      }
                      url={
                        currentCompanyId &&
                        `${Endpoints.Companies}/${currentCompanyId}/${Endpoints.HiringAuthorities}`
                      }
                      onSelect={handleComboChange}
                      getOptionLabel={option => option.full_name}
                      getOptionSelected={Selectors.byId}
                      onOptionsLoaded={handleHiringAuthorityLoaded}
                      createButton={{
                        text: 'Add Hiring Authority',
                        concatKeyword: false,
                        showAlways: true,
                        internalOptionsFiltering: true
                      }}
                      onCreateButton={toggleDrawer('isHAOpen', true)}
                    />
                  </div>
                </Tooltip>
              </FPCardContent>
            </FPCard>
          </div>

          <div className={globalClasses.newItemsSection}>
            <FPCard>
              <FPCardHeader
                title={assignmentSheetStrings.title}
                subheader={assignmentSheetStrings.subtitle}
                variant="section"
              />

              <FPCardContent variant="relaxed">
                <FPActionButton
                  onClick={toggleDrawer('isWhitesheetOpen', true)}
                  text={whitesheet ? strings.shared.ui.edit : assignmentSheetStrings.actions.fillIn}
                  disabled={!currentCompanyId || !formValues?.title}
                />
                <input
                  ref={form.register({
                    required: assignmentSheetStrings.warningFormFill
                  })}
                  type="hidden"
                  name="whitesheet_completed"
                />
                {!!form.errors.whitesheet_completed && (
                  <div>
                    <FormControl component="fieldset" error={!!form.errors.whitesheet_completed}>
                      <FormHelperText>
                        {form.errors.whitesheet_completed &&
                          form.errors.whitesheet_completed.message}
                      </FormHelperText>
                    </FormControl>
                  </div>
                )}
              </FPCardContent>
            </FPCard>
          </div>

          <div className={globalClasses.newItemsSection}>
            <FPCard>
              <FPCardHeader
                title={strings.fileManager.newProfile.sectionTitle}
                subheader={strings.formatString(strings.fileManager.newProfile.sectionSubtitle, {
                  categorySuggested: 'job description'
                })}
                variant="section"
              />
              <FPCardContent variant="relaxed">
                <FileManager
                  apiVersionForDeleting={DELETE_API_VERSION}
                  defaultCategories={DefaultNewJobOrderFileCategories}
                  filesEndpoint={Endpoints.Files}
                  initialFiles={initialFiles}
                  isNewEntity
                  module={JobOrderEntity.module}
                  onAttachmentsChanged={handleFilesChanged}
                  showItemDate
                  showItemMenu
                  viewItemOnClick
                />
              </FPCardContent>
            </FPCard>
          </div>

          {isUserCoach && (
            <div className={globalClasses.newItemsSection}>
              <FPCard>
                <FPCardHeader
                  title="Assign to a Recruiter"
                  subheader="Choose a recruiter to assign this item to."
                  variant="section"
                />

                <FPCardContent variant="relaxed">
                  <div className="inputContainer">
                    <AutocompleteSelect
                      name={FormFieldsMap.Recruiter.key}
                      selectedValue={selectedRecruiter}
                      placeholder="Recruiters in your team"
                      displayKey="full_name"
                      url={`${Endpoints.Recruiters}/myTeam`}
                      onSelect={handleRecruiterSelect}
                    />
                  </div>
                </FPCardContent>
              </FPCard>
            </div>
          )}

          <div className={globalClasses.newItemsActions}>
            <FooterActionsControlsLegacy
              secondaryAction={handleCancelClick}
              primaryProps={{
                isSaving: uiState.isSaving,
                isSuccess: uiState.isSuccess
              }}
              customThirdButton={hasRequiredFieldsForDraft ? saveAsDraftButton : null}
            />
          </div>
        </form>
      </FormContext>
      {currentCompanyId && uiState.isWhitesheetOpen && (
        <Drawer open onClose={toggleDrawer('isWhitesheetOpen', false)}>
          <When condition={!canUseAssignmentSheetOnCreation}>
            <div role="presentation">
              <JobOrderSheet
                key={currentCompanyId}
                onWhitesheetCompleted={handleWhitesheetCompleted}
                onWhitesheetClosed={toggleDrawer('isWhitesheetOpen', false)}
                whitesheet={whitesheet}
                onBluesheetChanged={handleSetDraftWhitesheet}
                companyId={currentCompanyId || 0}
                hasWhitesheet={!!formValues.whitesheet_completed || draft?.id}
              />
            </div>
          </When>
          <When condition={canUseAssignmentSheetOnCreation}>
            <AssignmentSheetDrawer
              key={currentCompanyId}
              drawerTitle={assignmentSheetStrings.title}
              assignmentSheetId={draft?.assignmentSheet?.id}
              whiteSheet={whitesheet}
              companyId={currentCompanyId || 0}
              onClose={toggleDrawer('isWhitesheetOpen', false)}
              onSaveAssignmentSheet={handleSaveAssignmentSheet}
            />
          </When>
        </Drawer>
      )}

      <Drawer
        anchor={drawerAnchor}
        open={uiState.isHAOpen}
        onClose={toggleDrawer('isHAOpen', false)}
      >
        <div role="presentation">
          <HiringAuthorityDrawer
            entityTitle="Job Order"
            type={EntityType.Company}
            companyId={currentCompanyId}
            endpoint={
              currentCompanyId &&
              `${Endpoints.Companies}/${currentCompanyId}/${Endpoints.HiringAuthorities}`
            }
            onHACompleted={handleHiringAuthorityCompleted}
            onHAClosed={toggleDrawer('isHAOpen', false)}
          />
        </div>
      </Drawer>
    </Fragment>
  );
};

const draftValidationFields = [FormFieldsMap.Position.key];

const NewJobOrder = (props: NewJobOrderProps) => {
  const [selectedDraft, setSelectedDraft] = useState(null);
  useEffect(() => {
    document.title = PageTitles.JobOrderCreate;
  });

  const globalClasses = useGlobalStyles();

  return (
    <ContentPageLayout text="New Job Order" titleLabelProps={{ backNavigation: true }}>
      <div className={globalClasses.itemCreationDataWrapper}>
        <DraftsProvider type={EntityType.Joborder} draftValidationFields={draftValidationFields}>
          <DraftsManager type={EntityType.Joborder} onDraftSelected={setSelectedDraft} />
          <AllForms key={selectedDraft?.id || 0} {...props} />
        </DraftsProvider>
      </div>
    </ContentPageLayout>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAlert: alert => dispatch(showAlert(alert))
  };
};

export default connect(null, mapDispatchToProps)(NewJobOrder);
