import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Refresh } from '@material-ui/icons';
import { showAlert } from 'actions/app';
import clsx from 'clsx';
import { RefreshButton } from 'features/command-center/components/layout/Layout/components/Header/Header.styled';
import { Toolbar } from 'features/command-center/components/shared';
import { FeedbackNoteDrawer } from 'features/command-center/components/shared/FeedbackNotes/FeedbackNotesDrawer';
import { StatsCard } from 'features/command-center/components/shared/StatsCard';
import { ENDPOINTS } from 'features/command-center/constants';
import useDatatable, {
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import { rowClickBuilder } from 'hooks/profileActions';
import usePageTitle from 'hooks/usePageTitle';
import moment from 'moment';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { SECONDARY_API_CLIENT } from 'services/API';
import { Permissions, userHasPermission } from 'services/Authorization';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import { When } from 'UI/components/atoms/When';
import DataTable from 'UI/components/organisms/DataTable';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { backNavigateListConfig } from 'UI/constants/config';
import { DateFormats, PageTitles, RowActions } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { useFixedDatatableStyles, useTableCard } from 'UI/globalStyles/DataTableStyles';
import { SvgInformationCircle } from 'UI/res';
import {
  createCustomStaticRanges,
  downloadFile as downloadCSV,
  getCurrentWeekRange,
  getErrorMessage
} from 'UI/utils';

import {
  COLUMNS,
  DRAWER_CONTENT_TYPES,
  extraFilters,
  FiltersGroups,
  GOAL_METRICS,
  METRICS,
  METRICS_DESCRIPTION,
  ORDER_BY_OPTIONS,
  PAGE_KEY
} from './OverallPerformance.constants';
import { ActionContent, useStyles } from './OverallPerformance.styles';
import { formatResponse } from './OverallPerformance.utils';

const COPIES = strings.commandCenter.overallPerformance;

export const OverallPerformance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fixedTableClasses = useFixedDatatableStyles();
  const tableClasses = useTableCard();
  const { checkIfFeatureFlagEnabled: isFeatureFlagEnabled } = useFeatureFlags();
  const canAccessFeedbackLogs = userHasPermission(
    Permissions.CommandCenter.FeedbackLogs.CanUseModule
  );
  const [employeeNumber, setEmployeeNumber] = useState();
  const [drawerContent, setDrawerContent] = useState(DRAWER_CONTENT_TYPES.NotesForm);

  const initialPreferences = getColumnPreferences(PAGE_KEY, 0, ORDER_BY_OPTIONS, COLUMNS);
  const allMetrics = METRICS.concat(`,${GOAL_METRICS}`);

  const {
    columnPreferences,
    count,
    data,
    filters,
    getData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePeriodChange,
    handlePerPageChange,
    listState,
    queryParams,
    selectedPeriod
  } = useDatatable({
    apiInstance: SECONDARY_API_CLIENT,
    columnsDefinitions: COLUMNS,
    endpoint: `${ENDPOINTS.overallPerformance}?metrics=${allMetrics}`,
    formatResponse,
    initialPreferences,
    key: PAGE_KEY,
    orderByOptions: ORDER_BY_OPTIONS,
    periodDefaultValue: getCurrentWeekRange(),
    periodFilterColumn: 'date',
    shouldScrollOnNavigate: true
  });

  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword } = listState;
  const { columns, columnOrder, orderBy, direction } = columnPreferences;

  const actions = [
    {
      name: RowActions.Add,
      onClick: rowClickBuilder({
        columns,
        data,
        pickId: true,
        idKey: 'empNo',
        callback: empNo => {
          setDrawerContent(DRAWER_CONTENT_TYPES.NotesForm);
          setEmployeeNumber(empNo);
        }
      })
    },
    {
      name: RowActions.View,
      onClick: rowClickBuilder({
        columns,
        data,
        pickId: true,
        idKey: 'empNo',
        callback: empNo => {
          setDrawerContent(DRAWER_CONTENT_TYPES.FeedbackLog);
          setEmployeeNumber(empNo);
        }
      })
    }
  ];

  const handleCloseDrawer = () => {
    setEmployeeNumber(null);
    setDrawerContent(DRAWER_CONTENT_TYPES.NotesForm);
  };

  const actionsColumn = buildRowActionsRendererDefinition({
    actions,
    namespace: 'Overall Performance',
    columns
  });

  const finalColumns = getColumnsToRender(
    isFeatureFlagEnabled(FeatureFlags.FeedbackNotes) && canAccessFeedbackLogs
      ? [...columns, actionsColumn]
      : columns
  );

  usePageTitle({ title: PageTitles.CommandCenterOverallPerformance, isLoading });

  const footer = (
    <div className={classes.footer}>
      <div className={classes.footerTitle}>
        <FPIcon
          role="img"
          aria-label="Info"
          component={SvgInformationCircle}
          onSetColor={palette => palette.info.main}
          size={18}
        />
        <Typography className={classes.label}>Daily & Weekly Activity Goals</Typography>
      </div>
      <ul className={classes.list}>
        {METRICS_DESCRIPTION.map(item => (
          <li key={item.title}>
            <Typography className={classes.label}>
              <span className={classes.goalTitle}>{item.title}:</span> {item.goals}
            </Typography>
          </li>
        ))}
      </ul>
      <Typography className={classes.label}>{COPIES.footer.infoLabel}</Typography>
    </div>
  );

  const handleDownloadFile = () => {
    (async () => {
      await downloadFile();
    })();
    return false;
  };

  const downloadFile = async () => {
    const { startDate, endDate } = selectedPeriod ?? {};
    const today = moment().format(DateFormats.QueryShortFormat);
    const fileStartDate = startDate
      ? moment(startDate).format(DateFormats.QueryShortFormat)
      : today;
    const fileEndDate = startDate ? moment(endDate).format(DateFormats.QueryShortFormat) : today;
    const fileName = `overall-performance-report_${fileStartDate}_${fileEndDate}_${Date.now()}`;
    try {
      const response = await SECONDARY_API_CLIENT.get(
        `${ENDPOINTS.overallPerformanceDownload}?${queryParams.params}&metrics=${METRICS}`,
        {
          responseType: 'blob'
        }
      );
      downloadCSV(response.data, 'text/csv', fileName);
    } catch (err) {
      dispatch(
        showAlert({
          severity: 'error',
          title: "Couldn't download report",
          body: getErrorMessage(err)
        })
      );
    }
  };

  return (
    <FiltersLayout
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      defaultRange={getCurrentWeekRange()}
      enableStore={false}
      extraFiltersDefinition={extraFilters}
      extraSelector={
        <ActionContent>
          <RefreshButton
            type="button"
            startIcon={<Refresh width={20} height={20} />}
            onClick={getData}
          >
            Refresh Stats
          </RefreshButton>
        </ActionContent>
      }
      filters={filters}
      groups={FiltersGroups}
      isLoading={showWholeSkeleton}
      isSideMenuOpen={isSideMenuOpen}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      onPeriodChange={handlePeriodChange}
      onSearch={handleFiltersApply}
      title={COPIES.title}
      titleLabelProps={backNavigateListConfig}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StatsCard footer={footer} paddingContent={0}>
            <DataTable
              classes={{ responsiveBase: 'responsiveBase' }}
              className={clsx(tableClasses.tableRoot, fixedTableClasses.fixedList)}
              columnOrder={columnOrder?.length && columnOrder}
              columns={finalColumns}
              components={{ TableToolbar: Toolbar }}
              count={count ?? 0}
              data={data}
              download={isFeatureFlagEnabled(FeatureFlags.OverallPerformanceDownload)}
              draggableColumns={{
                enabled: true
              }}
              elevation={0}
              filter={false}
              isExpandable
              loading={showWholeSkeleton}
              onColumnDisplayClick={handleColumnDisplayChange}
              onColumnOrderChange={handleColumnOrderChange}
              onColumnSortChange={handleColumnSortChange}
              onDownload={handleDownloadFile}
              onPageClick={handlePageChange}
              onPerPageClick={handlePerPageChange}
              onSearchTextChange={handleKeywordChange}
              onToggleFilters={handleFiltersToggle}
              page={page}
              refreshing={isLoading}
              rowsPerPage={perPage}
              searchText={keyword}
              selectableRows="none"
              selectToolbarPlacement="none"
              sortOrder={{ name: orderBy, direction }}
            />
          </StatsCard>
        </Grid>
      </Grid>
      <When condition={!!employeeNumber}>
        <FeedbackNoteDrawer
          initialDrawerContent={drawerContent}
          employeeNumber={employeeNumber}
          onClose={handleCloseDrawer}
        />
      </When>
    </FiltersLayout>
  );
};
