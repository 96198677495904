// @flow
import React, { useState } from 'react';
import { buildMultiplePhonesActionsRenderer } from 'hooks/datatable';
import { useProfileQuickView } from 'hooks/useProfileQuickView';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import { When } from 'UI/components/atoms/When';
import ProfileTableLayout from 'UI/components/organisms/inventoryProfiles/ProfileTableLayout';
import { RowActions } from 'UI/constants/defaults';
import { TabKeys } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';
import { updateUiState } from 'UI/utils/uiHelpers';

import EmployeesManagerDrawer from '../EmployeesManagerDrawer';
import FPQuickView from '../FPQuickView';

import {
  EmployeeColumnsWithoutLegacyPhones,
  EmployeesColumns,
  EmployeesCustomRender
} from './columns';

type EmployeesTabProps = {
  DataTableProps: ProfileDataTableProps,
  profile: Object
};

const EmployeesTab = ({ profile, DataTableProps, tablePreferencesKey }: EmployeesTabProps) => {
  const [uiState, setUiState] = useState({
    isEmployeesDrawerOpen: false,
    shouldRefreshTableData: false
  });

  const toggleEmployeesDrawer = () => {
    setUiState(prev => ({
      ...prev,
      isEmployeesDrawerOpen: !uiState.isEmployeesDrawerOpen,
      shouldRefreshTableData: false
    }));
  };

  const handleRefreshTableData = () =>
    setUiState(prev => ({ ...prev, shouldRefreshTableData: true }));

  const {
    handleQuickViewPreview,
    itemsToNavigate,
    quickViewEntity,
    entityId,
    quickViewState,
    toggleQuickViewPreview
  } = useProfileQuickView({ quickViewEntityRoleProps: { roleKey: 'roleId' } });

  const finalActions = [
    {
      name: RowActions.Call,
      value: 'phone'
    },
    {
      name: RowActions.Email,
      value: 'email'
    },
    {
      name: RowActions.Text,
      value: 'phone'
    }
  ];

  const initialColumns = !hasFeatureFlag(FeatureFlags.EmployeesMultiplePhones)
    ? EmployeesColumns
    : EmployeeColumnsWithoutLegacyPhones;

  const getSpecificTabColumns = handleAction => [
    buildMultiplePhonesActionsRenderer({
      customName: 'phoneNumbers',
      handleAction,
      featureFlagToCheck: FeatureFlags.EmployeesMultiplePhones,
      columns: initialColumns
    })
  ];

  const handleRefreshTab = async () => {
    await updateUiState({ shouldRefreshTableData: true }, setUiState);
  };

  const handleTableDataLoaded = () => async () => {
    await updateUiState({ shouldRefreshTableData: false }, setUiState);
  };

  return (
    <>
      <ProfileTableLayout
        actionText={strings.inventoryProfiles.sections.tabs.employees.additionButton}
        DataTableProps={{
          onFetchedData: uiState.shouldRefreshTableData && handleTableDataLoaded,
          ...DataTableProps
        }}
        extraRenderers={EmployeesCustomRender}
        hasProfileLoaded={!!profile?.id}
        finalActions={finalActions}
        initialColumns={initialColumns}
        customColumnsExtender={getSpecificTabColumns}
        onNewItemClick={toggleEmployeesDrawer}
        shouldRefresh={uiState.shouldRefreshTableData}
        tabKey={TabKeys.Employees}
        onQuickViewClick={handleQuickViewPreview}
        profileModule={quickViewEntity}
        tablePreferencesKey={tablePreferencesKey}
      />

      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          onClose={toggleQuickViewPreview}
          entityType={quickViewEntity}
          id={entityId}
          itemsToNavigate={itemsToNavigate}
          quickViewEntityRoleProps={{ roleKey: 'roleId' }}
          onEditionCompleted={handleRefreshTab}
        />
      </When>
      {uiState.isEmployeesDrawerOpen && (
        <EmployeesManagerDrawer
          onToggleDrawerOpen={toggleEmployeesDrawer}
          onEmployeeLinked={handleRefreshTableData}
          profile={profile}
          uiState={uiState}
        />
      )}
    </>
  );
};

export default EmployeesTab;
