import { EntityRoutes } from 'routes/constants';
import { OPT_OUT_COLUMN } from 'UI/constants/sharedColumns';

export const HIRING_AUTHORITIES_COLUMNS = [
  {
    name: 'id',
    label: 'Activity Id',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'hiringAuthorityId',
    label: 'Hiring Authority Id',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'activityOutcomeObj',
    label: 'Activity Outcome Object',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'activityLogId',
    label: 'Activity Log Id',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'activityOutcomeTypeId',
    label: 'Activity Outcome Type Id',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'activityLogTypeTitle',
    label: 'Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'createdAt',
    label: 'Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'hiringAuthorityName',
    label: 'HA Reached',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'hiringAuthorityStatusTitle',
    label: 'HA Status',
    options: {
      sort: true,
      display: true
    }
  },
  OPT_OUT_COLUMN,
  {
    name: 'hiringAuthorityEmailVerdict',
    label: 'Email Validation',
    options: {
      sort: false,
      display: true,
      renderer: 'emailStatus'
    }
  },
  {
    name: 'hiringAuthorityEmail',
    label: 'Email',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'hiringAuthorityTitle',
    label: 'Title',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'companyName',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'industryTitle',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'specialtyTitle',
    label: 'Specialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'subspecialtyTitle',
    label: 'Subspecialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'countrySlug',
    label: 'Country',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'stateTitle',
    label: 'State',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'cityTitle',
    label: 'City',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'activityOutcome',
    label: 'Outcome',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'activityOutcomeComments',
    label: 'Comments',
    options: {
      sort: true,
      display: false
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'createdAt',
  direction: 'desc'
};
