export const FORM_FIELDS_MAP = {
  ActivityType: {
    key: 'activityType'
  },
  ActivityBody: {
    key: 'activityBody'
  },
  ActivityOutcome: {
    key: 'activityOutcome'
  },
  ActivityOutcomeComments: {
    key: 'activityOutcomeComments'
  }
};
