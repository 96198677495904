import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import { getId } from 'UI/utils';

import { useStyles } from '../styles';

import { isColumnToHide } from './utils';

export const DataTableRecruiterRender = ({ recruiters, year }) => {
  const classes = useStyles();

  return recruiters.map(item => {
    const isYearView = !!year?.date;

    const tableColumns = isYearView
      ? [
          { label: item.recruiter, class: classes.expandibleTeam },
          { label: item.january },
          { label: item.february },
          { label: item.march },
          { label: item.april },
          { label: item.may },
          { label: item.june },
          { label: item.july },
          { label: item.august },
          { label: item.september },
          { label: item.october },
          { label: item.november },
          { label: item.december },
          { label: item.total }
        ]
      : [
          { label: item.recruiter, class: classes.expandibleTeam },
          { label: item.daily, key: 'daily' },
          { label: item.m, key: 'm' },
          { label: item.t, key: 't' },
          { label: item.w, key: 'w' },
          { label: item.th, key: 'th' },
          { label: item.f, key: 'f' },
          { label: item.goal, key: 'goal' },
          { label: item.actual, key: 'total' },
          { label: item.adjusted, key: 'adjusted' },
          { label: item.percentage, key: 'percentage' }
        ];

    return (
      <tr key={getId()}>
        <td />
        {tableColumns.map(
          column =>
            !isColumnToHide(column.key) && (
              <td key={getId()} className={column.class || ''}>
                {column.label}
              </td>
            )
        )}
      </tr>
    );
  });
};

const LoadingRender = () => {
  const classes = useStyles();
  return (
    <tr>
      <td colSpan={15} className={classes.expandableLoadingRender}>
        <div className={classes.defaultWrapper}>
          <CircularProgress />
        </div>
      </td>
    </tr>
  );
};

const SendoutExpandableRow = ({ endpoint, coach, coachId, year, month, week }) => {
  const coachParams = coachId ? `&coach=${coach}&coachId=${coachId}` : `&coach=${coach}`;
  const { Status } = useFetchWithStatus(
    `${endpoint}?year=${year?.date ? year.date : ''}&month=${month?.date}&week=${
      week?.date ? week.date : ''
    }${coachParams}`
  );
  return (
    <Status
      loading={<LoadingRender />}
      success={recruiters => (
        <DataTableRecruiterRender recruiters={recruiters} year={year} week={week} />
      )}
    />
  );
};

export default SendoutExpandableRow;
