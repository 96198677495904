import React, { useState } from 'react';
import { ENDPOINTS } from 'features/command-center/constants';
import useDatatable, {
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import { rowClickBuilder } from 'hooks/profileActions';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import DataTable from 'UI/components/organisms/DataTable';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { RowActions } from 'UI/constants/defaults';
import { componentDimensions } from 'UI/constants/dimensions';
import { useDataTableStyles } from 'UI/globalStyles/DataTableStyles';
import { SvgAdd, SvgNoNotes } from 'UI/res';

import { FeedbackNoteDetails } from '../FeedbackNoteDetails';

import { COLUMNS, ORDER_BY_OPTIONS, PAGE_KEY } from './FeedbackNotesList.constants';
import { ButtonContainer, Container, TableContainer, useStyles } from './FeedbackNotesList.styled';
import { formatResponse } from './FeedbackNotesList.utils';

const ICON_BUTTON_SIZE = 15;
const { emptyState } = componentDimensions;

export const FeedbackNotesList = ({ employeeNumber, onNewItemClick, onEditNoteClick }) => {
  const classes = useStyles();
  const dataTableClasses = useDataTableStyles();
  const [selectedNoteId, setSelectedNoteId] = useState(null);

  const initialPreferences = getColumnPreferences(PAGE_KEY, 0, ORDER_BY_OPTIONS, COLUMNS);
  const {
    columnPreferences,
    count,
    data,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePerPageChange,
    listState
  } = useDatatable({
    apiInstance: SECONDARY_API_CLIENT,
    columnsDefinitions: COLUMNS,
    endpoint: ENDPOINTS.feedbackNotesByEmployee.replace(':employeeNumber', employeeNumber),
    formatResponse,
    initialPreferences,
    key: PAGE_KEY,
    orderByOptions: ORDER_BY_OPTIONS,
    periodDefaultValue: null,
    sendDateWithTimezone: true,
    shouldScrollOnNavigate: true
  });

  const { isLoading, showWholeSkeleton, page, perPage, keyword } = listState;
  const { columns, columnOrder, orderBy, direction } = columnPreferences;

  const actions = [
    {
      name: RowActions.Edit,
      onClick: rowClickBuilder({
        columns,
        data,
        pickId: true,
        callback: id => onEditNoteClick && onEditNoteClick(id)
      })
    },
    {
      name: RowActions.View,
      onClick: rowClickBuilder({
        columns,
        data,
        pickId: true,
        callback: id => setSelectedNoteId(id)
      })
    }
  ];

  const actionsColumn = buildRowActionsRendererDefinition({
    actions,
    columns
  });

  const finalColumns = getColumnsToRender([...columns, actionsColumn]);

  const handleCloseDrawer = () => {
    setSelectedNoteId(null);
  };

  return (
    <Container container>
      <ButtonContainer item xs={12}>
        <FPActionButton
          icon={SvgAdd}
          text="New Note"
          onClick={() => onNewItemClick && onNewItemClick()}
          iconProps={{ family: 'mini', size: ICON_BUTTON_SIZE }}
        />
      </ButtonContainer>
      <TableContainer item xs={12}>
        <DataTable
          classes={{ responsiveBase: 'responsiveBase' }}
          className={dataTableClasses.withoutToolbar}
          columnOrder={columnOrder?.length && columnOrder}
          columns={finalColumns}
          count={count ?? 0}
          customEmptyState={<SvgNoNotes size={emptyState.width} />}
          data={data}
          draggableColumns={{
            enabled: true
          }}
          elevation={0}
          filter={false}
          loading={showWholeSkeleton}
          onColumnDisplayClick={handleColumnDisplayChange}
          onColumnOrderChange={handleColumnOrderChange}
          onColumnSortChange={handleColumnSortChange}
          onPageClick={handlePageChange}
          onPerPageClick={handlePerPageChange}
          onSearchTextChange={handleKeywordChange}
          onToggleFilters={handleFiltersToggle}
          page={page}
          refreshing={isLoading}
          rowsPerPage={perPage}
          searchText={keyword}
          selectableRows="none"
          selectToolbarPlacement="none"
          sortOrder={{ name: orderBy, direction }}
          subtitle={strings.commandCenter.feedbackNotes.emptyState.notes.subtitle}
          title={strings.commandCenter.feedbackNotes.emptyState.notes.title}
        />
      </TableContainer>

      {!!selectedNoteId && (
        <DrawerContentLayout
          title={strings.commandCenter.feedbackNotes.noteDetailsDrawer.title}
          footerActionsProps={{
            hidePrimaryButton: true
          }}
          drawerProps={{
            open: !!selectedNoteId,
            classes: { paper: classes.drawerLarge }
          }}
          onClose={handleCloseDrawer}
          contentProps={{ className: classes.drawerContent }}
        >
          <FeedbackNoteDetails id={selectedNoteId} />
        </DrawerContentLayout>
      )}
    </Container>
  );
};
