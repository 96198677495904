import React from 'react';
import { Box, ListSubheader } from '@material-ui/core';
import LoaderContent from 'UI/components/templates/DrawerContentLayout/LoaderContent';

import { Conversation } from '../conversationList/Conversation';

import { SearchResultsContainer } from './SearchResults.styled';

export const SearchResults = ({
  results,
  isLoading,
  customMessageKey,
  selectedConversationId,
  onClickConversation
}) => {
  if (isLoading) {
    return (
      <SearchResultsContainer>
        <LoaderContent color="primary" />
      </SearchResultsContainer>
    );
  }

  const getConversationItem = conversation => {
    return (
      <Conversation
        key={conversation[customMessageKey] || conversation.id}
        conversation={conversation}
        isSelected={
          selectedConversationId === conversation[customMessageKey] ||
          selectedConversationId === conversation.id
        }
        onClickConversation={onClickConversation}
      />
    );
  };

  return (
    <SearchResultsContainer>
      <li>
        <ul>
          <ListSubheader>Contacts ({results.contacts.length})</ListSubheader>
          <Box>{results.contacts.map(getConversationItem)}</Box>
        </ul>
      </li>
      <li>
        <ul>
          <ListSubheader>Messages ({results.conversations.length})</ListSubheader>
          <Box>{results.conversations.map(getConversationItem)}</Box>
        </ul>
      </li>
    </SearchResultsContainer>
  );
};
