import { capitalizeName } from 'features/command-center/utils';
import moment from 'moment';
import { DateFormats } from 'UI/constants/defaults';

const convertMinutesToSeconds = value => {
  if (!value) return 0;
  return Math.floor(value * 60);
};

export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    recruiterName: {
      empNo: item.empNo,
      name: capitalizeName(item?.recruiterName)
    },
    managerName: {
      managerEmpNo: item.managerEmpNo,
      team: item?.managerName
    },
    callsTime: item?.callsTime ? convertMinutesToSeconds(item?.callsTime) : 0,
    totalCalls: item?.calls ?? 0 + item?.notConnectedCalls ?? 0,
    hireDate: item?.hireDate && moment(item?.hireDate).format(DateFormats.SimpleDate),
    lastPlacementDate:
      item?.lastPlacementDate && moment(item?.lastPlacementDate).format(DateFormats.SimpleDate)
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
