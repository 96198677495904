import moment from 'moment';
import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';

export const formatStats = ({ data, drawerHandlers }) => {
  const finalData = data?.data ?? data;

  const lastSendout = {
    title: strings.commandCenter.metricsTitles.lastSendoutDate,
    value: finalData?.lastSendout
      ? moment(finalData?.lastSendout?.date).format(DateFormats.SimpleDate)
      : null,
    ...(finalData?.lastSendout?.id && {
      onClick: () => drawerHandlers.lastSendout({ id: finalData?.lastSendout?.id })
    })
  };

  const lastPlacement = {
    title: strings.commandCenter.metricsTitles.lastPlacementDate,
    value: finalData?.lastPlacement
      ? moment(finalData?.lastPlacement?.date).format(DateFormats.SimpleDate)
      : null,
    ...(finalData?.lastPlacement?.id && {
      onClick: () => drawerHandlers.lastPlacement({ id: finalData?.lastPlacement?.id })
    })
  };

  return [lastSendout, lastPlacement].filter(item => item?.title);
};
