// @flow
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { SchemaStatuses } from 'constants/schemaStatuses';
import { useUserContext } from 'providers/UserProvider';
import { getToken } from 'services/Authentication';
import strings from 'strings';
import { EnvApiUrls } from 'UI/constants/defaults';
import { FormSchema } from 'UI/constants/entityTypes';
import { UIStatus } from 'UI/constants/status';
import { getDataSheetCount } from 'UI/pages/NewCandidates/CandidateDataSheetDrawer/CandidateDataSheetDrawer.utils';
import { getErrorMessage } from 'UI/utils';

import { CANDIDATE_DATA_SHEET_QUESTIONS_PATHS } from '../EditCandidateDataSheet.constants';
import { saveCandidateDataSheet } from '../EditCandidateDataSheet.services';
import { createDataSheetFormData, createDataToSend } from '../EditCandidateDataSheet.utils';

const {
  complete: { success: completeSuccess, error: completeError },
  draft: { success: draftSuccess, error: draftError },
  error: fieldsError
} = strings.candidates.editDataSheet.uiMessages;

type UseCandidateDataSheetFormProps = {
  blueSheet: Object,
  dataSheet: Object,
  blueSheetStatus: Object,
  updateDataSheet: (newDataSheet: Object) => void,
  toggleDraftDialog: (value: boolean) => () => void
};
const useCandidateDataSheetForm = ({
  blueSheet,
  dataSheet,
  blueSheetStatus,
  updateDataSheet,
  toggleDraftDialog
}: UseCandidateDataSheetFormProps) => {
  const dispatch = useDispatch();
  const [user] = useUserContext();
  const [status, setStatus] = useState(UIStatus.Default);
  const [formData, setFormData] = useState();
  const saveButtonRef = useRef(null);

  const counts = getDataSheetCount(
    formData,
    CANDIDATE_DATA_SHEET_QUESTIONS_PATHS,
    FormSchema.CandidateDataSheetFull
  );

  useEffect(() => {
    if (!blueSheet && !dataSheet) return;

    const updateFormData = prevFormData => {
      if (dataSheet) return dataSheet.data;

      const newFormData = createDataSheetFormData(prevFormData, {
        ...blueSheet,
        status: blueSheetStatus
      });

      if (!newFormData) return prevFormData;

      return newFormData;
    };

    setFormData(updateFormData);
  }, [blueSheet, dataSheet, blueSheetStatus]);

  const handleOnChange = ({ formData: newFormData }) => {
    setFormData(newFormData);
  };

  const commonStyledFormProps = {
    showErrorList: false,
    noHtml5Validate: true,
    focusOnFirstError: true,
    submitRef: saveButtonRef,
    httpOptions: {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    },
    baseUrl: `${EnvApiUrls.MAIN}/`
  };

  const getHandleOnSubmit =
    ({ candidate, schemaVersion, navigateToProfile }) =>
    async ({ formData: newFormData }) => {
      const sanitizedFormData = newFormData;
      setStatus(UIStatus.Saving);
      setFormData(sanitizedFormData);

      const dataToSend = createDataToSend({
        formData: sanitizedFormData,
        candidate,
        schemaVersion,
        user,
        blueSheetId: blueSheet.id,
        count: counts,
        ...(dataSheet && { dataSheetId: dataSheet.id })
      });

      const toastBody = candidate.personalInformation.full_name;

      try {
        const response = await saveCandidateDataSheet(dataToSend);

        setStatus(UIStatus.IDLE);

        if ([HTTPStatusCodes.BadRequest, HTTPStatusCodes.Unauthorized].includes(response.status)) {
          dispatch(
            showAlert({
              severity: UIStatus.Error,
              title:
                response.data.progress.key === SchemaStatuses.Completed
                  ? completeError
                  : draftError,
              body: toastBody
            })
          );
          return;
        }

        updateDataSheet(response.data);

        dispatch(
          showAlert({
            severity: UIStatus.Success,
            title:
              response.data.progress.key === SchemaStatuses.Completed
                ? completeSuccess
                : draftSuccess,
            body: toastBody
          })
        );

        navigateToProfile();
      } catch (error) {
        setStatus(UIStatus.Error);
        dispatch(
          showAlert({
            severity: UIStatus.Error,
            title: strings.candidates.editDataSheet.title,
            body: getErrorMessage(error)
          })
        );
      }
    };

  const handleOnSave = () => {
    saveButtonRef.current.click();
    toggleDraftDialog(false)();
  };

  const getHandleOnError =
    ({ candidate }) =>
    errors => {
      if (errors && errors.length === 0) return;

      const toastBody = candidate.personalInformation.full_name;

      dispatch(
        showAlert({
          severity: UIStatus.Error,
          title: fieldsError,
          body: toastBody
        })
      );

      toggleDraftDialog(false)();
    };

  return {
    formData,
    formStatus: status,
    commonStyledFormProps,
    canSave:
      dataSheet?.progress.key !== SchemaStatuses.Completed ||
      (dataSheet?.progress.key === SchemaStatuses.Completed && counts.total === counts.answered),
    handleOnChange,
    getHandleOnSubmit,
    handleOnSave,
    getHandleOnError
  };
};

export default useCandidateDataSheetForm;
