import {
  CandidateEntity,
  CompanyEntity,
  ContactEntity,
  HiringAuthorityEntity,
  JobOrderEntity,
  TabKeys
} from 'UI/constants/entityTypes';

export const ProfileTablesPreferenceKeys = {
  ContactSearchProjects: `${ContactEntity.id}-${TabKeys.SearchProjects}`,
  ContactsActivity: `${ContactEntity.id}-${TabKeys.ActivityLogs}`,
  ContactsNotes: `${ContactEntity.id}-${TabKeys.Notes}`,
  ContactsChangeLogs: `${ContactEntity.id}-${TabKeys.ChangeLogs}`,
  CandidatesJobOrders: `${CandidateEntity.id}-${TabKeys.JobOrders}`,
  CandidatesSendouts: `${CandidateEntity.id}-${TabKeys.Sendouts}`,
  CandidatesPlacement: `${CandidateEntity.id}-${TabKeys.Placements}`,
  CandidatesReferenceChecks: `${CandidateEntity.id}-${TabKeys.ReferenceCheck}`,
  CandidatesSearchProjects: `${CandidateEntity.id}-${TabKeys.SearchProjects}`,
  CandidatesActivity: `${CandidateEntity.id}-${TabKeys.ActivityLogs}`,
  CandidatesNotes: `${CandidateEntity.id}-${TabKeys.Notes}`,
  CandidatesChangeLogs: `${CandidateEntity.id}-${TabKeys.ChangeLogs}`,
  JobOrdersSendouts: `${JobOrderEntity.id}-${TabKeys.Sendouts}`,
  JobOrdersPlacements: `${JobOrderEntity.id}-${TabKeys.Placements}`,
  JobOrdersCandidate: `${JobOrderEntity.id}-${TabKeys.Candidates}`,
  JobOrdersHiringAuthority: `${JobOrderEntity.id}-${TabKeys.HiringAuthorities}`,
  JobOrdersActivity: `${JobOrderEntity.id}-${TabKeys.ActivityLogs}`,
  JobOrdersNotes: `${JobOrderEntity.id}-${TabKeys.Notes}`,
  JobOrdersChangeLogs: `${JobOrderEntity.id}-${TabKeys.ChangeLogs}`,
  CompanyJobOrders: `${CompanyEntity.id}-${TabKeys.JobOrders}`,
  CompanySendouts: `${CompanyEntity.id}-${TabKeys.Sendouts}`,
  CompanyPlacements: `${CompanyEntity.id}-${TabKeys.Placements}`,
  CompanyFeeAgreements: `${CompanyEntity.id}-${TabKeys.FeeAgreements}`,
  CompanyHiringAuthorities: `${CompanyEntity.id}-${TabKeys.HiringAuthorities}`,
  CompanyAssocietedCompanies: `${CompanyEntity.id}-${TabKeys.AssociatedCompanies}`,
  CompanyEmployees: `${CompanyEntity.id}-${TabKeys.Employees}`,
  CompanySearchProjects: `${CompanyEntity.id}-${TabKeys.SearchProjects}`,
  CompanyActivity: `${CompanyEntity.id}-${TabKeys.ActivityLogs}`,
  CompanyNotes: `${CompanyEntity.id}-${TabKeys.Notes}`,
  CompanyChangeLogs: `${CompanyEntity.id}-${TabKeys.ChangeLogs}`,
  HiringAuthorityCompanies: `${HiringAuthorityEntity.id}-${TabKeys.Companies}`,
  HiringAuthoritySendouts: `${HiringAuthorityEntity.id}-${TabKeys.Sendouts}`,
  HiringAuthorityJobOrders: `${HiringAuthorityEntity.id}-${TabKeys.JobOrders}`,
  HiringAuthoritySearchProjects: `${HiringAuthorityEntity.id}-${TabKeys.SearchProjects}`,
  HiringAuthorityActivity: `${HiringAuthorityEntity.id}-${TabKeys.ActivityLogs}`,
  HiringAuthorityNotes: `${HiringAuthorityEntity.id}-${TabKeys.Notes}`,
  HiringAuthorityPlacement: `${HiringAuthorityEntity.id}-${TabKeys.Placements}`,
  HiringAuthorityFeeAgreement: `${HiringAuthorityEntity.id}-${TabKeys.FeeAgreements}`,
  HiringAuthorityChangeLogs: `${HiringAuthorityEntity.id}-${TabKeys.ChangeLogs}`
};
