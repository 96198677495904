// @flow
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { showAlert } from 'actions/app';
import { resetFilters as resetDashboardFilters, saveFilters } from 'actions/dashboard';
import {
  resetFilters as resetDashboardPhoneFilters,
  saveFilters as saveDashboardPhoneFilters
} from 'actions/dashboardPhone';
import { resetFilters as resetMapFilters, searchDig, searchInventory } from 'actions/map';
import clsx from 'clsx';
import { globalStyles } from 'GlobalStyles';
import flatten from 'lodash/flatten';
import isNil from 'lodash/isNil';
import { UserProviderContext } from 'providers/UserProvider';
import { filtersToUi } from 'selectors/app';
import strings from 'strings';
import type { Filters, FiltersSection } from 'types/app';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
/** Material Assets and Components */
/** Atoms, Components and Styles */
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPHint from 'UI/components/atoms/FPHint';
import InputRange from 'UI/components/atoms/InputRange';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import Searchbar from 'UI/components/molecules/Searchbar';
import { DateFormats, FilterRangeSufix, FilterType, HideMode } from 'UI/constants/defaults';
import { nestTernary } from 'UI/utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

import type { FilterDefinition, FilterGroup } from './filters';
import { AllFiltersDefinition as DefaultFiltersDefinition } from './filters';
import { useStyles } from './styles';

const chainedSelects = {
  entityType: ['status', 'type'],
  industry: ['specialty', 'subspecialty', 'position'],
  specialty: ['subspecialty'],
  coach: ['recruiter']
};

const mapSections = ['dig', 'inventory'];
const DEFAULT_VERSION = 1;

type SideFiltersLayoutProps = {
  section: Section,
  includeFilters: string[],
  groups: FilterGroup[],
  initialFilters: Filters, // for saved, previousFilters
  defaultFilters: Filters,
  isLoading: boolean,
  showGoToTop: boolean,
  searchButtonText: string,
  areFiltersRequired: boolean,
  contentClassName: any,
  onSearch: (section: Section, any) => mixed,
  onFiltersReset: (section: Section) => mixed,
  onShowAlert: any => void,
  enableCollapsibleForm: boolean,
  children: any,
  isMapInventoryTab: boolean,
  inventoryMapFilters: any
};

const MapTabs = {
  Pil: 0,
  Inventory: 1
};

export const SideFiltersLayout = ({
  section,
  includeFilters,
  groups,
  initialFilters,
  defaultFilters,
  isLoading,
  hasLoaded,
  showGoToTop,
  emptyContent,
  searchButtonText,
  areFiltersRequired,
  contentClassName,
  onSearch,
  onFiltersReset,
  onShowAlert,
  enableCollapsibleForm,
  children,
  isMapInventoryTab,
  inventoryMapFilters,
  extraFiltersDefinition
}: SideFiltersLayoutProps) => {
  const [user] = useContext(UserProviderContext);
  const [filters, setFilters] = useState<Filters>({ ...defaultFilters });
  const [areFiltersDirty, setAreFiltersDirty] = useState(false);
  const AllFiltersDefinition =
    extraFiltersDefinition && Array.isArray(extraFiltersDefinition)
      ? DefaultFiltersDefinition.concat(extraFiltersDefinition)
      : DefaultFiltersDefinition;

  const filtersToInclude =
    groups && groups.length ? flatten(groups.map(group => group.filters)) : includeFilters;

  const filtersDefinition: FilterDefinition[] = filtersToInclude.map(filterToShow =>
    AllFiltersDefinition.find(filter => filter.name === filterToShow)
  );

  const topRef = useRef(null);

  const handleGoToTop = () => {
    topRef.current && (topRef.current.scrollTop = 0);
  };

  const [isFormCollapsed, setIsFormCollapsed] = useState(false);

  useEffect(() => {
    if (isLoading) setIsFormCollapsed(true);
  }, [isLoading]);

  useDeepCompareEffect(() => {
    setFilters({ ...defaultFilters, ...initialFilters });
  }, [initialFilters, defaultFilters]);

  const handleCheckToggle = event => {
    const { checked, name, title } = event.target;
    handleFilterChange(name, checked ? title : '');
  };

  const handleFilterChange = (name?: string, value: any) => {
    setFilters((prevState: Filters): Filters => ({
      ...prevState,
      [name]: value
    }));
    setAreFiltersDirty(true);

    if (name && chainedSelects[name]) {
      chainedSelects[name].forEach(chainedSelect => {
        setFilters((prevState: Filters): Filters => ({
          ...prevState,
          [chainedSelect]: null
        }));
      });
    }
  };

  const handleDateChange =
    (format = DateFormats.QueryShortFormat) =>
    (name, value) => {
      setFilters(prevState => ({
        ...prevState,
        [name]: value?.format(format) ?? value
      }));
    };

  const renderAlert = () => {
    const bodyTxt = isMapInventoryTab ? strings.map.inventory.alertBody : strings.map.dig.alertBody;
    onShowAlert({
      severity: 'warning',
      title: strings.map.inventory.alertTitle,
      body: bodyTxt
    });
  };

  const invalidFields = filtersDefinition.filter(
    filter => filter.isValid && !filter.isValid(filters[filter.name])
  );

  const areInvalidFields = invalidFields.length > 0;

  const handleSearchClick = async () => {
    if (isMapInventoryTab && atLeastTwoFilters()) {
      onShowAlert && renderAlert();
      return;
    }

    if (!atLeastOneFilter && areFiltersRequired) {
      onShowAlert && renderAlert();
      return;
    }

    const asyncTaskToSearch = async () => {
      const filtersWithValue = {};

      if (areInvalidFields) return;

      filtersDefinition.forEach(eachFilter => {
        const isFilterRangeType = eachFilter.type === FilterType.Range;

        if (isFilterRangeType) {
          const filterValueFrom = filters[`${eachFilter.name}${FilterRangeSufix.From}`];
          if (!isNil(filterValueFrom) && filterValueFrom !== '') {
            filtersWithValue[`${eachFilter.name}${FilterRangeSufix.From}`] = {
              paramName: `${eachFilter.paramName}${FilterRangeSufix.From}`,
              value: filterValueFrom,
              displayLabel: `${eachFilter?.displayLabel ?? ''} ${FilterRangeSufix.From}`
            };
          }

          const filterValueTo = filters[`${eachFilter.name}${FilterRangeSufix.To}`];
          if (!isNil(filterValueTo) && filterValueTo !== '') {
            filtersWithValue[`${eachFilter.name}${FilterRangeSufix.To}`] = {
              paramName: `${eachFilter.paramName}${FilterRangeSufix.To}`,
              value: filterValueTo,
              displayLabel: `${eachFilter?.displayLabel ?? ''} ${FilterRangeSufix.To}`
            };
          }
        } else {
          const filterValue = filters[eachFilter.name];
          if (!isNil(filterValue) && filterValue !== '') {
            filtersWithValue[eachFilter.name] = {
              paramName: eachFilter.paramName,
              value: filters[eachFilter.name],
              idKey: eachFilter.idKey,
              outboundValue: eachFilter.outboundTransformer
                ? eachFilter.outboundTransformer(filters[eachFilter.name])
                : null,
              displayLabel: eachFilter?.displayLabel
            };
          }
        }
      });

      onSearch && onSearch(section, filtersWithValue);
      setAreFiltersDirty(false);
    };
    await asyncTaskToSearch();
  };

  const handleResetClick = () => {
    onFiltersReset && onFiltersReset(section);
    setFilters(defaultFilters);
    setIsFormCollapsed(false);
  };

  const renderFilter = (filterDef: FilterDefinition) => {
    const isVisible = filterDef.showWhen !== undefined ? filterDef.showWhen(filters, user) : true;

    const title =
      typeof filterDef.title === 'string'
        ? filterDef.title
        : nestTernary(typeof filterDef.title === 'function', filterDef.title(filters, section), '');

    if (!isVisible && (!filterDef.hideMode || filterDef.hideMode === HideMode.Unmounted)) {
      return null;
    }

    const filterStyles = {
      ...(filterDef.customStyle ? filterDef.customStyle : globalStyles.inputMinSpacing),
      display: !isVisible && filterDef.hideMode === HideMode.Hidden ? 'none' : 'block'
    };

    const isInvalid =
      areInvalidFields && filterDef.isValid && !filterDef.isValid(filters[filterDef.name]);

    switch (filterDef.type) {
      case FilterType.Autocomplete:
        return (
          <div key={`filter-${filterDef.name}`} style={filterStyles}>
            <AutocompleteSelect
              api={filterDef?.api}
              apiVersion={filterDef?.apiVersion || DEFAULT_VERSION}
              name={filterDef.name}
              selectedValue={
                !isNil(filters[filterDef.name])
                  ? filters[filterDef.name]
                  : nestTernary(filterDef.multiple, [], null)
              }
              label={title}
              placeholder={`Filter by ${title}`}
              displayKey={filterDef.displayKey || 'title'}
              url={
                typeof filterDef.url === 'string'
                  ? filterDef.url
                  : nestTernary(
                      typeof filterDef.url === 'function',
                      filterDef.url(filters, section, user),
                      ''
                    )
              }
              onSelect={handleFilterChange}
              renderOption={filterDef.renderOption || undefined}
              disableClearable={filterDef.disableClearable}
              startAdornment={
                filters[filterDef.name] &&
                filterDef.startAdornment &&
                filterDef.startAdornment(
                  filters[filterDef.name].style_class_name ||
                    filters[filterDef.name].style ||
                    filters[filterDef.name].color
                )
              }
              groupBy={filterDef.groupBy}
              getOptionLabel={filterDef.getOptionLabel}
              getOptionSelected={filterDef.getOptionSelected}
              multiple={filterDef.multiple}
              typeahead={filterDef.typeahead}
              typeaheadLimit={filterDef.typeaheadLimit}
              typeaheadParams={
                typeof filterDef.typeaheadParams === 'function'
                  ? filterDef.typeaheadParams(filters, section, user)
                  : filterDef.typeaheadParams
              }
              autocomplete="nothing"
            />
          </div>
        );
      case FilterType.Search:
        return (
          <Box key={`filter-${filterDef.name}`} style={filterStyles}>
            <Searchbar
              name={filterDef.name}
              placeholder={title}
              value={filters[filterDef.name] || ''}
              width="100%"
              onChange={handleFilterChange}
              onSearch={handleSearchClick}
            />
          </Box>
        );
      case FilterType.Select:
        return (
          <Box key={`filter-${filterDef.name}`} style={filterStyles}>
            <AutocompleteSelect
              name={filterDef.name}
              selectedValue={filters[filterDef.name]}
              options={filterDef.options}
              placeholder={filterDef.title}
              displayKey={filterDef.displayKey || 'title'}
              disableClearable={filterDef.disableClearable}
              width="100%"
              onSelect={handleFilterChange}
            />
          </Box>
        );
      case FilterType.Input:
        return (
          <Box key={`filter-${filterDef.name}`} style={filterStyles}>
            <TextBox
              name={filterDef.name}
              value={filters[filterDef.name] || ''}
              label={filterDef.title}
              onChange={handleFilterChange}
              inputType={filterDef.inputType}
              placeholder={filterDef.placeholder}
              error={isInvalid}
              errorText={isInvalid ? filterDef.errorText : null}
              inputProps={filterDef?.InputProps}
            />
          </Box>
        );
      case FilterType.Switch:
        return (
          <Box key={`filter-${filterDef.name}`} style={filterStyles}>
            <FormControlLabel
              label={filterDef.title}
              control={
                <Checkbox
                  checked={filters[filterDef.name] === filterDef.title}
                  color="primary"
                  name={filterDef.name}
                  title={filterDef.title}
                  onChange={handleCheckToggle}
                  value={filters[filterDef.name]}
                  inputProps={{
                    title: filterDef.title
                  }}
                />
              }
            />
          </Box>
        );
      case FilterType.Range:
        return (
          <InputRange
            inputType={filterDef.inputType}
            key={`filter-${filterDef.name}`}
            label={filterDef.title}
            name={filterDef.name}
            onChange={handleFilterChange}
            valueFrom={filters[`${filterDef.name}From`]}
            valueTo={filters[`${filterDef.name}To`]}
          />
        );
      case FilterType.Date:
        return (
          <CustomDatePicker
            key={`filter-${filterDef.name}`}
            name={filterDef.name}
            label={filterDef.title}
            value={filters[filterDef.name] || null}
            onDateChange={handleDateChange(filterDef.format)}
          />
        );
      default:
        return null;
    }
  };

  const renderForm = () => (
    <section>
      {groups && groups.length > 0
        ? renderGroups()
        : filtersDefinition.map(eachFilter => renderFilter(eachFilter))}
    </section>
  );
  const renderGroups = () =>
    groups.map(({ id: groupId, name, filters: groupFilters, showWhen }) => {
      const isMapInventory = name === 'Location' && isMapInventoryTab && inventoryMapFilters.area;
      const isVisible = showWhen ? showWhen(filters, user) : true;

      if (!isVisible) return null;

      return (
        <section key={groupId} className={classes.group}>
          <Typography variant="subtitle1">{name}</Typography>
          {isMapInventory && <FPHint description={strings.map.inventory.locationHint} size="md" />}
          {groupFilters.map(eachFilter =>
            renderFilter(AllFiltersDefinition.find(filter => filter.name === eachFilter))
          )}
        </section>
      );
    });

  const renderTopBar = () => (
    <div className={clsx(classes.topBarContainer, classes.topBarShadow)}>
      <div className={clsx(classes.topBar)}>
        {enableCollapsibleForm && (
          <Button
            className={classes.hideShowButton}
            onClick={toggleCollapse}
            endIcon={<ListAltIcon style={{ fontSize: 20 }} />}
            disabled={!hasLoaded}
          >
            {isFormCollapsed ? 'Show Filters' : 'Hide Filters'}
          </Button>
        )}

        <Button
          color="primary"
          size="small"
          onClick={handleResetClick}
          disabled={!atLeastOneFilter && areFiltersRequired}
          className={
            !atLeastOneFilter && areFiltersRequired ? classes.resetButton : classes.resetActive
          }
        >
          Reset all
        </Button>
      </div>
    </div>
  );

  const atLeastOneFilter = filtersDefinition.some(eachFilter => filters[eachFilter.name]);
  const isUsaSelected = filters?.countries?.some(eachValue => eachValue.id === 1);
  const atLeastTwoFilters = () => {
    const minFiltersRequired = isUsaSelected ? 2 : 1;
    const arrayOfFilters = Object.values(filters).filter(eachFilter => Array.isArray(eachFilter));
    const arrayOfFiltersWithValues = arrayOfFilters.filter(eachFilter => eachFilter.length > 0);

    return arrayOfFiltersWithValues.length < minFiltersRequired;
  };

  const shouldCollapseForm = isFormCollapsed;
  const toggleCollapse = () => {
    if (isFormCollapsed) {
      handleGoToTop();
    }
    setIsFormCollapsed(!isFormCollapsed);
  };
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, contentClassName)}>
      {renderTopBar()}
      <div className={classes.filtersContent} ref={topRef}>
        <div className={clsx(shouldCollapseForm ? classes.hiddenForm : classes.shownForm)}>
          {renderForm()}
        </div>
        {!areFiltersDirty && atLeastOneFilter && emptyContent && isFormCollapsed && (
          <>{emptyContent}</>
        )}
        {isLoading && (
          <div className={classes.circularProgressFormContainer}>
            <CircularProgress />
          </div>
        )}
        {children}
        {showGoToTop && (
          <Tooltip title="Back to Top" placement="top">
            <Button color="primary" className={classes.goToTopButton} onClick={handleGoToTop}>
              <ExpandLessIcon />
            </Button>
          </Tooltip>
        )}
      </div>
      <Box className={classes.filterButton}>
        <FPActionButton
          style={globalStyles.mapActionButton}
          text={isLoading ? 'Searching ...' : searchButtonText}
          onClick={handleSearchClick}
          iconPosition="right"
          disabled={isLoading}
        >
          {isLoading && <CircularProgress className={classes.circularProgress} size={24} />}
        </FPActionButton>
      </Box>
    </div>
  );
};

SideFiltersLayout.defaultProps = {
  searchButtonText: 'Search',
  areFiltersRequired: true,
  enableCollapsibleForm: false,
  isLoading: false,
  groups: [],
  includeFilters: [],
  showGoToTop: false,
  contentClassName: null,
  children: undefined
};

const mapStateToProps = (state, ownProps) => {
  const { section } = ownProps;
  const slice = mapSections.includes(section) ? 'map' : section;
  return {
    isLoading: state[slice]?.ui.isLoading,
    hasLoaded: state[slice]?.ui.hasLoaded,
    initialFilters: filtersToUi(state[slice]?.domain.filters),
    isMapInventoryTab: state.map.ui.activeTab === MapTabs.Inventory,
    inventoryMapFilters: state.map.domain.filters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSearch: (section, filters) => dispatch(determineOnSearch(section, filters)),
    onFiltersReset: section => dispatch(determineOnReset(section)),
    onShowAlert: alert => dispatch(showAlert(alert))
  };
};

const determineOnSearch = (section: FiltersSection, filters: Filters) => {
  switch (section) {
    case 'dig':
      return searchDig(filters);
    case 'inventory':
      return searchInventory(filters);
    case 'dashboardPhone':
      return saveDashboardPhoneFilters(filters);
    default:
      return saveFilters(filters);
  }
};

const determineOnReset = (section: FiltersSection) => {
  switch (section) {
    case 'dashboard':
      return resetDashboardFilters();
    case 'dashboardPhone':
      return resetDashboardPhoneFilters();
    default:
      return resetMapFilters();
  }
};

const SideFiltersLayoutConnected = connect(mapStateToProps, mapDispatchToProps)(SideFiltersLayout);

export default SideFiltersLayoutConnected;
