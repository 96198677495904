// @flow
import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import InfoLabel, { InfoLabelModes } from 'UI/components/molecules/InfoLabel';
import { currencyFormatter, dateFormatter, numberFormatter } from 'UI/utils';

import { GRID_CONFIG } from '../CandidateOverview/config';

// @param {string|object} title - The title to evaluate, which can be a string or an object.
// @param {Array} key - An array used to access properties in case `title` is an object.
// @returns {string} - The resulting title, which can be a string from the `title` parameter or the first element of `key`.

const getTitle = (title, key) => {
  if (typeof title === 'string') {
    return title;
  }
  if (typeof title === 'object' && title !== null) {
    return title[key] ?? key;
  }
  return null;
};

const innerGridRender = (info, { gridSize, descriptionRender, title, descriptionRenderParams }) => {
  if (typeof info !== 'object' || info === null) {
    return null;
  }

  return (
    <Grid container>
      {Object.entries(info).map(([key, value]) => {
        return (
          <Grid item xs={gridSize ?? 'auto'} key={`${key}-${value}}`}>
            <InfoLabel
              title={getTitle(title, key)}
              description={descriptionRender(value, ...(descriptionRenderParams || []))}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const matchStatusValueFromCatalogs = (id, catalogs, catalogSection) => {
  if (!catalogs || typeof catalogs !== 'object') {
    return null;
  }

  if (!(catalogSection in catalogs)) {
    return null;
  }

  const status = catalogs[catalogSection]?.status;

  if (status !== HTTPStatusCodes.Ok) {
    return null;
  }

  const sectionData = catalogs[catalogSection]?.data;

  if (!Array.isArray(sectionData)) {
    return null;
  }

  const value = sectionData.find(item => item.id === id ?? '');

  return value?.title || null;
};

const joinBenefitItems = items => {
  if (!Array.isArray(items)) {
    return '';
  }

  if (items.length === 0) {
    return '';
  }

  if (!items.every(item => typeof item === 'string' || typeof item === 'number')) {
    return '';
  }

  return items.join(', ');
};

const extraSalaryTitles = {
  commissions: 'Commissions / Bonuses',
  realisticCompensation: 'Realistic 1st Year Compensation/Bonus'
};

const salaryRangeTitles = {
  highSalary: 'High Salary',
  idealSalary: 'Ideal Salary',
  lowSalary: 'Low Salary'
};

export const dataFilterAndFormat = catalogs => [
  {
    section: 'section1',
    questions: [
      { id: 1, key: 'signedFeeAgreement' },
      { id: 2, key: 'preparedToSign' },
      { id: 3, key: 'preparedToInterview' },
      {
        id: 4,
        key: 'feePercent',
        format: numberFormatter,
        params: ['percent']
      },
      { id: 5, key: 'feeGuaranteeDays' }
    ]
  },
  {
    section: 'section2',
    questions: [
      { id: 1, key: 'companyReason' },
      {
        id: 2,
        key: 'detailsCompanyLooking'
      },
      {
        id: 3,
        key: 'howLongPositionOpen',
        format: dateFormatter,
        params: [({ SimpleDate }) => SimpleDate]
      },
      {
        id: 4,
        key: 'personInPlace',
        format: dateFormatter,
        params: [({ SimpleDate }) => SimpleDate]
      },
      { id: 5, key: 'coverPosition' },
      { id: 6, key: 'coverPositionDetails' },
      { id: 7, key: 'resultsYouHad' },
      { id: 8, key: 'workCurrently' },
      { id: 9, key: 'notPositionConsequences' }
    ]
  },
  {
    section: 'section3',
    questions: [
      { id: 1, key: 'idealCandidate', config: GRID_CONFIG.fullWidth },
      { id: 3, key: 'whatSkills', config: GRID_CONFIG.fullWidth },
      { id: 4, key: 'whatCertifications', config: GRID_CONFIG.fullWidth },
      { id: 5, key: 'backgroundPersonDetails', config: GRID_CONFIG.fullWidth },
      {
        id: 2,
        key: 'skills',
        customTitle: 'Skills',
        mode: InfoLabelModes.CHIP
      }
    ]
  },
  {
    section: 'section4',
    questions: [
      {
        id: 1,
        key: 'salaryTypeBe',
        config: GRID_CONFIG.fullWidth
      },
      {
        id: 2,
        key: 'salaryRange',
        customTitle: `What's your salary range`,
        config: GRID_CONFIG.fullWidth,
        format: innerGridRender,
        params: [{ gridSize: 4, descriptionRender: currencyFormatter, title: salaryRangeTitles }]
      },
      {
        id: 5,
        key: 'realisticYearTotal',
        config: GRID_CONFIG.fullWidth,
        format: currencyFormatter
      },
      {
        id: 3,
        key: 'extraSalaryInfo',
        format: innerGridRender,
        config: GRID_CONFIG.fullWidth,
        params: [{ gridSize: 6, descriptionRender: currencyFormatter, title: extraSalaryTitles }]
      },
      { id: 4, key: 'companyVehicle' },
      {
        id: 6,
        key: 'benefits',
        config: GRID_CONFIG.fullWidth,
        format: items => joinBenefitItems(items),
        customTitle: 'Benefits'
      },
      { id: 7, key: 'otherBenefits', config: GRID_CONFIG.fullWidth },
      { id: 8, key: 'positionLeadTo', config: GRID_CONFIG.fullWidth }
    ]
  },
  {
    section: 'section5',
    questions: [
      {
        id: 1,
        key: 'workLocation',
        format: matchStatusValueFromCatalogs,
        params: [catalogs, 'workTypes']
      },
      { id: 4, key: 'biggestCompetitors' },
      { id: 3, key: 'coverTerritory' },
      { id: 2, key: 'whatDuties' }
    ]
  },
  {
    section: 'section6',
    questions: [
      { id: 1, key: 'walkMeThrough' },
      { id: 2, key: 'peopleInvolved' },
      {
        id: 3,
        key: 'peopleInvolvedList',
        customTitle: '',
        format: people => {
          if (!people || people.length === 0) return null;

          return (
            <Grid container spacing={2}>
              {people.map(item => {
                return (
                  <React.Fragment key={item?.info?.fullName}>
                    <Grid item xs={6}>
                      <InfoLabel title="Full Name" description={item?.info?.fullName} />
                    </Grid>
                    <Grid item xs={6}>
                      <InfoLabel title="Title" description={item?.info?.title} />
                    </Grid>
                    <Grid item xs={12}>
                      <InfoLabel
                        title="What is the role or responsibilities during the hiring process?"
                        description={item?.responsibilities}
                      />
                    </Grid>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </Grid>
          );
        }
      },
      { id: 4, key: 'nameCompanies' },
      { id: 5, key: 'isThereAnyCompany' },
      { id: 6, key: 'candidateToReachOut' },
      { id: 8, key: 'notes' },
      {
        id: 7,
        key: 'daysForInterviews',
        format: innerGridRender,
        customTitle: 'What days would you be available for interviews?',
        params: [
          {
            gridSize: 4,
            descriptionRender: dateFormatter,
            title: 'Date & Time',
            descriptionRenderParams: [({ SimpleDateTime }) => SimpleDateTime]
          }
        ]
      }
    ]
  },
  {
    section: 'section7',
    questions: [
      { id: 1, key: 'howManyYears' },
      { id: 2, key: 'companyVolume' },
      { id: 3, key: 'howManyPeople' },
      { id: 4, key: 'manyPeopleDivision' },
      { id: 5, key: 'companyRevenue' }
    ]
  },
  {
    section: 'section8',
    questions: [
      { id: 1, key: 'howLong' },
      { id: 2, key: 'uniqueAboutOpportunity' },
      { id: 3, key: 'mostImportantThings' },
      { id: 4, key: 'otherPositionsOpen' }
    ]
  },
  {
    section: 'section9',
    questions: [
      {
        id: 2,
        key: 'jobOrderStatus',

        format: matchStatusValueFromCatalogs,
        params: [catalogs, 'jobOrderStatuses']
      },
      {
        id: 1,
        key: 'jobOrderType',
        format: matchStatusValueFromCatalogs,
        params: [catalogs, 'jobOrderTypes']
      },
      { id: 3, key: 'notes' }
    ]
  }
];
