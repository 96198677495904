import * as React from 'react';

const SvgActivity = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M22.75 11.25h-4.88l-1.61-4.2a1.25 1.25 0 00-1.26-.8 1.27 1.27 0 00-1.13 1l-1.45 7.12-1.83-12.06a1.24 1.24 0 00-2.43-.15l-2.61 9.09h-4.3a1.25 1.25 0 000 2.5h5.24a1.25 1.25 0 001.2-.9L9 8.36l2 13.33a1.25 1.25 0 001.21 1.06 1.25 1.25 0 001.22-1l2-9.8.38 1a1.26 1.26 0 001.17.8h5.74a1.25 1.25 0 000-2.5z" />
    </svg>
  );
};

export default SvgActivity;
