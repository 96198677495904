import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Toolbar } from 'features/command-center/components/shared';
import { StatsCard } from 'features/command-center/components/shared/StatsCard';
import {
  buildRowActionsRendererDefinition,
  extractObjectFromDataTable,
  getColumnsToRender
} from 'hooks/datatable';
import { rowClickBuilder } from 'hooks/profileActions';
import DataTable from 'UI/components/organisms/DataTable';

import { useTableStyles } from './ActivitiesListing.styles';
import ActivityOutcomeForm from './ActivityOutcomeForm';

export const ActivitiesListing = ({
  canExpandRow,
  dataTable,
  enableCustomFilters,
  entity,
  entityConfig,
  expandedRows,
  finalData,
  handleExpansion,
  handleFormCancel,
  handleSubmitSucceed,
  outcomeOptions,
  rowActions
}) => {
  const classes = useTableStyles();

  const { entityIdKey = '', title = '' } = entityConfig;

  const {
    columnPreferences,
    count,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersToggle,
    handleFiltersChange,
    handlePeriodChange,
    handleKeywordChange,
    handlePageChange,
    handlePerPageChange,
    listState
  } = dataTable;

  const { isLoading, showWholeSkeleton, page, perPage, keyword } = listState;
  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const actions = rowActions.map(action => {
    return {
      name: action?.name,
      onClick: rowClickBuilder({
        columns,
        data: finalData,
        callback: action?.onClick
      })
    };
  });

  const tableActions = buildRowActionsRendererDefinition({
    actions,
    namespace: entity || 'activity',
    customName: 'activities'
  });

  const finalColumns = getColumnsToRender([...columns, tableActions]);

  const renderExpandableRow = (rowData, rowMeta) => {
    const colSpan = rowData.length + 1;
    const item = extractObjectFromDataTable(
      columns,
      [
        entityIdKey,
        'activityLogId',
        'activityOutcomeComments',
        'activityOutcomeTypeId',
        'activityOutcomeObj'
      ],
      rowData
    );
    return canExpandRow ? (
      <TableRow aria-label="collection actions">
        <TableCell colSpan={colSpan}>
          <ActivityOutcomeForm
            outcomeOptions={outcomeOptions}
            activityId={item.activityLogId}
            outcome={item.activityOutcomeObj}
            outcomeComments={item.activityOutcomeComments}
            entityId={item?.[entityIdKey]}
            entity={entity}
            onCancel={handleFormCancel}
            onSubmitSucceed={handleSubmitSucceed}
            rowIndex={rowMeta.rowIndex}
          />
        </TableCell>
      </TableRow>
    ) : null;
  };

  return (
    <>
      <StatsCard header={title} paddingContent={0}>
        <DataTable
          className={classes.denseCell}
          columnOrder={columnOrder?.length ? columnOrder : undefined}
          columns={finalColumns}
          components={{ TableToolbar: Toolbar }}
          count={count}
          data={finalData}
          draggableColumns={{ enabled: true }}
          enableCustomFilters={enableCustomFilters}
          expandableRows={canExpandRow}
          expandableRowsHeader={false}
          elevation={0}
          filter={false}
          isExpandable
          isRowExpandable={() => true}
          loading={showWholeSkeleton}
          onColumnDisplayClick={handleColumnDisplayChange}
          onColumnOrderChange={handleColumnOrderChange}
          onColumnSortChange={handleColumnSortChange}
          onFiltersChange={handleFiltersChange}
          onMenuToggle={handleFiltersToggle}
          onPageClick={handlePageChange}
          onPeriodChange={handlePeriodChange}
          onPerPageClick={handlePerPageChange}
          onRowExpansionChange={handleExpansion}
          onSearchTextChange={handleKeywordChange}
          onToggleFilters={handleFiltersToggle}
          page={page}
          refreshing={isLoading}
          rowsExpanded={expandedRows}
          rowsPerPage={perPage}
          searchText={keyword}
          selectableRows="none"
          sortOrder={{ name: orderBy, direction }}
          renderExpandableRow={renderExpandableRow}
        />
      </StatsCard>
    </>
  );
};
