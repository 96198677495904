// @flow

import { defaultProfileColumnsConfig } from 'UI/constants/defaults';

export const columns = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    name: 'activityLogType.title',
    label: 'Type',
    options: {
      renderer: 'default',
      ...defaultProfileColumnsConfig
    }
  },
  {
    name: 'user',
    label: 'Created by',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'custom',
        config: {
          customValue: 'initials'
        }
      }
    }
  },
  {
    name: 'body',
    label: 'Description',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'html'
    }
  },
  {
    name: 'created_at',
    label: 'Creation Date',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'date'
    }
  },
  {
    name: 'updated_at',
    label: 'Last Updated',
    options: {
      display: true,
      renderer: 'date'
    }
  }
];

export const outcomeColumns = [
  {
    name: 'activityOutcomeTitle',
    label: 'Outcome',
    options: {
      display: true,
      sort: false
    }
  },
  {
    name: 'activityOutcomeComments',
    label: 'Outcome Comments',
    options: {
      display: true,
      sort: false
    }
  }
];
