import React from 'react';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import {
  DEFAULT_MESSAGE_STATUS,
  MESSAGE_FAILURE_STATUSES
} from 'features/message-center/constants';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';

import { formatInternationalPhone } from '../messageChat/chat.utils';

import { formatDate } from './Conversation.utils';
import { useStyles } from './styles';

export const Conversation = ({
  conversation,
  onClickConversation,
  isSelected,
  shouldTruncate = false
}) => {
  const {
    attachments,
    fullName = '',
    externalNumber,
    date,
    message,
    unreadMessages = 0,
    messageStatus = DEFAULT_MESSAGE_STATUS,
    isOutbound
  } = conversation;
  const hasErrorStatus = MESSAGE_FAILURE_STATUSES.includes(messageStatus);

  const attachmentText = isOutbound
    ? strings.messageCenter.attachments.sent
    : strings.messageCenter.attachments.received;
  const messageText = message || (attachments?.length > 0 ? attachmentText : '');

  const hasUnreadMessages = unreadMessages > 0;
  const styles = useStyles({
    shouldTruncate
  });

  const phoneNumber = formatInternationalPhone(externalNumber);

  const handleClickConversation = () => {
    onClickConversation && onClickConversation(conversation);
  };

  return (
    <ListItem
      aria-label="conversation"
      button
      className={styles.conversationContainer}
      onClick={handleClickConversation}
      selected={isSelected}
    >
      <ListItemText
        primary={
          <>
            <div className={styles.header}>
              <Typography variant="body2">
                <When condition={fullName}>
                  <span className={styles.contactName}>{fullName}</span>
                </When>
                <span>{phoneNumber}</span>
              </Typography>
              <Typography variant="body2">{formatDate(date)}</Typography>
            </div>
            <div className={styles.content}>
              <Typography
                className={styles.messageText}
                dangerouslySetInnerHTML={{ __html: messageText }}
              />

              {hasErrorStatus ? (
                <ErrorIcon color="error" />
              ) : (
                <Badge
                  badgeContent={unreadMessages}
                  className={styles.unreadBadge}
                  color="primary"
                  invisible={!hasUnreadMessages}
                  max={9}
                />
              )}
            </div>
          </>
        }
      />
    </ListItem>
  );
};
