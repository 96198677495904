import { TABLES_KEYS } from 'features/command-center/constants';

export const PAGE_KEY = TABLES_KEYS.feedbackNotes;

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'reachOutReasonTitle',
    label: 'Reason to Reach Out',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'formattedAddressableIssues',
    label: 'Issues to Address',
    options: {
      sort: false,
      display: true,
      renderer: {
        id: 'multipleChipBuilder',
        config: {
          idKey: 'id',
          labelKey: 'title',
          backgroundColorKey: 'backgroundColor',
          textColorKey: 'textColor'
        }
      },
      setCellHeaderProps: () => ({
        style: { minWidth: 250 }
      })
    }
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      sort: false,
      display: true,
      renderer: {
        id: 'trimText',
        config: {
          isHtml: true
        }
      }
    }
  },
  {
    name: 'contract',
    label: 'Contract',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'trimText'
      }
    }
  },
  {
    name: 'createdAt',
    label: 'Added Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'createdBy.fullName',
    label: 'Added By',
    options: {
      sort: false,
      display: true
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'createdAt',
  direction: 'desc'
};
