import { InfoLabelModes } from 'UI/components/molecules/InfoLabel';
import { cleanUpSchema } from 'UI/pages/NewJobOrder/components/AssigmentSheetDrawer/AssignmentSheetDrawer.utils';
import { LabelRenderers } from 'UI/utils/renderers';

import { GRID_CONFIG } from '../CandidateOverview/config';
import InfoLabelsGrid from '../InfoLabelsGrid';

import { dataFilterAndFormat } from './labels';

export const createReadOnlySchema = (schema, data) => {
  const { feeAgreement: _feeAgreement, ...finalSection1Properties } =
    schema.properties.section1.properties;

  return {
    ...schema,
    properties: {
      ...schema.properties,
      section1: {
        ...schema.properties.section1,
        properties: {
          ...finalSection1Properties,
          ...(data?.section1?.feeAgreement
            ? {
                feeAgreement: {
                  title: schema.properties.section1.properties.feeAgreement.title,
                  type: 'string'
                }
              }
            : {})
        }
      },
      section5: {
        ...schema.properties.section5,
        properties: {
          ...schema.properties.section5.properties,
          workLocation: {
            title: schema.properties.section5.properties.workLocation.title,
            type: 'string'
          }
        }
      },
      section9: {
        ...schema.properties.section9,
        properties: {
          ...schema.properties.section9.properties,
          jobOrderType: {
            title: schema.properties.section9.properties.jobOrderType.title,
            type: 'string'
          },
          jobOrderStatus: {
            title: schema.properties.section9.properties.jobOrderStatus.title,
            type: 'string'
          }
        }
      }
    }
  };
};

export const createReadOnlyFormData = (formData, catalogs) => {
  if (!catalogs && formData) return formData;

  if (!catalogs || !formData) return undefined;

  const feeAgreementObj = catalogs.feeAgreements.data.find(
    feeAgreement => feeAgreement.id === formData.section1?.feeAgreement
  );

  const feeAgreementText = feeAgreementObj ? LabelRenderers.feeAgreement(feeAgreementObj) : '';

  return {
    ...formData,
    section1: {
      ...formData.section1,
      feeAgreement: feeAgreementText
    },
    section5: {
      ...formData.section5,
      workLocation:
        catalogs.workTypes.data.find(workType => workType.id === formData.section5?.workLocation)
          ?.title ?? ''
    },
    section9: {
      ...formData.section9,
      jobOrderType:
        catalogs.jobOrderTypes.data.find(
          jobOrderType => jobOrderType.id === formData.section9?.jobOrderType
        )?.title ?? '',
      jobOrderStatus:
        catalogs.jobOrderStatuses.data.find(
          jobOrderStatus => jobOrderStatus.id === formData.section9?.jobOrderStatus
        )?.title ?? ''
    }
  };
};

const createFormattedData = (data, finalDataSheet) =>
  Object.keys(data).map(section => {
    const { properties, title } = data[section];

    const answers = finalDataSheet.sections[section];

    const relation = {
      cardSection: title,
      key: section,
      questionsAndAnswers: Object.keys(properties).map(key => {
        const question = properties[key];

        return {
          questionTitle: question?.title ?? '',
          answer: answers?.[key] ?? '',
          key
        };
      })
    };

    return { ...relation };
  });

const createCards = ({ filteredData, loading }) => {
  if (!Array.isArray(filteredData)) {
    return [];
  }

  return filteredData.map(info => {
    if (typeof info !== 'object' || info === null) {
      return null;
    }

    if (!Array.isArray(info.questionsAndAnswers)) {
      return null;
    }

    return {
      cardProps: {
        headerProps: { title: info.cardSection }
      },
      Component: InfoLabelsGrid,
      componentProps: {
        loading,
        isFlexibleGrid: true,
        labels: info.questionsAndAnswers.map(questions => {
          if (typeof questions !== 'object' || questions === null) {
            return null;
          }

          const id = typeof questions.id === 'number' ? questions.id : null;

          const questionTitle =
            typeof questions.questionTitle === 'string' ? questions.questionTitle : '';

          const customQuestionTitle =
            typeof questions.customTitle === 'string' && questions.customTitle;

          const title = customQuestionTitle || questionTitle;

          const description =
            typeof questions.format === 'function'
              ? questions.format(
                  questions.answer,
                  ...(Array.isArray(questions.params) ? questions.params : [])
                )
              : questions.answer || '';

          const infoLabelMode = typeof questions.mode === 'string' && questions.mode;

          const mode = Object.values(InfoLabelModes).includes(infoLabelMode)
            ? questions.mode
            : InfoLabelModes.TEXT;

          return {
            id,
            title,
            description,
            mode,
            config: GRID_CONFIG.fullWidth
          };
        })
      }
    };
  });
};

const filteredDataQuestions = ({ catalogs, rawDataSheetData }) =>
  dataFilterAndFormat(catalogs).map(filterSection => {
    const questionsToRender = filterSection.questions.map(q => q.key);

    const sectionData = rawDataSheetData.find(section => section.key === filterSection.section);

    if (sectionData) {
      return {
        ...sectionData,
        questionsAndAnswers: sectionData.questionsAndAnswers
          .filter(question => questionsToRender.includes(question.key))
          .map(question => {
            const { config, format, params, id, customTitle, mode } =
              filterSection.questions.find(q => q.key === question.key) || {};

            return {
              id,
              ...question,
              config,
              format,
              params,
              customTitle,
              mode
            };
          })
      };
    }
    return null;
  });

const createCardsWithOrdererQuestions = cards =>
  cards.map(item => {
    return {
      cardProps: { ...item.cardProps },
      Component: item.Component,
      componentProps: {
        ...item.componentProps,
        labels: item.componentProps.labels.sort((a, b) => a.id - b.id)
      }
    };
  });

export const dataSheetCards = ({ dataSheet, catalogs, loading }) => {
  if (!dataSheet) return null;

  const schema = cleanUpSchema(dataSheet.data, dataSheet.schema.structure);

  const finalDataSheet = {
    properties: {
      ...schema.properties,
      section6: {
        ...schema.properties.section6,
        properties: {
          ...schema.properties.section6.properties,
          peopleInvolvedList: {}
        }
      }
    },
    sections: dataSheet.data
  };

  const data = finalDataSheet.properties;

  const rawDataSheetData = createFormattedData(data, finalDataSheet);
  const filteredData = filteredDataQuestions({ catalogs, rawDataSheetData });
  const cards = createCards({ filteredData, loading });
  const cardsWithOrdererQuestions = createCardsWithOrdererQuestions(cards);

  return cardsWithOrdererQuestions;
};
