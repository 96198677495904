import omit from 'lodash/omit';
import { userHasPermission, userHasRole } from 'services/Authorization';
import { FormSchema, PaymentMode } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { getInitials } from 'UI/utils/getinitials';
import { LabelRenderers } from 'UI/utils/renderers';
import { getQuestionsCounts } from 'utils';

import { extractSkillsFromExperienceField } from '../EditCandidateDataSheet/EditCandidateDataSheet.utils';

const ANSWER = {
  Positive: 'Yes',
  Negative: 'No'
};

export const getFeeAgreementOptions = (catalogs, whiteSheet) => {
  const possibleFeeAgreements = catalogs.feeAgreements.data.map(feeAgreement => ({
    const: feeAgreement.id,
    title: LabelRenderers.feeAgreement(feeAgreement)
  }));

  return whiteSheet.companyFeeAgreement
    ? [
        ...possibleFeeAgreements,
        {
          const: whiteSheet.companyFeeAgreement.id,
          title: LabelRenderers.feeAgreement(whiteSheet.companyFeeAgreement)
        }
      ].filter(
        (feeAgreement, index, self) =>
          self.findIndex(feeAgreementItem => feeAgreementItem.const === feeAgreement.const) ===
          index
      )
    : possibleFeeAgreements;
};

export const createDataSheetFormData = (formData, dataSheet, isFeeAgreementNeeded) => {
  if (!dataSheet) return null;

  const createFormData = () => {
    return {
      section1: {},
      section2: {},
      section3: {},
      section4: {},
      section5: {},
      section6: {},
      section7: {},
      section8: {},
      section9: {}
    };
  };

  const finalFormData = formData || createFormData();

  const formatDateTimes = (dateTimes = []) => {
    return dateTimes.map(dateTime => {
      if (!dateTime) return undefined;

      const date = new Date(dateTime);

      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        0
      ).toISOString();
    });
  };

  const interviewDates = formatDateTimes(dataSheet.preset_interview_dates);

  return {
    ...finalFormData,
    section1: {
      ...finalFormData.section1,
      signedFeeAgreement:
        dataSheet.discussing_agreement_complete === 1 ? ANSWER.Positive : ANSWER.Negative,
      preparedToSign: dataSheet.company_prepared_to_sign_service_agreement
        ? ANSWER.Positive
        : ANSWER.Negative,
      preparedToInterview: Number(dataSheet.company_prepared_to_interview_asap)
        ? ANSWER.Positive
        : ANSWER.Negative,
      ...(isFeeAgreementNeeded && {
        feeAgreement: dataSheet.company_fee_agreement_id
      }),
      feePercent: String(Number(dataSheet.fee_agreement_percent)),
      feeGuaranteeDays: String(Number(dataSheet.warranty_time_in_days))
    },
    section2: {
      ...finalFormData.section2,
      howLongPositionOpen: String(dataSheet.time_position_open).slice(0, 10),
      personInPlace: String(dataSheet.position_filled).slice(0, 10)
    },
    section3: {
      ...finalFormData.section3,
      idealCandidate: String(dataSheet.background_requirements)
    },
    section4: {
      ...finalFormData.section4,
      salaryRange: {
        lowSalary: String(dataSheet.minimum_compensation),
        idealSalary: String(dataSheet.good_compensation),
        highSalary: String(dataSheet.maximum_compensation)
      },
      otherBenefits: String(dataSheet.benefits)
    },
    section5: {
      ...finalFormData.section5,
      workLocation: dataSheet.work_type_option_id
    },
    section6: {
      ...finalFormData.section6,
      daysForInterviews: {
        dateTime1: interviewDates[0] ? String(interviewDates[0]) : undefined,
        dateTime2: interviewDates[1] ? String(interviewDates[1]) : undefined,
        dateTime3: interviewDates[2] ? String(interviewDates[2]) : undefined
      }
    },
    section9: {
      ...finalFormData.section9,
      jobOrderType: dataSheet.job_order_type_id,
      jobOrderStatus: dataSheet.status.id,
      notes: dataSheet.notes ? String(dataSheet.notes) : undefined
    }
  };
};

export const formatDataSheetSchema = (schema, catalogs, whiteSheet) => {
  if (!catalogs || !schema || !whiteSheet) return undefined;

  const feeAgreementOptions = getFeeAgreementOptions(catalogs, whiteSheet);
  const schemaWithFeePercentAdjusted =
    whiteSheet.companyFeeAgreement?.fee_agreement_payment_scheme_id === PaymentMode.Flat
      ? removeFeePercentFromSchema(schema)
      : schema;

  const { feeAgreement, ...remainingSection1Properties } =
    schemaWithFeePercentAdjusted.properties.section1.properties;

  const section1FinalProperties =
    feeAgreementOptions.length > 0
      ? {
          ...remainingSection1Properties,
          feeAgreement: {
            ...feeAgreement,
            oneOf: feeAgreementOptions
          }
        }
      : remainingSection1Properties;

  const section1FinalRequiredFields =
    feeAgreementOptions.length > 0
      ? schemaWithFeePercentAdjusted.properties.section1.required
      : schemaWithFeePercentAdjusted.properties.section1.required.filter(
          property => property !== 'feeAgreement'
        );

  return {
    ...schema,
    properties: {
      ...schema.properties,
      section1: {
        ...schema.properties.section1,
        properties: {
          ...section1FinalProperties
        },
        required: section1FinalRequiredFields
      },
      section5: {
        ...schema.properties.section5,
        properties: {
          ...schema.properties.section5.properties,
          workLocation: {
            ...schema.properties.section5.properties.workLocation,
            oneOf: catalogs.workTypes.data.map(workType => ({
              const: workType.id,
              title: workType.title
            }))
          }
        }
      },
      section9: {
        ...schema.properties.section9,
        properties: {
          ...schema.properties.section9.properties,
          jobOrderType: {
            ...schema.properties.section9.properties.jobOrderType,
            oneOf: catalogs.jobOrderTypes.data.map(jobOrderType => ({
              const: jobOrderType.id,
              title: jobOrderType.title
            }))
          },
          jobOrderStatus: {
            ...schema.properties.section9.properties.jobOrderStatus,
            oneOf: catalogs.jobOrderStatuses.data.map(jobOrderStatus => ({
              const: jobOrderStatus.id,
              title: jobOrderStatus.title
            }))
          }
        }
      }
    }
  };
};

export const addFeeAgreementValuesToFormData = (formData, catalogs, whiteSheet) => {
  if (!catalogs || !formData) return formData;

  const feeAgreementOptions = [
    ...catalogs.feeAgreements.data,
    ...(whiteSheet?.companyFeeAgreement ? [whiteSheet.companyFeeAgreement] : [])
  ];

  const feeAgreementSelected = feeAgreementOptions.find(
    feeAgreement => feeAgreement.id === formData.section1.feeAgreement
  );

  if (!feeAgreementSelected) return formData;

  return {
    ...formData,
    section1: {
      ...formData.section1,
      feePercent: feeAgreementSelected.fee_percentage
        ? String(Number(feeAgreementSelected.fee_percentage))
        : null,
      feeGuaranteeDays: String(Number(feeAgreementSelected.guarantee_days))
    }
  };
};

export const getDataSheetCount = (formData, questionsPaths, needsFeeAgreement) => {
  if (!formData) {
    return {
      total: 0,
      answered: 0
    };
  }

  let finalQuestionsPaths = { ...questionsPaths };

  if (formData.section2.coverPosition === 'No') {
    const {
      'section2.coverPositionDetails': _coverDetails,
      'section2.resultsYouHad': _resultsYouHad,
      ...rest
    } = finalQuestionsPaths;
    finalQuestionsPaths = { ...rest };
  }

  if (!formData.section1.feePercent) {
    const { 'section1.feePercent': _feePercent, ...rest } = finalQuestionsPaths;
    finalQuestionsPaths = { ...rest };
  }

  if (!needsFeeAgreement) {
    const { 'section1.feeAgreement': _feeAgreement, ...rest } = finalQuestionsPaths;
    finalQuestionsPaths = { ...rest };
  }

  return getQuestionsCounts(formData, finalQuestionsPaths);
};

export const createDataToSend = ({
  formData,
  jobOrder,
  user,
  schemaData,
  count,
  whiteSheetId,
  dataSheetId: id
}) => {
  return {
    ...(id && { id }),
    jobOrder: {
      id: jobOrder.id,
      title: jobOrder.title,
      whiteSheetId
    },
    schema: {
      key: FormSchema.JobOrderDataSheet,
      version: schemaData.version
    },
    progress: {
      current: count.answered,
      total: count.total
    },
    data: formData,
    trace: {
      user: {
        id: user.id,
        fullName: user.personalInformation.full_name,
        email: user.email,
        initials: getInitials(user.personalInformation.full_name)
      }
    }
  };
};

export const canChangeFeeAgreementField = (canUserEdit, whiteSheet, fieldPermission) => {
  const isUserCoach = userHasRole(Roles.Coach);
  const isExistingWhiteSheet = !!whiteSheet?.id;
  const hasRightPrivileges = isUserCoach || userHasPermission(fieldPermission);

  return hasRightPrivileges && (!isExistingWhiteSheet || (isExistingWhiteSheet && canUserEdit));
};

export const formatDataSheetUiSchema = (
  uiSchema,
  { canModifyFeeGuarantee, canModifyFeePercentage }
) => {
  return {
    ...uiSchema,
    section1: {
      ...uiSchema.section1,
      signedFeeAgreement: {
        ...uiSchema.section1.signedFeeAgreement,
        'ui:disabled': false
      },
      feeGuaranteeDays: {
        ...uiSchema.section1.feeGuaranteeDays,
        'ui:disabled': !canModifyFeeGuarantee
      },
      feePercent: {
        ...uiSchema.section1.feePercent,
        'ui:disabled': !canModifyFeePercentage
      }
    }
  };
};

export const removeFeePercentFromSchema = schema => {
  if (!schema) return schema;

  const { feePercent: _feePercent, ...propertiesSkippingFeePercent } =
    schema.properties.section1.properties;
  return {
    ...schema,
    properties: {
      ...schema.properties,
      section1: {
        ...schema.properties.section1,
        properties: propertiesSkippingFeePercent,
        required: schema.properties.section1.required.filter(property => property !== 'feePercent')
      }
    }
  };
};

export const addFeePercent = schema => {
  return {
    ...schema,
    properties: {
      ...schema.properties,
      section1: {
        ...schema.properties.section1,
        properties: {
          ...schema.properties.section1.properties,
          feePercent: {
            title: 'Fee Percent',
            type: 'string',
            pattern: '^\\d+(\\.\\d+)?$'
          }
        },
        required: [
          ...schema.properties.section1.required.filter(property => property !== 'feePercent'),
          'feePercent'
        ]
      }
    }
  };
};

export const formatDataSheet = dataSheet => {
  const { section3 } = dataSheet;

  if (!section3?.whatSkills || section3?.skills?.length > 0) return dataSheet;
  const section3WithoutWhatSkills = omit(section3, 'whatSkills');

  return {
    ...dataSheet,
    section3: {
      ...section3WithoutWhatSkills,
      skills: extractSkillsFromExperienceField(section3?.whatSkills)
    }
  };
};
