import { EntityType } from 'UI/constants/entityTypes';

import { columns, outcomeColumns } from './activityColumns';

export const formatResponseActivityTable = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    activityOutcomeComments: item?.outcomes[0]?.comments || null,
    activityOutcomeTitle: item?.outcomes[0]?.activityOutComeType?.title || null
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};

export const formatResponseJobOrderActivityTable = response => response;

export const getFinalActivityColumns = entity => {
  return entity === EntityType.Joborder ? columns : [...columns, ...outcomeColumns];
};
