import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { NOTIFICATIONS_SERVICE_TYPES } from 'UI/utils';

const getLastNotification = state => {
  const notifications = state.notification.domain.notifications.filter(
    notification => notification.type === NOTIFICATIONS_SERVICE_TYPES.MESSAGE_CENTER
  );

  const lastNotification =
    Array.isArray(notifications) && notifications.length > 0 ? notifications[0] : null;

  return lastNotification;
};

export const useNotificationHandler = ({ onNewNotification, isEnabled }) => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const canUseNotifications =
    isEnabled && checkIfFeatureFlagEnabled(FeatureFlags.MessageCenterNotifications);
  const lastNotificationRef = useRef(null);
  const lastNotification = useSelector(getLastNotification);

  useEffect(() => {
    if (
      !canUseNotifications ||
      !lastNotification ||
      lastNotificationRef.current === lastNotification
    )
      return;

    lastNotificationRef.current = lastNotification;
    const incomingMessage = JSON.parse(lastNotification.rawMessage);
    onNewNotification(incomingMessage);
  }, [canUseNotifications, lastNotification, onNewNotification]);
};
