import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { colors } from 'UI/res';

const BORDER_STYLE = `1px solid ${THEME.palette.customColors.borderColor}`;

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: colors.sideBar,
    borderRight: BORDER_STYLE,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  listContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    paddingBottom: theme.spacing(3)
  },
  conversationContainer: {
    borderTop: BORDER_STYLE,
    backgroundColor: 'transparent'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& > p': {
      display: 'flex'
    }
  },
  contactName: {
    fontWeight: 700,
    marginRight: theme.spacing(1),
    maxWidth: '20ch',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(0.5)
  },
  messageText: props => ({
    whiteSpace: 'pre-wrap',
    '& em': {
      fontStyle: 'normal',
      color: theme.palette.grey[600],
      fontWeight: 700
    },
    ...(props.shouldTruncate && {
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical'
    })
  }),
  unreadBadge: {
    marginRight: theme.spacing(1)
  }
}));
