import { COMMAND_CENTER_BASE_URL, TABLES_KEYS } from 'features/command-center/constants';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { FilterType } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

export const PAGE_KEY = TABLES_KEYS.overallPerformance;

const COPIES = strings.commandCenter.overallPerformance;

export const DRAWER_CONTENT_TYPES = {
  NotesForm: 'notesForm',
  FeedbackLog: 'feedbackLog'
};

export const FiltersGroups = [
  {
    id: 'recruiters',
    name: 'Recruiters',
    filters: ['employeeNumbers', 'teams', 'healthStatusesById', 'tenures', 'hireDate']
  },
  {
    id: 'productivity',
    name: 'Productivity',
    filters: ['rangeBySendouts', 'rangeBySendovers', 'rangeByPlacements', 'rangeBySignedAgreements']
  },
  {
    id: 'financial',
    name: 'Financial',
    filters: [
      'rangeByEstimates',
      'rangeByBillings',
      'rangeByProduction',
      'rangeByCashin',
      'rangeByYearlyProduction',
      'rangeByYearlyCashin',
      'rangeByOutstandingBillings',
      'rangeByDrawBalance'
    ],
    showWhen: () => hasFeatureFlag(FeatureFlags.OverallPerformanceFinancialFilters)
  },
  {
    id: 'metrics',
    name: 'Metrics',
    filters: [
      'rangeByInMails',
      'rangeByBulks',
      'rangeByPeopleReached',
      'rangeByPosts',
      'rangeByPhoneTime',
      'rangeByTotalCalls'
    ],
    showWhen: () => hasFeatureFlag(FeatureFlags.OverallPerformanceFinancialFilters)
  }
];

export const COLUMNS = [
  {
    name: 'idFortpac',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'empNo',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'recruiterName',
    label: 'Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CommandCenterOverviewRecruiter,
          idKey: 'empNo',
          textKey: 'name',
          enableInTabRedirect: true
        }
      },
      setCellHeaderProps: () => ({ className: 'dataTableStickyLeftColumnHeader' }),
      setCellProps: () => ({
        className: 'dataTableStickyLeftColumn'
      })
    }
  },
  {
    name: 'team',
    label: 'Team',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CommandCenterOverviewTeam,
          idKey: 'managerEmpNo',
          textKey: 'team',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'healthStatus',
    label: 'Current Health Status',
    options: {
      sort: true,
      display: true,
      renderer: 'healthStatusChip'
    }
  },
  {
    name: 'hireDate',
    label: 'Start Date',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'tenure',
    label: 'Tenure',
    options: {
      sort: true,
      display: true,
      renderer: 'tenure'
    }
  },
  {
    name: 'daysInStatus',
    label: 'Days in Status *',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'inMails',
    label: 'InMails',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'inMails',
          goalKey: 'inMailsGoals'
        }
      }
    }
  },
  {
    name: 'avgInMails',
    label: 'Avg Daily InMails',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'avgInMails',
          goalKey: 'avgInMailsGoals'
        }
      }
    }
  },
  {
    name: 'bulks',
    label: 'Bulks',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'bulks',
          goalKey: 'bulksGoal'
        }
      }
    }
  },
  {
    name: 'peopleReached',
    label: 'People Reached',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'peopleReached',
          goalKey: 'peopleReachedGoal'
        }
      }
    }
  },
  {
    name: 'posts',
    label: 'Avg Daily Active Posts',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'todayLivePosts',
          goalKey: 'livePostingsDailyGoal'
        }
      }
    }
  },
  {
    name: 'calls',
    label: 'Calls',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'calls',
          goalKey: 'callsGoal'
        }
      }
    }
  },
  {
    name: 'callsTime',
    label: 'Phone Time',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'callsTime',
          goalKey: 'callsTimeGoal',
          prefix: 'min'
        }
      }
    }
  },
  {
    name: 'sendouts',
    label: 'Sendouts',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'goalsCount',
        config: {
          key: 'sendouts',
          goalKey: 'sendoutsGoal'
        }
      }
    }
  },
  {
    name: 'sendovers',
    label: 'Sendovers',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'placements',
    label: 'Placements',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'feeAgreementsSent',
    label: 'Fee Agreements Sent',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'feeAgreementsSigned',
    label: 'Signed Agreements',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'estimates',
    label: 'Estimates',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'billings',
    label: 'Billings',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'production',
    label: 'Production',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'cashIn',
    label: 'Cash in',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'outStandingBillings',
    label: 'Current O. Billings',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'drawBalance',
    label: 'Current Draw Balance',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'productionYTD',
    label: 'Production YTD',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'cashInYTD',
    label: 'Cash in YTD',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'recruiterName',
  direction: 'asc'
};

export const GOAL_METRICS =
  'inMailsGoals,callsGoal,bulksGoal,peopleReachedGoal,livePostingsDailyGoal,callsTimeGoal,sendoutsGoal,avgInMailsGoals';

export const METRICS =
  'recruiterName,team,healthStatus,daysInStatus,inMails,bulks,peopleReached,posts,calls,callsTime,placements,feeAgreementsSent,feeAgreementsSigned,hireDate,tenure,cashIn,cashInYTD,production,productionYTD,sendouts,sendovers,billings,outStandingBillings,estimates,avgInMails,drawBalance';

export const METRICS_DESCRIPTION = [
  COPIES.footer.metrics.calls,
  COPIES.footer.metrics.phoneTime,
  COPIES.footer.metrics.bulks,
  COPIES.footer.metrics.peopleReached,
  COPIES.footer.metrics.inMails,
  COPIES.footer.metrics.posts
];

export const extraFilters = [
  {
    name: 'tenures',
    paramName: 'tenures',
    title: 'Tenure',
    type: FilterType.Autocomplete,
    displayKey: 'title',
    api: 'secondary',
    url: `${COMMAND_CENTER_BASE_URL}/catalog/tenures`,
    disableListWrap: true,
    multiple: true,
    idKey: 'id'
  },
  {
    name: 'hireDate',
    type: 'date',
    paramName: 'hireDate',
    title: 'Start Date',
    disableTimeRange: true
  },
  {
    name: 'healthStatusesById',
    paramName: 'statuses',
    title: 'Health Status',
    type: FilterType.Autocomplete,
    displayKey: 'title',
    api: 'secondary',
    url: `${COMMAND_CENTER_BASE_URL}/catalog/health-statuses`,
    disableListWrap: true,
    multiple: true,
    idKey: 'id'
  },
  {
    name: 'rangeBySendouts',
    paramName: 'sendouts',
    title: COPIES.filters.productivity.sendouts,
    type: FilterType.Range,
    displayLabel: COPIES.filters.productivity.sendouts
  },
  {
    name: 'rangeBySendovers',
    paramName: 'sendovers',
    title: COPIES.filters.productivity.sendovers,
    type: FilterType.Range,
    displayLabel: COPIES.filters.productivity.sendovers
  },
  {
    name: 'rangeByPlacements',
    paramName: 'placements',
    title: COPIES.filters.productivity.placements,
    type: FilterType.Range,
    displayLabel: COPIES.filters.productivity.placements
  },
  {
    name: 'rangeBySignedAgreements',
    paramName: 'feeAgreementsSigned',
    title: COPIES.filters.productivity.signedAgreements,
    type: FilterType.Range,
    displayLabel: COPIES.filters.productivity.signedAgreements
  },
  {
    name: 'rangeByEstimates',
    paramName: 'estimates',
    title: COPIES.filters.financial.estimates,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.estimates,
    inputType: 'currency'
  },
  {
    name: 'rangeByBillings',
    paramName: 'billings',
    title: COPIES.filters.financial.billings,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.billings,
    inputType: 'currency'
  },
  {
    name: 'rangeByProduction',
    paramName: 'production',
    title: COPIES.filters.financial.production,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.production,
    inputType: 'currency'
  },
  {
    name: 'rangeByCashin',
    paramName: 'cashin',
    title: COPIES.filters.financial.cashIn,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.cashIn,
    inputType: 'currency'
  },
  {
    name: 'rangeByYearlyProduction',
    paramName: 'productionYTD',
    title: COPIES.filters.financial.yearlyProduction,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.yearlyProduction,
    inputType: 'currency'
  },
  {
    name: 'rangeByYearlyCashin',
    paramName: 'cashinYTD',
    title: COPIES.filters.financial.yearlyCashIn,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.yearlyCashIn,
    inputType: 'currency'
  },
  {
    name: 'rangeByOutstandingBillings',
    paramName: 'currentOutstandingBillings',
    title: COPIES.filters.financial.outstandingBillings,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.outstandingBillings,
    inputType: 'currency'
  },
  {
    name: 'rangeByDrawBalance',
    paramName: 'currentDrawBalance',
    title: COPIES.filters.financial.drawBalance,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.drawBalance,
    inputType: 'currency'
  },
  {
    name: 'rangeByInMails',
    paramName: 'inMails',
    title: COPIES.filters.metrics.inMails,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.inMails
  },
  {
    name: 'rangeByBulks',
    paramName: 'bulks',
    title: COPIES.filters.metrics.bulks,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.bulks
  },
  {
    name: 'rangeByPeopleReached',
    paramName: 'peopleReached',
    title: COPIES.filters.metrics.peopleReached,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.peopleReached
  },
  {
    name: 'rangeByPosts',
    paramName: 'posts',
    title: COPIES.filters.metrics.posts,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.posts
  },
  {
    name: 'rangeByPhoneTime',
    paramName: 'phoneTime',
    title: COPIES.filters.metrics.phoneTime,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.phoneTime
  },
  {
    name: 'rangeByTotalCalls',
    paramName: 'totalCalls',
    title: COPIES.filters.metrics.totalCalls,
    type: FilterType.Range,
    displayLabel: COPIES.filters.metrics.totalCalls
  }
];
