import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import isEqual from 'lodash/isEqual';
import { EntityRoutes } from 'routes/constants';
import { Permissions } from 'services/Authorization';
import strings from 'strings';
import { PaymentMode } from 'UI/constants/entityTypes';
import { UIStatus } from 'UI/constants/status';

import { DATA_SHEET_QUESTIONS_PATHS } from '../EditDataSheet.constants';
import { saveDataSheet } from '../EditDataSheet.services';
import {
  addFeeAgreementValuesToFormData,
  addFeePercent,
  canChangeFeeAgreementField,
  createDataSheetFormData,
  createDataToSend,
  formatDataSheet,
  formatDataSheetSchema,
  formatDataSheetUiSchema,
  getDataSheetCount,
  removeFeePercentFromSchema
} from '../EditDataSheet.utils';

const {
  complete: { success: completeSuccess, error: completeError },
  error: fieldsError
} = strings.inventory.jobOrders.editDataSheet.uiMessages;

const useDataSheetSchema = ({
  whiteSheet,
  whiteSheetStatus,
  dataSheet,
  catalogs,
  schemaData,
  user,
  jobOrder,
  companyName,
  canUserEdit,
  history,
  isDataSheetReadyToBeEvaluated
}) => {
  const dispatch = useDispatch();
  const [isDraftDialogOpen, setIsDraftDialogOpen] = useState(false);
  const [status, setStatus] = useState(UIStatus.IDLE);
  const saveButtonRef = useRef(null);
  const isFormDataFormatted = useRef(false);

  const [formData, setFormData] = useState(undefined);
  const commonStyledFormProps = {
    showErrorList: false,
    noHtml5Validate: true,
    submitRef: saveButtonRef,
    focusOnFirstError: true
  };

  useEffect(() => {
    const notValidScenarios = [
      !catalogs,
      !whiteSheet,
      !isDataSheetReadyToBeEvaluated,
      isFormDataFormatted.current
    ];

    if (notValidScenarios.some(scenario => scenario)) return;
    setFormData(prevFormData => {
      if (dataSheet) {
        const newFormData = formatDataSheet(dataSheet.data);
        isFormDataFormatted.current = true;
        return newFormData;
      }

      const newFormData = createDataSheetFormData(
        prevFormData,
        { ...whiteSheet, status: whiteSheetStatus },
        catalogs?.feeAgreements.data.length > 0
      );

      const dataWithFeeAgreement = addFeeAgreementValuesToFormData(
        newFormData,
        catalogs,
        whiteSheet
      );

      isFormDataFormatted.current = true;
      return dataWithFeeAgreement;
    });
  }, [catalogs, whiteSheet, dataSheet, whiteSheetStatus, isDataSheetReadyToBeEvaluated]);

  const toastBody = `${jobOrder.title} - ${companyName}`;

  const finalSchemas = useMemo(() => {
    const canModifyFeeGuarantee = canChangeFeeAgreementField(
      canUserEdit,
      whiteSheet,
      Permissions.FeeAgreements.ModifyGuarantee
    );

    const canModifyFeePercentage = canChangeFeeAgreementField(
      canUserEdit,
      whiteSheet,
      Permissions.FeeAgreements.ModifyPercentage
    );

    if (!schemaData) {
      return {
        schema: undefined,
        uiSchema: undefined,
        version: undefined
      };
    }

    const formattedSchemas = {
      schema: formatDataSheetSchema(schemaData.structure, catalogs, whiteSheet),
      uiSchema: formatDataSheetUiSchema(schemaData.ui, {
        canModifyFeeGuarantee,
        canModifyFeePercentage
      }),
      version: schemaData.version
    };

    if (!formData) return formattedSchemas;
    const selectedFeeAgreement = catalogs.feeAgreements.data.find(
      feeAgreement => feeAgreement.id === formData.section1.feeAgreement
    );

    return {
      ...formattedSchemas,
      schema:
        selectedFeeAgreement?.fee_agreement_payment_scheme_id === PaymentMode.Flat
          ? removeFeePercentFromSchema(formattedSchemas.schema)
          : addFeePercent(formattedSchemas.schema)
    };
  }, [catalogs, whiteSheet, canUserEdit, schemaData, formData]);

  const toggleDraftDialog = isOpen => () => {
    isOpen !== isDraftDialogOpen && setIsDraftDialogOpen(isOpen);
  };

  const navigateToProfile = () =>
    history.push(EntityRoutes.JobOrderProfile.replace(':id', jobOrder.id));

  const handleOnSave = () => {
    saveButtonRef.current.click();
    toggleDraftDialog(false)();
  };

  const handleCancel = isEqual(dataSheet?.data, formData)
    ? navigateToProfile
    : toggleDraftDialog(true);

  const handleOnConfirm = async ok => {
    ok && (await handleOnSave({ formData }));
  };

  const handleOnChange = ({ formData: newFormData }) => {
    if (newFormData?.section1?.feeAgreement !== formData?.section1?.feeAgreement) {
      const newFormDataWithFeeAgreement = addFeeAgreementValuesToFormData(
        newFormData,
        catalogs,
        whiteSheet
      );
      setFormData(newFormDataWithFeeAgreement);
      return;
    }
    setFormData(newFormData);
  };

  const handleOnSubmit = ({ formData: newFormData }) => {
    setStatus(UIStatus.Saving);
    setFormData(newFormData);

    const count = getDataSheetCount(
      formData,
      DATA_SHEET_QUESTIONS_PATHS,
      catalogs?.feeAgreements.data.length > 0
    );

    const dataToSend = createDataToSend({
      formData: newFormData,
      jobOrder,
      schemaData,
      user,
      whiteSheetId: whiteSheet.id,
      count,
      ...(dataSheet && { dataSheetId: dataSheet.id })
    });

    saveDataSheet(dataToSend)
      .then(response => {
        setStatus(UIStatus.IDLE);

        if ([HTTPStatusCodes.BadRequest, HTTPStatusCodes.Unauthorized].includes(response.status)) {
          dispatch(
            showAlert({
              severity: UIStatus.Error,
              title: completeError,
              body: toastBody
            })
          );
          return;
        }

        dispatch(
          showAlert({
            severity: UIStatus.Success,
            title: completeSuccess,
            body: toastBody
          })
        );
        navigateToProfile();
      })
      .catch(() => {
        setStatus(UIStatus.Error);
        dispatch(
          showAlert({
            severity: UIStatus.Error,
            title: completeError,
            body: toastBody
          })
        );
      });
  };

  const handleOnError = errors => {
    if (errors && errors.length === 0) return;

    dispatch(
      showAlert({
        severity: UIStatus.Error,
        title: fieldsError,
        body: toastBody
      })
    );
  };

  return [
    {
      commonStyledFormProps,
      formData,
      schemas: finalSchemas,
      status,
      isDraftDialogOpen,
      dataSheetProgress: dataSheet?.progress
    },
    {
      toggleDraftDialog,
      handleOnSubmit,
      handleOnSave,
      handleCancel,
      handleClose: navigateToProfile,
      handleOnChange,
      handleOnConfirm,
      handleOnError
    }
  ];
};

export default useDataSheetSchema;
