import { THEME } from 'GlobalStyles';

const { grey } = THEME.palette;

export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    reachOutReasonTitle: item?.reachOutReason?.title ?? '',
    formattedAddressableIssues: !item?.addressableIssues
      ? []
      : item?.addressableIssues.map(addressableIssue => ({
          ...addressableIssue,
          backgroundColor: grey[400],
          textColor: 'black'
        }))
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
