export const HTTPStatusCodes = Object.freeze({
  Ok: 200,
  Created: 201,
  NoContent: 204,
  MiscellaneousPersistentWarning: 299,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  Conflict: 409,
  UnprocessableContent: 422,
  InternalServerError: 500
});
