import * as React from 'react';

const SvgPhoneAction = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M18.889 14h-3.167a2.111 2.111 0 00-2.111 2.111v1.667a7.384 7.384 0 01-7.389-7.389h1.667A2.111 2.111 0 0010 8.278V5.111A2.111 2.111 0 007.889 3H5.167A3.167 3.167 0 002 6.167v4.222A11.6 11.6 0 0013.611 22h4.222A3.167 3.167 0 0021 18.833v-2.722A2.111 2.111 0 0018.889 14z" />
    </svg>
  );
};

export default SvgPhoneAction;
