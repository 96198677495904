import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { getConversations, searchConversations } from 'features/message-center';
import { useUserContext } from 'providers/UserProvider';
import strings from 'strings';
import { UIStatus } from 'UI/constants/status';
import { getErrorMessage } from 'UI/utils';

import { getInternalNumber } from '../components/messageChat/chat.utils';

import { useNotificationHandler } from './useNotificationHandler';
import { updateConversationList } from './utils';

const CONVERSATIONS_LIMIT = 20;

export const useTextConversations = ({ isEnabled }) => {
  const dispatch = useDispatch();
  const [user] = useUserContext();
  const lastItem = useRef(null);
  const [conversations, setConversations] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [uiState, setUiState] = useState({
    error: null,
    isSearchContactsDrawerOpen: false,
    isSearching: false,
    status: UIStatus.Default
  });

  const internalNumber = getInternalNumber(user);

  const fetchConversations = useCallback(
    async ({ searchKeyword = '' } = {}) => {
      if (!isEnabled) return;

      try {
        setUiState(prev => ({ ...prev, status: UIStatus.Loading }));

        const fetchFunction = searchKeyword ? searchConversations : getConversations;
        const params = {
          limit: CONVERSATIONS_LIMIT,
          internalNumber,
          ...(searchKeyword && { search: searchKeyword })
        };

        const response = await fetchFunction(params);
        lastItem.current = response?.lastItem ?? null;

        if (searchKeyword) {
          setSearchResults(response?.chats ?? []);
        } else {
          setConversations(response?.chats ?? []);
        }
        setUiState(prev => ({ ...prev, status: UIStatus.Success, error: null }));
      } catch (err) {
        const error = getErrorMessage(err);
        setUiState(prev => ({ ...prev, status: UIStatus.Error, error }));
        dispatch(
          showAlert({
            severity: 'error',
            title: err?.response?.data?.error ?? strings.shared.errors.serverError.title,
            body: error
          })
        );
      }
    },
    [dispatch, internalNumber, isEnabled]
  );

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  useEffect(() => {
    if (!window.location.search || !isEnabled) return;

    const searchParams = new URLSearchParams(window.location.search);
    const encodedConversation = searchParams.get('conversation');
    if (!encodedConversation) return;

    const decodedConversation = Buffer.from(encodedConversation, 'base64').toString();

    try {
      const conversationEntity = JSON.parse(decodedConversation);
      setSelectedConversation(conversationEntity);

      const finalSearchParams = new URLSearchParams(window.location.search);
      finalSearchParams.delete('conversation');
      window.history.replaceState(
        {
          search: finalSearchParams.toString()
        },
        ''
      );
    } catch (error) {
      console.error('Error decoding conversationId:', error);
    }
  }, [isEnabled]);

  const handleUpdateConversation = useCallback(({ id, updates }) => {
    if (!id) return;

    setConversations(prev =>
      updateConversationList({
        conversations: prev,
        id,
        updates
      })
    );
  }, []);

  const handleNewNotification = useCallback(
    incomingMessage => {
      const id = incomingMessage?.conversationId;

      if (!id) return;

      const unreadMessages =
        selectedConversation?.id === incomingMessage.conversationId
          ? 0
          : incomingMessage.unreadMessages;

      const isNewConversation = !conversations.some(conversation => conversation.id === id);
      const { conversationId: _conversationId, ...incomingMessageWithoutConversationId } =
        incomingMessage;
      if (isNewConversation) {
        setConversations([
          ...conversations,
          {
            ...incomingMessageWithoutConversationId,
            id,
            unreadMessages
          }
        ]);
        return;
      }

      handleUpdateConversation({
        id,
        updates: {
          ...incomingMessageWithoutConversationId,
          id,
          unreadMessages
        }
      });
    },
    [selectedConversation, conversations, handleUpdateConversation]
  );

  useNotificationHandler({
    onNewNotification: handleNewNotification,
    isEnabled
  });

  const handleClickConversation = conversation => {
    setSelectedConversation(conversation);
    setUiState(prev => ({ ...prev, error: null }));
  };

  const handleSearch = async (searchKeyword = '') => {
    setSearchResults([]);
    if (!searchKeyword) {
      setUiState(prev => ({ ...prev, isSearching: false }));
      return;
    }

    setUiState(prev => ({ ...prev, isSearching: true }));
    fetchConversations({ searchKeyword });
  };

  const handleRefresh = async externalNumber => {
    if (uiState.status === UIStatus.Loading) return;

    lastItem.current = null;
    setConversations([]);
    setSearchResults([]);
    setUiState(prev => ({ ...prev, isSearching: false }));
    await fetchConversations();

    const updatedSelectedConversation = conversations?.find(
      conversation => conversation.externalNumber === externalNumber
    );
    setSelectedConversation(updatedSelectedConversation || null);
  };

  const handleFetchMoreConversations = async () => {
    if (!isEnabled || !lastItem.current || uiState.status === UIStatus.Loading) return;

    try {
      setUiState(prev => ({ ...prev, status: UIStatus.Loading }));

      const newParams = {
        internalNumber,
        lastItemDate: lastItem.current.date,
        lastItemInternalNumber: lastItem.current.internalNumber,
        lastItemExternalNumber: lastItem.current.externalNumber
      };

      const response = await getConversations(newParams);
      lastItem.current = response?.lastItem ?? null;
      setConversations(prev => [...prev, ...(response?.chats ?? [])]);
      setUiState(prev => ({ ...prev, status: UIStatus.Success }));
    } catch (e) {
      setUiState(prev => ({ ...prev, status: UIStatus.Error }));
    }
  };

  const handleCloseSearchContactsDrawer = () => {
    setUiState(prev => ({ ...prev, isSearchContactsDrawerOpen: false }));
  };

  const handleOpenSearchContactsDrawer = () => {
    setUiState(prev => ({ ...prev, isSearchContactsDrawerOpen: true }));
  };

  const displayedConversations = uiState.isSearching ? searchResults : conversations;

  const sortedConversationsByDate = displayedConversations.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return {
    conversations: sortedConversationsByDate,
    handleClickConversation,
    handleCloseSearchContactsDrawer,
    handleFetchMoreConversations,
    handleOpenSearchContactsDrawer,
    handleRefresh,
    handleSearch,
    handleUpdateConversation,
    selectedConversation,
    uiState
  };
};
