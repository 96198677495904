import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  ConversationContent,
  ConversationList,
  SearchContactsDrawer,
  useChat,
  useChatV2,
  useTextConversations,
  useTextConversationsV2
} from 'features/message-center';
import { formatInternationalPhone } from 'features/message-center/components/messageChat/chat.utils';
import { SearchResults } from 'features/message-center/components/SearchResults';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import Searchbar from 'UI/components/molecules/Searchbar';
import DualPanelLayout from 'UI/components/templates/DualPanelLayout/DualPanelLayout';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { UIStatus } from 'UI/constants/status';
import { getEntityType } from 'UI/utils/inventory';

export const MessageCenter = () => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const areEndpointsV2Enabled = checkIfFeatureFlagEnabled(FeatureFlags.MessageCenterEndpointsV2);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

  const conversationsV1 = useTextConversations({ isEnabled: !areEndpointsV2Enabled });
  const conversationsV2 = useTextConversationsV2({ isEnabled: areEndpointsV2Enabled });
  const {
    conversations,
    handleClickConversation,
    handleCloseSearchContactsDrawer,
    handleFetchMoreConversations,
    handleOpenSearchContactsDrawer,
    handleRefresh,
    handleSearch,
    handleUpdateConversation,
    selectedConversation,
    uiState
  } = areEndpointsV2Enabled ? conversationsV2 : conversationsV1;

  const params = {
    externalNumber: selectedConversation?.externalNumber,
    selectedConversationInList: selectedConversation,
    onUpdateConversation: handleUpdateConversation
  };

  const chatV1 = useChat({ ...params, isEnabled: !areEndpointsV2Enabled });
  const chatV2 = useChatV2({
    ...params,
    isEnabled: areEndpointsV2Enabled
  });
  const chat = areEndpointsV2Enabled ? chatV2 : chatV1;
  const { handleRefreshConversation } = chat;

  const isEntityTypeValid = !!getEntityType(selectedConversation?.entityType);

  const handleAddNewConversation = ({ externalNumber }) => {
    handleRefresh(externalNumber);
  };

  const handleSearchChange = (_name, value) => setSearchKeyword(value);
  const handleSearchAsync = () => handleSearch(searchKeyword.trim());

  const toggleQuickViewOpen = () => setIsQuickViewOpen(prev => !prev);

  const getFinalContent = () => {
    if (uiState.isSearching && areEndpointsV2Enabled) {
      return (
        <SearchResults
          results={conversations}
          isLoading={uiState.status === UIStatus.Loading}
          customMessageKey="messageId"
          selectedConversationId={selectedConversation?.messageId}
          onClickConversation={handleClickConversation}
        />
      );
    }

    return (
      <ConversationList
        conversations={conversations}
        isLoading={uiState.status === UIStatus.Loading}
        onClickConversation={handleClickConversation}
        onFetchMoreConversations={handleFetchMoreConversations}
        searchKeyword={searchKeyword}
        selectedConversationId={selectedConversation?.id}
      />
    );
  };

  return (
    <>
      <DualPanelLayout
        leftPanel={{
          header: {
            title: strings.messageCenter.title,
            Actions: (
              <>
                <FPIconButton
                  onClick={handleRefresh}
                  tooltipProps={{
                    title: strings.messageCenter.chat.refresh
                  }}
                >
                  <RefreshIcon />
                </FPIconButton>
                <FPIconButton
                  size={24}
                  onClick={handleOpenSearchContactsDrawer}
                  tooltipProps={{
                    title: strings.messageCenter.newMessage
                  }}
                >
                  <AddCircleIcon color="primary" />
                </FPIconButton>
              </>
            ),
            Header: (
              <Searchbar
                name="keyword"
                value={searchKeyword}
                placeholder={strings.shared.ui.search}
                width="auto"
                onChange={handleSearchChange}
                onSearchAsync={handleSearchAsync}
                showClearButton
              />
            )
          },
          Content: getFinalContent()
        }}
        rightPanel={{
          header: {
            HeaderTitle: selectedConversation ? (
              <>
                <Typography variant="h6">{selectedConversation?.fullName || ''}</Typography>
                <Typography variant="body2">
                  {formatInternationalPhone(selectedConversation?.externalNumber || '')}
                </Typography>
              </>
            ) : null,
            Actions: selectedConversation ? (
              <>
                <FPIconButton
                  onClick={handleRefreshConversation}
                  tooltipProps={{
                    title: strings.messageCenter.chat.refresh
                  }}
                >
                  <RefreshIcon />
                </FPIconButton>
                <When condition={isEntityTypeValid}>
                  <FPIconButton
                    onClick={toggleQuickViewOpen}
                    tooltipProps={{
                      title: strings.inventoryProfiles.quickView.viewProfile
                    }}
                  >
                    <VisibilityIcon />
                  </FPIconButton>
                </When>
              </>
            ) : null
          },
          Content: (
            <ConversationContent
              isQuickViewOpen={isQuickViewOpen}
              toggleQuickViewOpen={toggleQuickViewOpen}
              selectedConversationInList={selectedConversation}
              {...chat}
            />
          )
        }}
      />

      {uiState.isSearchContactsDrawerOpen && (
        <SearchContactsDrawer
          isDrawerOpen={uiState.isSearchContactsDrawerOpen}
          onClose={handleCloseSearchContactsDrawer}
          onNewConversation={handleAddNewConversation}
        />
      )}
    </>
  );
};
