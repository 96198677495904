import React from 'react';
import Grid from '@material-ui/core/Grid';
import isNil from 'lodash/isNil';
import strings from 'strings';
import FPHtmlViewer from 'UI/components/atoms/FPHtmlViewer';
import InfoLabel from 'UI/components/molecules/InfoLabel';
import { DateFormats } from 'UI/constants/defaults';
import { capitalizeFirstLetter, formatDuration, toLocalTime } from 'UI/utils';

import { ACTIVIY_MODE } from '../SupportActivityList/SupportActivityList.constants';

import { Container, NotesLabel } from './SupportActivityDetails.styled';
import { SupportActivityNotesField } from './SupportActivityNotesField';

const { fields: FIELDS_COPIES } = strings.commandCenter.feedbackNotes.activityDetailsDrawer;

export const SupportActivityDetails = ({ activity, mode }) => {
  return (
    <Container>
      <Grid container spacing={4}>
        <Grid container item>
          <Grid item xs={6}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.createdAt}
              description={
                activity?.createdAt
                  ? toLocalTime(activity?.createdAt).format(DateFormats.SimpleDateTime)
                  : ''
              }
            />
          </Grid>

          <Grid item xs={6}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.contact}
              description={activity?.giverName}
            />
          </Grid>
        </Grid>

        <Grid container item>
          {!isNil(activity?.secDuration) && (
            <Grid item xs={6}>
              <InfoLabel
                cropped={false}
                title={FIELDS_COPIES.duration}
                description={formatDuration(activity?.secDuration)}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.direction}
              description={activity?.direction}
            />
          </Grid>
        </Grid>

        {(!!activity?.body || !!activity.subject) && (
          <Grid item xs={12}>
            <InfoLabel
              cropped={false}
              title={FIELDS_COPIES.content}
              customValueRender={
                <>
                  <p>Subject: {activity?.subject}</p>
                  <p>{activity?.body}</p>
                </>
              }
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {mode === ACTIVIY_MODE.View ? (
            <InfoLabel
              cropped={false}
              title={`${activity?.type} ${FIELDS_COPIES.notes}`}
              customValueRender={<FPHtmlViewer content={activity?.notes} />}
            />
          ) : (
            <>
              <NotesLabel>{capitalizeFirstLetter(activity?.type)} Notes</NotesLabel>
              <SupportActivityNotesField />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
