// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import SaveButton from 'UI/components/atoms/SaveButton';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { UIStatus } from 'UI/constants/status';

import useActivitiesForm from '../hooks/useActivitiesForm';

import { useStyles } from './ActivitiesListing.styles';
import { FormFieldsMap } from './ActivityOutcomeFormFields';

const ActivityOutcomeForm = ({
  entity,
  entityId,
  outcome,
  outcomeComments,
  activityId,
  onCancel,
  onSubmitSucceed,
  rowIndex,
  outcomeOptions
}) => {
  const styles = useStyles();

  const {
    dirty,
    formValues,
    handleCancel,
    handleFieldChange,
    handleSelectChange,
    handleSubmit,
    onSubmit,
    saveState,
    errors
  } = useActivitiesForm({
    activityId,
    entity,
    entityId,
    outcome,
    outcomeComments,
    rowIndex,
    onCancel,
    onSubmitSucceed
  });

  const isSaving = saveState.status === UIStatus.Saving;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={7} sm={2} md={2} lg={2}>
          <AutocompleteSelect
            name={FormFieldsMap.ActivityOutcome.key}
            selectedValue={formValues[FormFieldsMap.ActivityOutcome.key]}
            error={!!errors[FormFieldsMap.ActivityOutcome.key]}
            errorText={errors[FormFieldsMap.ActivityOutcome.key]?.message}
            placeholder="Outcome"
            defaultOptions={outcomeOptions}
            getOptionLabel={option => option.typeTitle}
            groupBy={option => option.categoryTitle}
            getOptionSelected={(option, value) => option.typeId === value?.typeId}
            onSelect={handleSelectChange}
            disableClearable={
              outcome.activityOutcomeId && !formValues[FormFieldsMap.ActivityOutcome.key]?.typeId
            }
          />
        </Grid>
        <Grid item xs={7} sm={7} md={6} lg={6}>
          <TextBox
            label="Comments"
            name={FormFieldsMap.ActivityOutcomeComments.key}
            onChange={handleFieldChange}
            value={formValues[FormFieldsMap.ActivityOutcomeComments.key]}
          />
        </Grid>
      </Grid>
      {saveState.status === UIStatus.Error && (
        <Grid container spacing={1}>
          <Grid item xs={7} sm={7} md={10}>
            <Alert className={styles.alert} severity="error">
              {saveState.error}
            </Alert>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item>
          <SaveButton
            isSaving={isSaving}
            isSuccess={saveState.status === UIStatus.Success}
            disabled={isSaving || !dirty}
          />
        </Grid>
        <Grid item>
          <FPActionButton
            text={strings.shared.ui.cancel}
            variant="outlined"
            onClick={handleCancel}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ActivityOutcomeForm;
