import React, { useContext, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormHelperText } from '@material-ui/core';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { UserProviderContext } from 'providers/UserProvider';
import { userHasRole } from 'services/Authorization';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FPTextEditor from 'UI/components/molecules/FPTextEditor';
import { tinymceConfig } from 'UI/constants/config';
import { ActivityLogTypes, DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { idOptionSelected, REQUIRED_VALIDATION, toLocalTime } from 'UI/utils';

import { FORM_FIELDS_MAP } from './ActivityForm.constants';
import { useStyles } from './ActivityForm.styles';

const { form: STRINGS } = strings.inventoryProfiles.sections.tabs.activityNote;

export const ActivityForm = ({ activityData }) => {
  const classes = useStyles();
  const { errors, register, unregister, watch, setValue, getValues } = useFormContext();
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const [user] = useContext(UserProviderContext);
  const isOperationsUser = userHasRole(Roles.Operations, user);

  const localTime = toLocalTime(activityData.createdAt);
  const formattedDate = localTime && localTime.format(DateFormats.SimpleDateTime);
  const isExistingActivity = !!activityData.createdAt;
  const isOwner = user.id === activityData.userId;
  const canEditActivityFields = isExistingActivity ? isOperationsUser : true;
  const canEditOutcomeFields = isExistingActivity ? isOwner || isOperationsUser : true;

  const areOutcomeFieldsEnabled =
    checkIfFeatureFlagEnabled(FeatureFlags.ActivityOutcomeFieldsInForm) &&
    activityData.entity !== EntityType.Joborder;

  const editorRef = useRef(null);

  const formValues = watch();

  const isEmailOrBulk =
    formValues[FORM_FIELDS_MAP.ActivityType.key]?.id === ActivityLogTypes.Email ||
    formValues[FORM_FIELDS_MAP.ActivityType.key]?.id === ActivityLogTypes.BulkEmail;

  const initEditor = (e, editor) => {
    editorRef.current = editor;
  };

  const handleEditorChange = editorValue =>
    setValue(FORM_FIELDS_MAP.ActivityBody.key, editorValue, true);

  const handleCommentsChange = (fieldName, newValue) => {
    setValue(fieldName, newValue);
  };

  const handleSelectChange = (name?, value) => setValue(name, value, true);

  useEffect(() => {
    register(FORM_FIELDS_MAP.ActivityType.key, REQUIRED_VALIDATION);
    register(FORM_FIELDS_MAP.ActivityBody.key, { required: !activityData.isCreatedBySystem });

    if (areOutcomeFieldsEnabled) {
      register(FORM_FIELDS_MAP.ActivityOutcome.key, {
        validate: value => {
          const comments = getValues(FORM_FIELDS_MAP.ActivityOutcomeComments.key);
          if (comments && (!value || !value.typeId)) {
            return STRINGS.validation.activityOutcome;
          }
          return true;
        }
      });

      register(FORM_FIELDS_MAP.ActivityOutcomeComments.key, {
        validate: value => {
          const outcome = getValues(FORM_FIELDS_MAP.ActivityOutcome.key);
          if (value && (!outcome || !outcome.typeId)) {
            return STRINGS.validation.activityOutcomeComments;
          }
          return true;
        }
      });
    }

    return () => {
      unregister(FORM_FIELDS_MAP.ActivityType.key);
      unregister(FORM_FIELDS_MAP.ActivityBody.key);
      if (areOutcomeFieldsEnabled) {
        unregister(FORM_FIELDS_MAP.ActivityOutcome.key);
        unregister(FORM_FIELDS_MAP.ActivityOutcomeComments.key);
      }
    };
  }, [register, unregister, areOutcomeFieldsEnabled, getValues, activityData.isCreatedBySystem]);

  const previewRenderers = {
    [ActivityLogTypes.BulkEmail]: (
      <div
        className={classes.emailBodyRenderer}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html:
            activityData?.contextualData?.bulkEmailPreview ||
            formValues[FORM_FIELDS_MAP.ActivityBody.key]
        }}
      />
    ),
    [ActivityLogTypes.Email]: (
      <iframe
        data-testid="email-frame"
        title="htmlFrame"
        className={classes.emailBodyRenderer}
        srcDoc={
          activityData?.contextualData?.mailBody || formValues[FORM_FIELDS_MAP.ActivityBody.key]
        }
      />
    )
  };

  return (
    <div className={classes.formContainer}>
      <AutocompleteSelect
        name={FORM_FIELDS_MAP.ActivityType.key}
        selectedValue={formValues[FORM_FIELDS_MAP.ActivityType.key]}
        placeholder="Type *"
        error={!!errors[FORM_FIELDS_MAP.ActivityType.key]}
        errorText={errors[FORM_FIELDS_MAP.ActivityType.key]?.message}
        url={Endpoints.ActivityTypes}
        getOptionLabel={option => option.title}
        getOptionSelected={idOptionSelected}
        onSelect={handleSelectChange}
        disabled={activityData.isCreatedBySystem || !canEditActivityFields}
      />
      <When condition={isExistingActivity && activityData.isCreatedBySystem && isEmailOrBulk}>
        {previewRenderers[formValues[FORM_FIELDS_MAP.ActivityType.key]?.id]}
      </When>
      <When
        condition={
          activityData.isCreatedBySystem ? !isEmailOrBulk : !activityData.isCreatedBySystem
        }
      >
        <FPTextEditor
          onInit={initEditor}
          config={tinymceConfig}
          value={formValues[FORM_FIELDS_MAP.ActivityBody.key]}
          onChange={handleEditorChange}
          disabled={activityData.isCreatedBySystem || !canEditActivityFields}
          error={!!errors[FORM_FIELDS_MAP.ActivityBody.key]}
          errorText={errors[FORM_FIELDS_MAP.ActivityBody.key]?.message}
        />
      </When>
      <When condition={areOutcomeFieldsEnabled}>
        <AutocompleteSelect
          name={FORM_FIELDS_MAP.ActivityOutcome.key}
          selectedValue={formValues[FORM_FIELDS_MAP.ActivityOutcome.key]}
          placeholder="Outcome"
          url={`${Endpoints.ActivityOutcomes}?entityType=${activityData?.entity}`}
          getOptionLabel={option => option.typeTitle}
          groupBy={option => option.categoryTitle}
          getOptionSelected={(option, value) => option.typeId === value?.typeId}
          onSelect={handleSelectChange}
          disabled={!canEditOutcomeFields}
          error={!!errors[FORM_FIELDS_MAP.ActivityOutcome.key]}
          errorText={errors[FORM_FIELDS_MAP.ActivityOutcome.key]?.message}
        />
        <TextBox
          label="Comments"
          name={FORM_FIELDS_MAP.ActivityOutcomeComments.key}
          onChange={handleCommentsChange}
          value={formValues[FORM_FIELDS_MAP.ActivityOutcomeComments.key] || ''}
          disabled={!canEditOutcomeFields}
        />
      </When>
      <When condition={activityData.createdAt}>
        <FormHelperText className={classes.helper}>
          {STRINGS.label.createdBy}{' '}
          <b>
            {activityData.userInitials} - {activityData.userEmail}
          </b>
          {activityData.createdAt && (
            <>
              {' '}
              {STRINGS.label.createdDate}: <b>{formattedDate}</b>
            </>
          )}
        </FormHelperText>
      </When>
    </div>
  );
};
